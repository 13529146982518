import React, { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0, type User } from '@auth0/auth0-react';
import { type RootState } from 'redux/store';
import { PARAM_ACCOUNT, PARAM_PREVIEW_MODE, SFCC_PARAM_PREVIEW_ID } from 'utils/urlParams';
import { useCreateFirebaseTokenMutation, useSignInWithCustomTokenMutation, useSignOutMutation } from 'services/auth';
import { differenceInMilliseconds, fromUnixTime, parseISO } from 'date-fns';
import { clearUser, setUser } from 'redux/authSlice';
import { useLocalStorageState } from 'ahooks';

interface AuthContextParams {
  token?: string | null
  authenticated?: boolean
}

export const AuthContext = createContext<AuthContextParams>({});

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch()

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(window.location.search);
  const previewID = queryParams.get(SFCC_PARAM_PREVIEW_ID);
  const previewMode = queryParams.get(PARAM_PREVIEW_MODE);
  const account = queryParams.get(PARAM_ACCOUNT);

  // Determine if the application is in preview mode
  const isPreviewMode = useMemo(() => {
    return !!previewID || !!previewMode;
  }, [previewID, previewMode]);

  // Check if the account parameter is present
  const hasAccount = !!account;

  // Decide whether to show admin controls
  const shouldShowAdminControls = useMemo(() => {
    return hasAccount && !isPreviewMode;
  }, [hasAccount, isPreviewMode]);

  // Auth0 hooks for authentication
  const {
    isAuthenticated: auth0Authenticated,
    isLoading: auth0Loading,
    user,
    getIdTokenClaims,
    loginWithPopup
  } = useAuth0();

  // RTK Query mutation hook for creating Firebase token
  const [createFirebaseToken] = useCreateFirebaseTokenMutation();
  const [signInWithCustomToken, { data: firebaseCustomTokenAuthData }] = useSignInWithCustomTokenMutation();
  const [signOut] = useSignOutMutation();

  // Select needAuth from the Redux store
  const { authenticated } = useSelector((state: RootState) => state.auth);
  const [, setFirebaseToken] = useLocalStorageState<string | null>('firebaseToken', { defaultValue: null });
  const [auth0TokenExpiration, setAuth0TokenExpiration] = useLocalStorageState<string | null>('auth0TokenExpirationTime', { defaultValue: null });
  const [firebaseTokenExpiration, setFirebaseTokenExpiration] = useLocalStorageState<string | null>('firebaseTokenExpirationTime', { defaultValue: null });

  // Timer ID for managing token refresh
  const auth0TimerId = useRef<NodeJS.Timeout | null>(null);
  const firebaseTimerId = useRef<NodeJS.Timeout | null>(null);

  // Function to handle silent sign-in and create Firebase token
  const handleSilentSignIn = useCallback(async (user: User) => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const auth0Token = idTokenClaims?.__raw;
      const auth0TokenExp = idTokenClaims?.exp;

      if (!auth0Token || !auth0TokenExp) throw new Error('Auth0 token is missing.');

      setAuth0TokenExpiration(fromUnixTime(auth0TokenExp).toISOString());

      // Call the mutation and unwrap the response to get the data
      const result = await createFirebaseToken({ user, token: auth0Token }).unwrap();

      // Assuming the response contains the Firebase token
      const { firebaseToken } = result;

      await signInWithCustomToken({ token: firebaseToken });

      // Update the account param in the URL with the user's email
      if (user.email) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        params.set(PARAM_ACCOUNT, user.email);

        const newUrl = `${url.pathname}?${params.toString()}`;
        window.history.replaceState({}, '', decodeURIComponent(newUrl));
      }
    } catch (error) {
      console.error('Error during silent sign-in:', error);
    }
  }, [createFirebaseToken, getIdTokenClaims, setAuth0TokenExpiration, signInWithCustomToken]);

  const scheduleTokenRefresh = useCallback((expirationTime: Date) => {
    let refreshInMs = differenceInMilliseconds(expirationTime, new Date()) - 60000; // Refresh 1 minute before expiration
    refreshInMs = Math.max(refreshInMs, 0)

    if (firebaseTimerId.current) clearTimeout(firebaseTimerId.current);

    firebaseTimerId.current = setTimeout(() => {
      if (!auth0Authenticated || !user) {
        void signOut({});
        dispatch(clearUser());
      } else {
        void handleSilentSignIn(user);
      }
    }, refreshInMs)
  }, [auth0Authenticated, user, signOut, dispatch, handleSilentSignIn]);

  const scheduleAuth0Login = useCallback((expirationTime: Date) => {
    let refreshInMs = differenceInMilliseconds(expirationTime, new Date());
    refreshInMs = Math.max(refreshInMs, 0)

    if (auth0TimerId.current) clearTimeout(auth0TimerId.current);

    auth0TimerId.current = setTimeout(() => {
      dispatch(clearUser());
      void loginWithPopup({});
    }, refreshInMs)
  }, [dispatch, loginWithPopup])

  useEffect(() => {
    if (authenticated || auth0Authenticated) {
      return
    }
    if (shouldShowAdminControls) {
      void loginWithPopup({})
    }
  }, [shouldShowAdminControls, auth0Authenticated, authenticated, loginWithPopup]);

  // Effect to handle silent sign-in when auth0 authenticated
  useEffect(() => {
    if (authenticated || auth0Loading) {
      return
    }
    if (auth0Authenticated && user) {
      void handleSilentSignIn(user);
    }
  }, [auth0Authenticated, user, auth0Loading, authenticated, handleSilentSignIn]);

  useEffect(() => {
    if (!firebaseCustomTokenAuthData) return
    const { token, expirationTime } = firebaseCustomTokenAuthData
    setFirebaseToken(token)
    setFirebaseTokenExpiration(fromUnixTime(expirationTime).toISOString())
    dispatch(setUser({ token }))
  }, [dispatch, firebaseCustomTokenAuthData, setFirebaseToken, setFirebaseTokenExpiration]);

  // Effect to handle silent sign-out when auth0 not authenticated
  useEffect(() => {
    if (auth0Authenticated || auth0Loading) {
      return
    }
    if (authenticated) {
      void signOut({})
      dispatch(clearUser())
    }
  }, [auth0Authenticated, auth0Loading, authenticated, dispatch, signOut]);

  useEffect(() => {
    if (auth0TokenExpiration) {
      const expirationDate = parseISO(auth0TokenExpiration)

      scheduleAuth0Login(expirationDate);
    }
    return () => {
      if (auth0TimerId.current) clearTimeout(auth0TimerId.current);
    };
  }, [auth0TokenExpiration, scheduleAuth0Login]);

  useEffect(() => {
    if (firebaseTokenExpiration) {
      const firebaseExpirationDate = parseISO(firebaseTokenExpiration);

      scheduleTokenRefresh(firebaseExpirationDate);
    }
    return () => {
      if (firebaseTimerId.current) clearTimeout(firebaseTimerId.current);
    };
  }, [firebaseTokenExpiration, scheduleTokenRefresh]);

  return (
    <AuthContext.Provider value={{ authenticated }}>
      {children}
    </AuthContext.Provider>
  )
}
