import {
  type BaseQueryFn,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'
import { appConfig } from 'config'
import { type BannerType } from '../utils/types';
import { type RootState } from 'redux/store';
import { unescapeVariantHTML } from 'utils/mapPublicBanners';
import { v4 } from 'uuid';

const mapBannersData = (response: { data: BannerType[] }) => ({
  data: response.data.map((banner) => {
    return {
      ...banner,
      variants: banner.variants.map((variant) => ({
        ...variant,
        code: {
          ...variant.code,
          html: unescapeVariantHTML(variant.code?.html)
        },
        id: v4()
      }))
    }
  })
})

const rawBaseQuery = fetchBaseQuery({
  baseUrl: appConfig.baseUrl
})

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  args,
  api,
  extraOptions
) => {
  const { token } = (api.getState() as RootState).auth
  if (typeof args === 'string') {
    return rawBaseQuery(args, api, extraOptions)
  }

  const modifiedArgs = {
    ...args,
    headers: {
      ...args.headers,
      Authorization: `Bearer ${token}` // Attach the token in the Authorization header
    }
  }

  return rawBaseQuery(modifiedArgs, api, extraOptions)
}
export const bannersApi = createApi({
  reducerPath: 'bannersApi',
  baseQuery,
  endpoints: builder => ({
    fetchBanners: builder.query<{ data: BannerType[] }, any>({
      query: params => ({
        url: `api/apps/${appConfig.webAppId}/banners`,
        params
      }),
      transformResponse: mapBannersData
    })
  })
})

export const {
  usePrefetch,
  useFetchBannersQuery
} = bannersApi
