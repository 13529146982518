import styled from 'styled-components'

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

export const TooltipBox = styled.div<{ placement: string }>`
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  ${(props) => {
    switch (props.placement) {
      case 'topLeft':
        return `
          bottom: 100%;
          left: 0;
          transform: translateY(-10px);
        `
      case 'topRight':
        return `
          bottom: 100%;
          right: 0;
          transform: translateY(-10px);
        `
      case 'bottomLeft':
        return `
          top: 100%;
          left: 0;
          transform: translateY(10px);
        `
      case 'bottomRight':
        return `
          top: 100%;
          right: 0;
          transform: translateY(10px);
        `
      default:
        return `
          top: 100%;
          left: 50%;
          transform: translateX(-50%) translateY(10px);
        `
    }
  }}

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`
