import React, { forwardRef, type LegacyRef } from 'react';
import { ButtonContainer, MenuButton, MenuContainer, MenuContainerRight, MenuTitle } from './styles';

export enum CreateMenuType {
  DEFAULT = 'DEFAULT',
  RIGHT = 'RIGHT'
}

interface CreateMenuProps {
  ref?: LegacyRef<HTMLDivElement>
  type?: CreateMenuType
  onMouseEnter: (e: React.MouseEvent) => void
  onMouseLeave: (e: React.MouseEvent) => void
  onCreateNew?: () => void
  onSelectFromBannerList?: () => void
}

const CreateMenu: React.FC<CreateMenuProps> = forwardRef<HTMLDivElement, CreateMenuProps>(function CreateMenu ({
  type = CreateMenuType.DEFAULT,
  onMouseEnter,
  onMouseLeave,
  onCreateNew,
  onSelectFromBannerList
}, ref) {
  const Container = type === CreateMenuType.RIGHT
    ? MenuContainerRight
    : MenuContainer;

  return (
    <Container
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MenuTitle>Insert a New Banner</MenuTitle>
      <ButtonContainer>
        {!!onCreateNew && (
          <MenuButton
            type="primary"
            className="create-button"
            onClick={onCreateNew}
          >
            Create New
          </MenuButton>
        )}
        {!!onSelectFromBannerList && (
          <MenuButton
            type="default"
            className="save-button"
            onClick={onSelectFromBannerList}
          >
            Select from Banner List
          </MenuButton>
        )}
      </ButtonContainer>
    </Container>
  );
})

export default CreateMenu;
