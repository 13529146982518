import React from 'react'
import define from './../remote/define'

// Add to global so that eval
// can find the define fn in
// global context.
// @ts-expect-error temp
global.define = define
global.React = React

const strictEvaluate = (code: string, exportName: string = 'default'): any => {
  try {
    // eslint-disable-next-line no-eval
    const compiled = eval?.(`"use strict"; ${code}`)
    return compiled && exportName?.length > 0 ? compiled?.[exportName] : compiled;
  } catch (e) {
    console.error(e)
    return null
  }
}

export default strictEvaluate
