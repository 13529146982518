import { getSizeFromURL } from './metadata'

export const base64Pattern = /^data:image\/([\d+.A-Za-z-]+);base64,[\d+/=A-Za-z]+$/
const mimePattern = /data:(\w+\/[\w+.-]+);/

export const b64ToBlob = (image: string, bannerName: string) => {
  // Decode base64 string to raw binary data held in a string
  const byteString = atob(image.split(',')[1])

  // Create an array of 8-bit unsigned integers
  const byteArray = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    byteArray[i] = byteString.charCodeAt(i)
  }

  // Define the file type
  const mimeType = image.match(mimePattern)?.[1]

  // Create a Blob from the byte array and specify the file type
  const blob = new Blob([byteArray], { type: mimeType })

  // Convert the Blob to a File object
  return new File([blob], bannerName, { type: mimeType })
}

export const getImageDims = async (image: string, name: string) => {
  const i = new Image()

  const isString = typeof image === 'string' && !base64Pattern.test(image)
  i.src = isString ? image : URL.createObjectURL(b64ToBlob(image, name))
  try {
    if (isString) {
      const dims = await getSizeFromURL(i.src)
      return {
        height: +dims.height,
        width: +dims.width
      }
    }
  } catch (ex) {
    console.error(ex)
  }

  try {
    await i.decode()
    if (!isString) {
      URL.revokeObjectURL(i.src)
    }
    return {
      height: i.naturalHeight,
      width: i.naturalWidth
    }
  } catch (ex) {
    console.error(ex)
    return {
      height: 0,
      width: 0
    }
  }
}
