import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Toolbar from 'components/Toolbar'
import SlotPortal from 'components/SlotPortal'
import { fetchDefaultTemplate, fetchBaseComponent, setPublishedBanners } from 'redux/bannersSlice'
import { addSlot, organizeBannersIntoSlots, slotsListStateSelector } from 'redux/slotsSlice'
import { BILDIT_DATA_ATTR } from 'utils/urlParams'
import { useMemoizedFn } from 'ahooks'
import AdminPublicSlotListingProvider from 'containers/AdminPublicSlotListingProvider'
import { useFetchBannersQuery } from 'services/banners'
import { AuthContext } from 'contexts/auth'
import { bannersForLocation, useBannersStore } from 'services/banners.public'
import useHasAccount from 'hooks/useHasAccount'
import type { GetBannersParams } from 'utils/api'
import { usePreview } from 'contexts/preview'
import { useClientCookies } from 'contexts/cookies'
import { useProxyLocation } from 'contexts/location'
import { useAjaxCategory } from 'hooks/useCategory'

const SlotListing: React.FC = () => {
  const dispatch = useDispatch()
  const { slots, remoteFnStr } = useSelector(slotsListStateSelector)
  const setClientDefaultPublic = useBannersStore((state) => state.setClientDefault)
  const { cookies, loading } = useClientCookies()

  const toolbarRef = useRef<HTMLDivElement | null>(null)

  const { authenticated } = useContext(AuthContext)
  const { isPreviewEnabled, isPublicMode } = usePreview()
  const hasAccount = useHasAccount()
  const { url } = useProxyLocation()
  const { href } = window.location
  const currentCategory = useAjaxCategory(url)
  const shouldShowAdminControls = useMemo(() => hasAccount && authenticated && !isPreviewEnabled, [hasAccount, isPreviewEnabled, authenticated])
  const params: GetBannersParams = {
    archived: false
  }
  const { data: bannersQueryResult, isLoading } = useFetchBannersQuery(params, { skip: !authenticated })
  const fetchDefaultData = useMemoizedFn(() => {
    if (!authenticated) return
    dispatch(fetchDefaultTemplate())
    if (!remoteFnStr) {
      dispatch(fetchBaseComponent())
    }
  })

  useEffect(() => {
    if (isLoading || !bannersQueryResult) return
    const { data } = bannersQueryResult
    dispatch(setPublishedBanners({ banners: data }))
    dispatch(organizeBannersIntoSlots({ banners: bannersForLocation(data, href, currentCategory, cookies) }))
  }, [bannersQueryResult, dispatch, isLoading, cookies, currentCategory, href]);

  useEffect(() => {
    const elements = window.BILDIT?.pageSlots ?? Array.from(document.querySelectorAll(`[${BILDIT_DATA_ATTR}]`))
    elements.forEach((node) => {
      const slotId = node.getAttribute(BILDIT_DATA_ATTR)
      if (slotId) {
        node.innerHTML = ''
        dispatch(addSlot({ id: slotId }))
      }
    })
    fetchDefaultData()
  }, [dispatch, fetchDefaultData, authenticated])

  useEffect(() => {
    if (remoteFnStr) {
      setClientDefaultPublic(remoteFnStr)
    }
  }, [setClientDefaultPublic, remoteFnStr])

  if (loading) return null

  return <div id="script-info" ref={toolbarRef}>
    {shouldShowAdminControls && <Toolbar />}
    {isPublicMode || !hasAccount
      ? <AdminPublicSlotListingProvider />
      : slots.length
        ? slots.map(({ id, banners, loading }) => (<SlotPortal
          key={id}
          isListLoading={loading}
          banners={banners}
          slotId={id}
        />))
        : null}
    </div>
}

export default SlotListing
