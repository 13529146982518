import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import type { ClientCookie } from 'config/types'
import { getCookie } from 'utils/cookies'
import { appConfig } from 'config'

interface CookieContextParams {
  cookies: ParsedCookie[]
  loading: boolean
}

const CookieContext = createContext<CookieContextParams>({ cookies: [], loading: true })

export interface ParsedCookie {
  name: string
  value: string | null
  map: any | null
}

interface CookieProviderProps {
  children: React.ReactNode
  cookies: ClientCookie[]
}

export const CookieProvider: React.FC<CookieProviderProps> = ({ children, cookies }) => {
  const [loading, setLoading] = useState(true)
  const [currentURL, setCurrentURL] = useState(window.location.href)
  const [cookieVals, setCookieVals] = useState<ParsedCookie[]>([])

  const updateCookies = useCallback(() => {
    if (!loading && currentURL === window.location.href) return

    Promise.all(cookies.map(async (cookie) => {
      const { name, encoded = false, json = false } = cookie;
      const cookieVal = await getCookie(name);
      if (cookieVal) {
        const clientMappedValues = appConfig.cookiesValueMaps.get(name) ?? null;
        let value = decodeURIComponent(cookieVal);
        if (encoded) value = atob(value);
        try {
          if (json) value = JSON.parse(value);
        } catch (ex) {
          console.error('Unable to parse JSON inside cookie', name, ex);
        }
        return { name, value, map: clientMappedValues };
      }
      return { name, value: null, map: null };
    })).then((mappedCookies) => {
      setCookieVals(mappedCookies)
    }).catch((ex) => {
      console.error(ex)
    }).finally(() => {
      setLoading(false)
      setCurrentURL(window.location.href)
    })
  }, [cookies, currentURL, loading])

  useEffect(() => {
    updateCookies()
    // refresh cookies on ajax refresh
    if (appConfig.ajaxPageDataReadyEvent) {
      const ajaxDataEvent = appConfig.ajaxPageDataReadyEvent
      document.addEventListener(ajaxDataEvent, updateCookies)

      return () => {
        document.removeEventListener(ajaxDataEvent, updateCookies)
      }
    }
  }, [updateCookies])

  return (
    <CookieContext.Provider value={{ cookies: cookieVals, loading }}>
      {children}
    </CookieContext.Provider>
  )
}

export const useClientCookies = () => {
  const context = useContext(CookieContext)
  if (!context) {
    throw new Error('useClientCookies must be used within a CookieProvider');
  }
  return context
}
