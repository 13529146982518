import styled from 'styled-components'
import { Form, type FormItemProps } from 'antd'
import theme from 'config/theme'

const FormItem = styled(Form.Item)<FormItemProps>`
  flex: 1;
  min-width: 0;
  .ant-checkbox .ant-checkbox-inner,
  .ant-checkbox-checked:after {
    border-radius: 2px;
  }
  .ant-picker:focus-within {
    border-color: black;
  }

  .ant-form-item-label > label {
    font-weight: 500;
    height: 100%;
    line-height: normal;
    max-height: ${theme.token?.controlHeight}px;
    min-height: ${theme.components?.Form?.labelHeight}px;
  }

  .ant-form-item-extra {
    color: #3A94FD;
    font: 400 10px ${theme.token?.fontFamily};
    margin-top: 5px;
    min-height: initial;
  }
`

export default FormItem
