import React from 'react'
// import styled from 'styled-components'
import jsxRuntime from 'react/jsx-runtime'
/* Specific to component. */

export default {
  // node_modules
  react: React,
  // 'styled-components': styled,
  'react/jsx-runtime': jsxRuntime
}
