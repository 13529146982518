import React, { useEffect } from 'react';
import PublicSlotListing from '../components/PublicSlotListing';
import {
  useFetchPublicBannersQuery,
  // useFetchAppetizePublicBannersQuery,
  useGetRemoteCodelibQuery
} from 'redux/bannersPublic';
import { useBannersStore } from 'services/banners.public';
import { usePreview } from 'contexts/preview';
import { getUnixTime } from 'date-fns';

const AdminPublicSlotListingProvider: React.FC = () => {
  const { previewDate } = usePreview();
  let params = {};
  if (previewDate) {
    const date = getUnixTime(previewDate) * 1000;
    params = { date };
  }
  const { isLoading, data } = useFetchPublicBannersQuery(params);
  const { isLoading: isLoadingBaseComponent, data: baseComponent } =
    useGetRemoteCodelibQuery({});

  const setClientDefaultPublic = useBannersStore(
    (state) => state.setClientDefault
  );
  useEffect(() => {
    if (baseComponent && baseComponent?.length > 0) {
      setClientDefaultPublic(baseComponent);
    }
  });

  if (!data) {
    return null;
  }

  return (
    <PublicSlotListing isLoading={isLoading ?? isLoadingBaseComponent} banners={data} />
  )
}

export default AdminPublicSlotListingProvider;
