import type { BannerType, ImageFile, ScheduleType, ScreenType, Variant, VariantMetadata, VariantPreview } from './types'
import { getDefaultSchedules } from './date'

export interface ResponsiveImage {
  height: number
  image?: string
  screen: string
  url: string
  width: number
}

export interface VariantSubmitData {
  id?: string
  categories?: string[]
  customerGroups?: string[]
  code?: string
  codeType?: string
  image?: string | ImageFile
  locations: string[]
  preview?: {
    h: number
    w: number
  }
  url?: string
  metadata?: VariantMetadata[]
  responsiveImage?: ResponsiveImage[]
  templateName?: string
}

export interface ScreenTypeCommon {
  [index: string]: boolean
  desktop: boolean
  phone: boolean
  tablet: boolean
}

export interface WebBannersResponse {
  codelib: string
  data: BannerRemoteSchemeCommon[]
}

export interface BannerRemoteSchemeCommon {
  adobeAnalytics?: string
  alternateText?: string
  appType?: string
  archived: boolean
  bannerSortOrder?: Record<string, number>
  code?: {
    code?: string
    html?: string
  }
  createdAt: number | string
  createdBy: string
  deleted: boolean
  device?: string
  devices?: Record<string, boolean>
  id: string
  image?: string
  location?: string
  locations: string[]
  name: string
  order: number
  orientation?: 'portrait' | 'landscape'
  preview?: VariantPreview
  published: boolean
  responsiveImage?: ResponsiveImage[]
  schedules: ScheduleType[]
  screenTypes: ScreenTypeCommon
  slot: number
  updatedAt?: number | string
  updatedBy?: string
  url?: string
  variants: Variant[]
  webSlots: string[]
}

export interface BannerTypePartial {
  id: string
  schedules: ScheduleType[]
  screenTypes: ScreenType[]
  locations: string[]
  webSlots: string[]
  name: string
  alternateText?: string
  published: boolean
  responsiveImage?: ResponsiveImage[]
  bannerSortOrder?: Record<string, number>
  url?: string
}

export const DEFAULT_SCREENS = ['phone', 'tablet', 'desktop'] as ScreenType[]

export const remoteSchemeCommonToBanner = (remote: BannerRemoteSchemeCommon): BannerTypePartial => {
  let screenTypes: ScreenType[] = []
  if (remote && typeof remote === 'object' && typeof remote.screenTypes === 'object') {
    screenTypes = Object.keys(remote.screenTypes)?.filter((key) => !!remote.screenTypes[key]).map((key) => key as ScreenType)
  }
  if (screenTypes.length === 0) {
    screenTypes = DEFAULT_SCREENS
  }
  return {
    id: remote.id,
    schedules: remote.schedules,
    alternateText: remote.alternateText,
    name: remote.name,
    published: remote.published,
    screenTypes,
    webSlots: remote.webSlots ?? [],
    locations: remote.locations ?? (remote.location ? [remote.location] : []),
    bannerSortOrder: remote.bannerSortOrder ?? {},
    url: remote.url
  }
}

export type BannerRemoteSchemeV4 = BannerRemoteSchemeCommon & {
  devices: Array<'web'>
  variants: Variant[]
  bannerSortOrder?: Record<string, number>
}

export const remoteSchemeV4ToBanner = (remote: BannerRemoteSchemeV4): BannerType => ({
  dirty: false,
  stale: false,
  ...remoteSchemeCommonToBanner(remote),
  variants: remote.variants
})

export interface BannersPrivateResponse {
  data: BannerRemoteSchemeV4[]
  locations: string[]
  total: number
  webSlots: string[]
}

export interface TemplateResponse {
  appType: string
  code: {
    compiled: string
    metadata: VariantMetadata[]
    raw: string
  }
  codeType: string
  createdAt: number
  createdBy: string
  image: string
  isCustom: boolean
  isDefault: boolean
  metadata: VariantMetadata[]
  name: string
  snippetId: string
  type: string
  updatedAt: number
  updatedBy: string
}

export interface BannerSubmitData {
  alternateText?: string
  appType: 'web'
  bannerSortOrder?: Record<string, number>
  devices: Array<'web'>
  id?: string
  locations: string[]
  name: string
  order: number
  orientation: 'landscape'
  preview?: {
    h: number
    w: number
  }
  published: boolean
  schedules: ScheduleType[]
  screenTypes: ScreenType[]
  url?: string
  variants: VariantSubmitData[]
  webSlots: string[]
}

// matches __v4()__ placeholder ids for unsaved banners
const placeholderID = /^__[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}__$/
export const isEphemeral = (banner: BannerType) => banner.draft === true || banner.id.startsWith('__')
export const bannerToBannerSubmitData = (banner: BannerType): BannerSubmitData => ({
  alternateText: banner.alternateText,
  appType: 'web',
  devices: ['web'],
  id: !placeholderID.test(banner.id) ? banner.id : undefined,
  locations: banner?.locations ?? [],
  name: banner.name,
  order: 0,
  orientation: 'landscape',
  preview: banner.preview,
  published: banner.published,
  schedules: banner.schedules,
  screenTypes: banner.screenTypes?.length ? banner.screenTypes : ['desktop', 'phone', 'tablet'],
  url: banner.url,
  variants: banner.variants.map(v => ({
    categories: v.categories ?? [],
    code: typeof v.code === 'string' ? v.code : v.code?.raw,
    codeType: v.codeType,
    customerGroups: v.customerGroups ?? [],
    image: v.image,
    locations: Array.isArray(v?.locations) ? v.locations : banner?.locations ?? [],
    metadata: v.metadata,
    responsiveImage: v.responsiveImage,
    templateName: v.templateName,
    url: v.url
  })),
  webSlots: banner.webSlots
})
export const updateBannerLocation = (banner: BannerType, slotId: string) => {
  return {
    ...banner,
    webSlots: [slotId],
    schedules: [
      {
        ...getDefaultSchedules(),
        slot: 1
      }
    ],
    variants: banner.variants?.map((v) => ({
      ...v,
      locations: [window.location.pathname]
    })),
    screenTypes: DEFAULT_SCREENS,
    locations: [window.location.pathname],
    stale: false,
    dirty: true,
    published: false
  }
}
