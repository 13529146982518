import React, {
  useState,
  useRef,
  useEffect,
  type FC,
  useMemo
} from 'react';
import {
  SelectPickerContainer,
  OptionList,
  Option,
  Wrapper
  // SelectPickerPanel
} from './styles';
import { DownOutlined } from '@ant-design/icons';
import { type SelectValue } from 'antd/lib/select';
import { type SelectProps } from 'antd';
import TextInput from 'components/TextInput';
import { useMemoizedFn } from 'ahooks';

export interface SelectPickerProps
  extends Omit<SelectProps<SelectValue>, 'options'> {
  onChange?: (value: SelectValue) => void
  options?: SelectPickerOption[]
  selectedValue?: string
  value?: string
  placeholder?: string
  multiple?: boolean
  dismissTarget?: Element
}

export interface SelectPickerOption {
  value: string | number
  label: string
  disabled?: boolean
}

const SelectPicker: FC<SelectPickerProps> = ({
  options,
  onChange,
  multiple = false,
  selectedValue,
  placement = 'bottomLeft',
  dismissTarget = window.document,
  defaultValue
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectPickerOption[]>([]);
  const handleInputClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = useMemoizedFn(({ target }: Event) => {
    if (
      wrapperRef.current &&
      target &&
      !wrapperRef.current.contains(target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  });

  useEffect(() => {
    const modalRoot = wrapperRef.current?.closest('.modal-root') as HTMLElement
    if (modalRoot) {
      modalRoot.addEventListener('click', handleClickOutside);
    } else {
      (dismissTarget ?? document).addEventListener('click', handleClickOutside);
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeEventListener('click', handleClickOutside);
      } else {
        (dismissTarget ?? document).removeEventListener('click', handleClickOutside);
      }
    };
  }, [dismissTarget, handleClickOutside]);

  useEffect(() => {
    const [option] = options?.filter(({ value: optionValue }) => optionValue === selectedValue) ?? options?.filter(({ value: optionValue }) => optionValue === defaultValue) ?? []
    if (selectedValue) {
      setSelectedOptions([option])
    }
  }, [defaultValue, options, selectedValue])

  const inputValue = useMemo(() => selectedOptions.map(({ label }) => label).join(', '), [selectedOptions])

  const handleOptionClick = (option: SelectPickerOption) => {
    if (multiple) {
      setSelectedOptions((prev) =>
        prev.includes(option)
          ? prev.filter((o) => o !== option)
          : [...prev, option]
      );
    } else {
      setSelectedOptions([option]);
      setIsDropdownOpen(false);
    }
    onChange?.(option.value);
  };

  const renderOptions = () =>
    options?.map((option) => (
      <Option
        key={option.value}
        onClick={() => {
          handleOptionClick(option);
        }}
        selected={selectedOptions.some(selectedOption => selectedOption.value === option.value)}
      >
        {option.label}
      </Option>
    ));

  return (
    <Wrapper ref={wrapperRef}>
      <TextInput
        value={inputValue}
        onClick={handleInputClick}
        readOnly
        suffix={<DownOutlined onClick={handleInputClick} />}
      />
      {isDropdownOpen && (
        <SelectPickerContainer placement={placement}>
          <OptionList>{renderOptions()}</OptionList>
        </SelectPickerContainer>
      )}
    </Wrapper>
  );
};

export default SelectPicker;
