import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ShowBanner from 'components/ShowBanner'
import { type Variant, type BannerType } from 'utils/types'
import EditBannerToolbar from 'components/EditingMenu/EditBannerToolbar'
import BannerListModal from 'components/BannerListModal'
import { addBannerToSlot, updateBannerInSlot, markBannerStale, setSlotLoading } from 'redux/slotsSlice'
import BannerFormModal from './BannerFormModal'
import ViewCodeModal from './BannerFormModal/ViewCodeModal'
import CodeLibraryModal from './CodeLibraryModal'
import { bannersListStateSelector, transpileCode } from 'redux/bannersSlice'
import { parseMetadata, replaceTemplateData } from 'utils/metadata'
import { useMemoizedFn } from 'ahooks'
import { convertSchedules } from 'utils/date'
import { CURRENT_URL } from 'utils/urlParams'
import { variantsForLocation } from 'services/banners.public'
import { v4 } from 'uuid'
import { validateVariants } from './BannerFormModal/BannerFormModal'
import { copyBanners } from './CreateBanner'
import { updateBannerLocation } from 'utils/adapters'
import { unescapeVariantHTML } from 'utils/mapPublicBanners'

interface Props {
  banner: BannerType
  slotId: string
  stale: boolean
}

const BannerInfoStyle = {
  backgroundColor: 'var(--border-color,#50C12E)',
  color: '#fff',
  cursor: 'text',
  fontSize: '14px',
  padding: '2px 5px',
  position: 'absolute' as React.CSSProperties['position'],
  left: '6px',
  top: '6px',
  zIndex: 2
}

const EditBanner: React.FC<Props> = ({
  banner,
  slotId,
  stale
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const [showEditModal, setShowEditModal] = useState(false)
  const [variantId, setVariantId] = useState('')
  const [viewCodeVisible, setViewCodeVisible] = useState(false)
  const [showListModal, setShowListModal] = useState(false)
  const [showLibraryModal, setShowLibraryModal] = useState(false)
  const [bannerState, setBannerState] = useState<string>('')
  const { defaultTemplate: template } = useSelector(bannersListStateSelector)
  const { displayCode } = useSelector(bannersListStateSelector)
  const [htmlCode, setHtmlCode] = useState(banner?.variants?.[0].code?.html ?? '')

  const handleShowListModal = (show: boolean) => {
    setShowListModal(show)
  }

  const handleShowLibraryModal = (show: boolean) => {
    setShowLibraryModal(show)
  }

  useEffect(() => {
    if (banner.dirty) setBannerState('dirty')
    if (stale && banner?.id) {
      setBannerState('stale')
      dispatch(markBannerStale({ id: banner.id }))
    }
  }, [banner.dirty, banner.id, dispatch, stale])

  const renderBanner = useMemoizedFn(() => <div style={BannerInfoStyle}>#{banner.id}</div>)
  const origVariants = banner?.variants ?? []
  const variants = variantsForLocation(origVariants, CURRENT_URL)
  const bannerizedVariants: BannerType[] = variants.map((variant) => ({
    ...banner,
    variants: [variant]
  }))
  // add any banners that didn't have variants
  if (!bannerizedVariants.some((b) => b.id === banner.id)) {
    bannerizedVariants.push(banner)
  }

  return bannerizedVariants.map((banner) => (
    <Wrapper key={banner.variants[0].id} data-state={bannerState} ref={containerRef}>
      <Container>
        <ShowBanner banner={banner} renderBannerInfo={renderBanner} />
        <EditBannerToolbar
          remoteBanner={banner}
          slotId={slotId}
          openEditModal={() => {
            setShowEditModal(true)
            setVariantId(banner.variants[0].id ?? '')
          }}
          onSelectFromBannerList={() => {
            handleShowListModal(true)
            setVariantId(banner.variants[0].id ?? '')
          }}
        />
      </Container>
      <BannerFormModal
        open={showEditModal && variantId === banner.variants[0].id}
        htmlCode={htmlCode}
        banner={banner}
        slotId={slotId}
        onClickSelectFromList={() => { handleShowLibraryModal(true) }}
        onCancel={() => {
          setShowEditModal(false);
          setViewCodeVisible(false)
        } }
        onSubmit={(values) => {
          const { id: bannerId, schedules } = values
          const submittedVariant = [...(values.variants ?? [])]

          const isValid = validateVariants(submittedVariant)
          if (!isValid) return

          const origIndex = origVariants.findIndex((v) => v.id === banner.variants[0].id)
          if (origIndex) {
            const variants = [...origVariants]
            variants.splice(origIndex, 1, submittedVariant[0])
            values.variants = variants
          }
          values.schedules = convertSchedules(schedules)

          const { variants } = values
          if (Array.isArray(variants) && variants.length > 0) {
            setShowEditModal(false)
            dispatch(setSlotLoading({ slotId, bannerId }))

            const variant = submittedVariant[0]
            const { code, codeType, metadata } = variant
            if (code && codeType) {
              const { code: tmpCode, id: displayCodeId } = displayCode
              // if preview has already compiled our code, proceed
              dispatch(tmpCode?.length > 0 && displayCodeId === bannerId
                ? updateBannerInSlot({
                  slotId,
                  updatedBanner: {
                    ...values,
                    variants: values.variants.map((variant: Variant, index: number) => ({
                      ...variant,
                      code: index === origIndex
                        ? {
                            raw: codeType === 'html' ? htmlCode : code,
                            compiled: codeType === 'html' ? htmlCode : tmpCode,
                            html: codeType === 'html' ? htmlCode : undefined
                          }
                        : variant.code
                    }))
                  }
                })
                : transpileCode({
                  bannerId,
                  code: replaceTemplateData(typeof code === 'object' ? code.raw : code, metadata),
                  codeType: codeType ?? 'jsx',
                  onSuccess: (code) => {
                    // values from form would only have raw code
                    // don't wipe out object and break banner display until user saves
                    values.variants[origIndex].code = {
                      raw: variant.code,
                      compiled: code
                    }
                    dispatch(updateBannerInSlot({ slotId, updatedBanner: values }))
                  },
                  onFailure: (err) => {
                    console.error(err)
                  }
                }))
            } else {
              dispatch(updateBannerInSlot({ slotId, updatedBanner: values }))
            }
          }
        }}
        onViewCode={() => { setViewCodeVisible(true); }}
      />
      <ViewCodeModal
        open={viewCodeVisible && variantId === banner.variants[0].id}
        rawCode={banner?.variants[0]?.code?.raw ?? template.raw}
        codeType={banner?.variants[0]?.codeType}
        onCancel={() => {
          setViewCodeVisible(false)
        }}
        onSave={(code) => {
          setViewCodeVisible(false)
          if (banner.variants[0].code) {
            setHtmlCode(code)
            dispatch(updateBannerInSlot({
              slotId,
              updatedBanner: {
                ...banner,
                variants: banner.variants?.map((v) => ({
                  ...v,
                  code: {
                    raw: code,
                    compiled: code,
                    html: unescapeVariantHTML(code)
                  }
                }))
              }
            }))
          }
        }}
      />
      {showListModal && variantId === banner.variants[0].id && (
        <BannerListModal
          open
          onConfirm={(banners: BannerType[]) => {
            copyBanners(banners, slotId).map((banner) =>
              dispatch(addBannerToSlot({
                slotId,
                banner: updateBannerLocation(banner, slotId)
              })))
            handleShowListModal(false)
          }}
          onClose={() => {
            handleShowListModal(false)
          }}
        />
      )}
      {showLibraryModal && variantId === banner.variants[0].id && (
        <CodeLibraryModal
          open
          onConfirm={(snippet) => {
            const updatedBanner: BannerType = {
              ...banner,
              name: `${snippet.name} Copy`,
              variants: [
                {
                  id: v4(),
                  categories: [],
                  code: snippet.code,
                  codeType: snippet.codeType,
                  metadata: snippet.metadata?.length ? snippet.metadata : parseMetadata(snippet.code?.raw ?? ''),
                  locations: [window.location.pathname],
                  templateName: snippet.name
                }
              ],
              dirty: true,
              stale: false
            }
            dispatch(updateBannerInSlot({ slotId, updatedBanner }))
            handleShowLibraryModal(false)
            setShowEditModal(false)
          }}
          onClose={() => {
            handleShowLibraryModal(false)
          }}
        />
      )}
    </Wrapper>
  ))
}

export default EditBanner

export const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 0;
  background-color: #E5E5E5;
  border-style: var(--border-style, solid);
  border-color: var(--border-color, #50C12E);
  border-width: 0;
  box-sizing: border-box;
  position: relative;

  &[data-state="dirty"] {
    --border-color: #fcaa05;
  }

  &[data-state="stale"] {
    --border-color: red;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  min-height: 150px;
  transition: opacity 150ms ease-in;
  cursor: grab;

  img {
    box-sizing: border-box;
    display: block;
    object-fit: contain;
    max-width: 100%;
    height: auto;
  }

  .component:hover {
    filter: brightness(70%);
  }

  &:before {
    border: 2px solid var(--border-color, #50C12E);
    content: '';
    height: calc(100% - 16px);
    position: absolute;
    width: calc(100% - 16px);
    z-index: 2;
  }

  &:hover {
    > div {
      opacity: 1;
      pointer-events: all;
    }
  }

  &:active {
    opacity: 1;
    cursor: grabbing;
  }
`
