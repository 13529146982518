import React, { type FC, useEffect, useMemo, useState } from 'react'
import { Collapse, Empty, List, Typography } from 'antd'
import { FolderOpenFilled, FolderFilled } from '@ant-design/icons'
import { type CodeSnippet } from 'services/codeLibrary.d'
import CodePanel from 'components/CodePanel'
import { CodeLibraryContainer, Previewer, PreviewImage, PreviewPanel, SnippetListPanel } from './styles'
import theme from 'config/theme'

interface CustomCodeLibraryListProps {
  items: CodeSnippet[]
  onSelect: (snippet: CodeSnippet) => void
  selected: CodeSnippet | null
  customKey: string
}

interface GroupedItems {
  name: string
  items: CodeSnippet[]
  itemsId: string[]
}

const CustomCodeLibraryList: FC<CustomCodeLibraryListProps> = ({ items, onSelect, selected, customKey }) => {
  const [activeKey, setActiveKey] = useState<string[]>([`${customKey}-0`]);

  const groupedItems: GroupedItems[] = useMemo(() => {
    return Object.values(
      items.reduce((acc: Record<string, GroupedItems>, item) => {
        const { type } = item

        if (!acc[type]) {
          acc[type] = {
            name: type,
            items: [],
            itemsId: []
          }
        }

        acc[type].items.push(item)
        acc[type].itemsId.push(item.id)
        return acc
      }, {})
    )
  }, [items])

  const onChangeActiveKey = (key: string[]) => {
    setActiveKey(key);
  }

  useEffect(() => {
    if (selected !== null && !!selected?.id) {
      const selectedIndex = groupedItems.findIndex((section) => section.itemsId.includes(selected.id));
      if (selectedIndex !== -1) {
        setActiveKey([`${customKey}-${selectedIndex}`]);
      }
    }
  }, [customKey, groupedItems, selected]);

  if (items.length === 0) {
    return (
      <Empty />
    )
  }

  return (
    <CodeLibraryContainer>
      <SnippetListPanel>
        <Collapse
          collapsible='header'
          accordion
          expandIcon={({ isActive }) => (isActive ? <FolderOpenFilled /> : <FolderFilled />)}
          bordered={false}
          activeKey={activeKey}
          onChange={onChangeActiveKey}
          items={groupedItems.map((gi, index) => {
            const isActiveSection = gi.items.some(item => item.id === selected?.id);
            return {
              key: `${customKey}-${index}`,
              label: gi.name,
              styles: { header: isActiveSection ? { color: theme.token?.colorPrimary } : undefined },
              children: <List
                size='small'
                dataSource={gi.items}
                renderItem={({ name, ...item }) => {
                  const style = {
                    color: selected?.id === item.id ? theme.token?.colorPrimary : 'inherit',
                    fontWeight: item.isDefault ? 900 : 400
                  }
                  return (<List.Item onClick={() => { onSelect({ name, ...item }) }} style={style}>
                    {name}
                  </List.Item>)
                }}
              />
            }
          })}
        />
      </SnippetListPanel>
      <PreviewPanel>
        <Typography.Title level={3}>{selected?.name}</Typography.Title>

        <Typography.Title level={4}>
          <span>Preview</span>
        </Typography.Title>
        <Previewer>
          <PreviewImage src={selected?.image} alt=""/>
        </Previewer>

        <Typography.Title level={4}>
          {'Code'}
        </Typography.Title>
        <CodePanel
          value={selected?.code?.raw} setValue={() => {}} />
      </PreviewPanel>
    </CodeLibraryContainer>
  )
}

export default CustomCodeLibraryList
