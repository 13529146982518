import React from 'react'
import styled from 'styled-components'
import { AiOutlinePlus } from 'react-icons/ai'
import theme from 'config/theme'

interface Props {
  onClick?: () => void
  onMouseEnter?: (e: React.MouseEvent) => void
  onMouseLeave?: (e: React.MouseEvent) => void
}

const AddBannerButton: React.FC<Props> = ({ onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <IconContainer onClick={onClick}>
        <AiOutlinePlus color="#fff" />
      </IconContainer>
    </Container>
  )
}

export default AddBannerButton

const Container = styled.div`
  position: absolute;
  bottom: 25px;
  right: -12.5px;
  z-index: 1;
  padding-left: 40px;
`

const IconContainer = styled.button`
  align-items: center;
  background: #0559fd;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-family: ${theme.token?.fontFamily};
  height: 20px;
  justify-content: center;
  pointer-events: all;
  width: 24px;

  svg {
    cursor: 'pointer';
  }
`
