// eslint-disable no-document-cookie
import { type ClientCookie, CookieFlags } from 'config/types';

const useCookieStore = 'cookieStore' in window;

export const getCookieSync = (name: string): string | null => {
  const cookies = document.cookie;
  if (!cookies) return null;

  const nameEQ = `${name}=`;
  const start = cookies.indexOf(nameEQ);
  if (start === -1) return null;

  const end = cookies.indexOf(';', start);
  const value = cookies.substring(start + nameEQ.length, end === -1 ? undefined : end);
  return decodeURIComponent(value);
};
export const getCookie = async (name: string): Promise<string | null> => {
  if (useCookieStore) {
    const cookie = await window.cookieStore.get(name);
    return cookie ? cookie.value : null;
  } else {
    return getCookieSync(name)
  }
};

const REMOVE_LEADING_WWW_RE = /^www/
const getCurrentDomain = (): string => location.hostname.split('.').slice(-2).join('.')
export const setCookie = async (
  name: string,
  value: string,
  days: number = 30,
  options: { path?: string, domain?: string, secure?: boolean, sameSite?: 'None' | 'Strict' | 'Lax' } = {}
) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));

  if (useCookieStore) {
    try {
      await window.cookieStore.set({
        name,
        value,
        expires,
        path: options.path ?? '/',
        domain: (options.domain ?? getCurrentDomain()).replace(REMOVE_LEADING_WWW_RE, ''),
        secure: options.secure,
        sameSite: options?.sameSite?.toLowerCase() // must be lower-case for CookieStore enum
      });
    } catch (ex) {
      console.error(ex)
    }
  } else {
    const domain = (options.domain ?? getCurrentDomain()).replace(REMOVE_LEADING_WWW_RE, '');
    const path = options.path ?? '/';
    const secure = options.secure ? 'Secure;' : '';
    const sameSite = options.sameSite ? `SameSite=${options.sameSite};` : '';

    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=${path}; domain=.${domain}; ${secure}${sameSite}`;
  }
};

export const clearCookie = async (
  name: string,
  options: { path?: string, domain?: string } = {}
) => {
  const domain = (options.domain ?? getCurrentDomain()).replace(REMOVE_LEADING_WWW_RE, '');
  const path = options.path ?? '/';
  if (useCookieStore) {
    await window.cookieStore.delete({
      name,
      domain,
      path
    });
  } else {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=.${domain}`;
  }
};

export const mapClientCookies = (cookiesConfig?: string): ClientCookie[] => {
  if (!cookiesConfig) return []

  return cookiesConfig.split(',').map((cookieConfig) => {
    const [name, flags] = cookieConfig.split('|')
    if (!name || !flags || !Number.isInteger(+flags)) {
      return { name, encoded: false, json: false }
    }

    const numFlag = +flags;
    return {
      name,
      encoded: !!(numFlag & CookieFlags.BASE64),
      json: !!(numFlag & CookieFlags.JSON)
    }
  })
}

export const createCookieValueMaps = (cookiesMapConfig?: string): Map<string, any> => {
  const cookieMap = new Map<string, any>()

  if (!cookiesMapConfig) return cookieMap

  try {
    const cookiesConfig = JSON.parse(cookiesMapConfig)
    Object.keys(cookiesConfig).forEach((cookieName) => {
      cookieMap.set(cookieName, cookiesConfig[cookieName])
    })
  } catch (ex: any) {
    console.error(`Could not parse client cookie value maps: ${ex.message}`)
  }

  return cookieMap
}
