import { type User } from '@auth0/auth0-react'
import {
  type BaseQueryFn,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'
import { appConfig } from 'config'
import { signOut, signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth'
import fb from '../firebaseConfig'

const rawBaseQuery = fetchBaseQuery({
  baseUrl: appConfig.baseUrl
})

const baseQuery: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = (args, api, extraOptions) => rawBaseQuery(args, api, extraOptions)

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: builder => ({
    signIn: builder.mutation<any, { email: string, password: string }>({
      async queryFn ({ email, password }, _queryApi, _extraOptions) {
        try {
          await signInWithEmailAndPassword(fb.auth, email, password)
          const tokenResult = await fb.auth.currentUser?.getIdTokenResult()
          return { data: tokenResult }
        } catch (error: any) {
          return { error }
        }
      }
    }),
    signInWithCustomToken: builder.mutation<any, { token: string }>({
      async queryFn ({ token }, _queryApi, _extraOptions) {
        try {
          await signInWithCustomToken(fb.auth, token)
          const { token: fbToken, claims } = await fb.auth.currentUser?.getIdTokenResult() ?? {}
          const { exp } = claims ?? {}
          return { data: { token: fbToken, expirationTime: exp } }
        } catch (error: any) {
          return { error }
        }
      }
    }),
    signOut: builder.mutation<any, unknown>({
      async queryFn () {
        try {
          await signOut(fb.auth)
          return { data: undefined }
        } catch (error: any) {
          return { error }
        }
      }
    }),
    createFirebaseToken: builder.mutation<any, { user: User, token: string }>({
      query: body => ({
        url: `api/firebase-token`,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useCreateFirebaseTokenMutation,
  useSignInWithCustomTokenMutation,
  useSignInMutation,
  useSignOutMutation
} = authApi
