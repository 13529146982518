import styled from 'styled-components';

interface SelectPickerContainerProps {
  placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
}

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const SelectPickerContainer = styled.div<SelectPickerContainerProps>`
  position: absolute;
  ${(props) => {
    switch (props.placement) {
      case 'topLeft':
        return 'bottom: 100%; left: 0;';
      case 'topRight':
        return 'bottom: 100%; right: 0;';
      case 'bottomLeft':
        return 'top: 100%; left: 0;';
      case 'bottomRight':
        return 'top: 100%; right: 0;';
      default:
        return 'top: 100%; left: 0;';
    }
  }}
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;

export const OptionList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Option = styled.li<{ selected?: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }

  ${(props) =>
    props.selected &&
    `
    background-color: #1890ff;
    color: white;
  `}
`;
