import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type BannerType } from '../utils/types'
import { type RootState } from './store'

interface State {
  banners: BannerType[]
  loading: boolean
}

const initialState: State = {
  banners: [],
  loading: false
}

const NAME = 'bannersCatalog'

const { actions, reducer } = createSlice({
  name: NAME,
  initialState,
  reducers: {
    loadBannersCatalog () {},
    setLoadingBannersCatalog (state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading
    },
    setBannersCatalog (state, action: PayloadAction<{ banners: BannerType[] }>) {
      state.banners = action.payload.banners
    }
  }
})

export const {
  loadBannersCatalog,
  setLoadingBannersCatalog,
  setBannersCatalog
} = actions

export default reducer

export const bannersCatalogStateSelector = (state: RootState) => state.bannersCatalog
