import api from '../utils/api'
import { startAppListening } from './listenerMiddleware'
import { type BannerType } from '../utils/types'
import { type BannerRemoteSchemeV4, remoteSchemeV4ToBanner } from '../utils/adapters'
import { loadBannersCatalog, setBannersCatalog, setLoadingBannersCatalog } from './bannersCatalogSlice'

startAppListening({
  actionCreator: loadBannersCatalog,
  effect: async (_action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    try {
      listenerApi.dispatch(setLoadingBannersCatalog({ loading: true }))
      const res = await api.getBanners()
      const banners: BannerType[] = res.result.data.map((r: BannerRemoteSchemeV4) => remoteSchemeV4ToBanner(r))
      listenerApi.dispatch(setBannersCatalog({ banners }))
    } catch (e: any) {
      console.error(`Failed to load banner catalog - ${e}`)
    } finally {
      listenerApi.dispatch(setLoadingBannersCatalog({ loading: false }))
    }
  }
})
