import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { StyleProvider } from '@ant-design/cssinjs'
import { StyleSheetManager } from 'styled-components'
import { type BannerType } from 'utils/types'
import SlotItem from 'components/SlotItem'
import { ShadowRootProvider } from 'contexts/shadowRoot'
import { ConfigProvider } from 'antd'
import theme from 'config/theme'
import { BILDIT_DATA_ATTR } from 'utils/urlParams'
import { addGlobalStylesToShadowRoot } from 'utils/cloneStyles'

interface SlotPortalProps {
  banners: BannerType[]
  slotId: string
  isListLoading: boolean
}

const SlotPortal: React.FC<SlotPortalProps> = ({
  banners,
  slotId,
  isListLoading
}) => {
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot | undefined>()
  const [styleSlot, setStyleSlot] = useState<HTMLDivElement | undefined>()
  const [popupContainer, setPopupContainer] = useState<HTMLElement | undefined>()
  const SELECTOR = `[${BILDIT_DATA_ATTR}=${slotId}]`

  useEffect(() => {
    if (!shadowRoot) {
      const targetElement = Array.isArray(window.BILDIT?.pageSlots)
        ? window.BILDIT?.pageSlots.find((slot) => slot.matches(SELECTOR))
        : document.querySelector(SELECTOR)

      if (targetElement) {
        targetElement.innerHTML = ''

        if (!targetElement?.shadowRoot) {
          const shadow = targetElement.attachShadow({ mode: 'open' })
          if (shadow) {
            setShadowRoot(shadow)
            addGlobalStylesToShadowRoot(shadow)
          }
        }
      }
    }
  }, [shadowRoot, slotId, SELECTOR])

  useEffect(() => {
    if (!shadowRoot) return

    const styleKey = `BILDIT_style_slot_${slotId}`
    const popupKey = `BILDIT_container_${slotId}`
    const styleDiv = document.getElementById(styleKey)
    if (!styleDiv) {
      const styleSlot = document.createElement('div')
      styleSlot.id = styleKey
      shadowRoot.appendChild(styleSlot)
      setStyleSlot(styleSlot)
    }
    const popupSection = document.getElementById(popupKey)
    if (!popupSection) {
      const popupContainer = document.createElement('section')
      popupContainer.id = popupKey
      shadowRoot.appendChild(popupContainer)
      setPopupContainer(popupContainer)
    }
  }, [shadowRoot, slotId])

  if (!shadowRoot || !popupContainer) return null

  return createPortal(
    <ShadowRootProvider shadowRoot={shadowRoot}>
      <ConfigProvider theme={theme} getPopupContainer={() => popupContainer}>
        <StyleProvider container={shadowRoot}>
          <StyleSheetManager target={styleSlot}>
            <SlotItem
              key={slotId}
              banners={banners}
              isListLoading={isListLoading}
              slotId={slotId}
            />
          </StyleSheetManager>
        </StyleProvider>
      </ConfigProvider>
    </ShadowRootProvider>,
    shadowRoot
  )
}

export default SlotPortal
