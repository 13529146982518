import React, { type ReactElement, useMemo, useState } from 'react'
import Remote from 'remote/Remote'
import type { BannerTypeRender, BannerType, Variant } from 'utils/types'
import breakpoints from 'utils/breakpoints'
import useSiteDate from 'hooks/useSiteDate'
import { isCurrentDateWithinRange } from 'utils/date'
import ReactShadowRoot from 'react-shadow-root'
import type { ImageDimensionsNumeric } from 'utils/metadata'
import { isEphemeral } from 'utils/adapters'
import { useClientCookies, type ParsedCookie } from 'contexts/cookies'
import { appConfig } from 'config'
import useHasAccount from 'hooks/useHasAccount'
import { useWindowSize } from 'contexts/window'
import { getGlobalStyleSheets } from 'utils/cloneStyles'
import HTMLBanner from './HTMLBanner'
import { unescapeVariantHTML } from 'utils/mapPublicBanners'

interface ShowBannerProps {
  banner: BannerType | BannerTypeRender
  absolute?: boolean
  renderBannerInfo?: () => ReactElement
}

const CANVAS_TILE_SIZE = 32
const screenTypeToBreakpointKey: Record<string, string[]> = JSON.parse(appConfig.breakpointsMap)

const CREATE_EL_RE = /(?:createElement\(|jsxRuntime\.jsx\))\(?['"]style['"]/
const JS_NEEDS_RE = /(useEffect\(|useState\(|window\.|react\.useEffect|react\.useState)/

const STYLES = Object.freeze({
  bannerInfo: {
    backgroundColor: 'var(--border-color,#50C12E)',
    color: '#fff',
    cursor: 'text',
    fontSize: '14px',
    padding: '2px 5px',
    position: 'absolute',
    left: '6px',
    top: '6px',
    zIndex: 2
  } satisfies React.CSSProperties,
  bannerWrapper: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    width: '100%'
  } satisfies React.CSSProperties,
  blurHash: {
    background: '#fff',
    left: '50%',
    margin: '0 auto',
    maxWidth: '100%',
    position: 'absolute',
    top: '50%',
    translate: '-50% -50%',
    zIndex: 1
  } satisfies React.CSSProperties,
  codeBannerContainer: {
    margin: '0 auto',
    position: 'relative'
  } satisfies React.CSSProperties,
  fullWidth: {
    maxWidth: '100%'
  },
  img: {
    background: 'no-repeat left',
    backgroundSize: 'contain',
    boxSizing: 'border-box',
    height: '100%',
    margin: '0 auto',
    maxWidth: '100%',
    position: 'relative'
  } satisfies React.CSSProperties,
  wrapperAbsolute: {
    height: '100%',
    position: 'absolute',
    right: '0',
    top: '0',
    width: '100%'
  } satisfies React.CSSProperties
} as const)

const useBannerVisibility = (
  banner: BannerType | BannerTypeRender,
  size: ImageDimensionsNumeric,
  cookies?: ParsedCookie[]
) => {
  return useMemo(() => {
    const visibleByBreakpoint = !Array.isArray(banner.screenTypes)
      ? true
      : banner?.screenTypes?.some((type) =>
        screenTypeToBreakpointKey?.[type]?.some(
          (key) => size.width >= breakpoints[key].min && size.width < breakpoints[key].max
        )
      )

    const visibleByGroup = !Array.isArray(cookies) || cookies.length === 0 ||
      banner.variants.some((variant) => {
        const customerGroups = variant?.customerGroups
        return !Array.isArray(customerGroups) || customerGroups.some(group =>
          cookies.some(cookie => cookie.value && cookie.map?.[group]?.includes(cookie.value))
        )
      })

    return visibleByBreakpoint && visibleByGroup
  }, [banner, size, cookies])
}

const bannerNeedsJS = (variant: Variant): boolean => {
  if (!variant?.code) return false
  return JS_NEEDS_RE.test(variant.code.raw ?? '') || JS_NEEDS_RE.test(variant.code.compiled ?? '')
}

const ShowBanner: React.FC<ShowBannerProps> = ({ banner, absolute, renderBannerInfo }) => {
  const { cookies } = useClientCookies()
  const hasAccount = useHasAccount()
  const providedDate = useSiteDate()
  const [isLoaded, setIsLoaded] = useState(false)
  const size = useWindowSize()
  const isVisible = useBannerVisibility(banner, size, cookies)

  const containerClass = absolute ? STYLES.wrapperAbsolute : STYLES.bannerWrapper

  const {
    html: bannerHtml,
    compiled: bannerCode,
    image: bannerImage,
    url: bannerUrl,
    codeType
  } = useMemo(() => {
    const [variant] = banner?.variants ?? []

    const image = !variant && banner?.image
      ? {
          image: banner.image,
          preview: banner.preview
        }
      : {
          image: variant?.image,
          preview: variant?.preview
        }

    return {
      image,
      compiled: variant?.code?.compiled,
      html: bannerNeedsJS(variant) ? null : unescapeVariantHTML(variant?.code?.html),
      url: variant?.url ?? banner?.url,
      codeType: variant?.codeType
    }
  }, [banner])

  const isBannerInSchedule = useMemo(() => {
    return banner?.schedules.some(schedule => isCurrentDateWithinRange(schedule, providedDate ?? new Date()))
  }, [banner, providedDate])

  const shouldShowBanner = useMemo(() => {
    return providedDate
      ? isVisible && isBannerInSchedule
      : hasAccount || (isVisible && isBannerInSchedule)
  }, [providedDate, hasAccount, isVisible, isBannerInSchedule])

  const disablePlaceholder = () => { setIsLoaded(true) }

  const BannerInfo = useMemo(() => (
    hasAccount && renderBannerInfo && !isEphemeral(banner) ? renderBannerInfo?.() : null
  ), [hasAccount, banner, renderBannerInfo])

  if (!shouldShowBanner) return null

  let finalComponent = null

  if (bannerHtml) {
    if (bannerHtml.includes('<style')) {
      finalComponent = <ReactShadowRoot stylesheets={getGlobalStyleSheets()}><HTMLBanner html={bannerHtml} /></ReactShadowRoot>
    } else {
      finalComponent = <HTMLBanner html={bannerHtml} />
    }
  } else if (bannerCode) {
    finalComponent = CREATE_EL_RE.test(bannerCode)
      ? <ReactShadowRoot stylesheets={getGlobalStyleSheets()}>
          <Remote code={bannerCode} codeType={codeType} />
        </ReactShadowRoot>
      : <Remote code={bannerCode} codeType={codeType} />
  } else if (bannerImage?.image) {
    const preview = bannerImage?.preview
    const previewW = preview?.w ?? 1
    const previewH = preview?.h ?? 1

    const tileWidth = preview ? Math.ceil(previewW / CANVAS_TILE_SIZE) : CANVAS_TILE_SIZE
    const tileHeight = preview ? Math.ceil(previewH / CANVAS_TILE_SIZE) : CANVAS_TILE_SIZE

    const scaleX = (preview ? Math.min(previewW, size.width) : size.width) / tileWidth
    const scaleY = (preview ? Math.min(previewH, size.height) : size.height) / tileHeight
    const scaleToFit = Math.max(scaleX, scaleY)

    const hasLink = bannerUrl && bannerUrl.length > 0
    const content = (
      <>
        {!isLoaded &&
          <canvas
            style={{
              ...STYLES.blurHash,
              transform: `scale(${scaleToFit})`
            }}
            height={tileHeight}
            width={tileWidth}
          />}
        <img style={STYLES.img} src={bannerImage.image} height={preview?.h} width={preview?.w} onError={disablePlaceholder} onLoad={disablePlaceholder} />
      </>
    );

    finalComponent = (
      <div style={containerClass}>
        {hasLink ? <a data-aali={banner.adobeAnalytics} href={banner.url}>{content}</a> : content}
      </div>
    )
  }

  return (
    <div id={banner.id} style={STYLES.fullWidth}>
      {BannerInfo}
      <section style={bannerCode ? STYLES.codeBannerContainer : undefined}>{finalComponent}</section>
    </div>
  )
}

export default React.memo(ShowBanner)
