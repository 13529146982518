import { Button } from 'antd'
import { ReactComponent as TrashIcon } from 'assets/icons/delete.svg'
import classNames from 'classnames'
import SelectedImageInputContent from 'components/SelectedImageInputContent'
import React, { type MouseEventHandler, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { type FieldError } from 'react-hook-form'
import type { ImageFile } from 'utils/types'

import {
  DragPanel,
  ErrorText,
  ImageActionsContainer,
  ImageContainer,
  ImageInput,
  ImageReference,
  InputErrorText,
  InputGroup,
  InputHelperText,
  InputLabel,
  InputLine
} from './styles'

const imageTypesAllowed = {
  'application/octet-stream': [],
  'image/avif': [],
  'image/gif': [],
  'image/jpeg': [],
  'image/png': [],
  'image/svg+xml': [],
  'image/webp': []
}

interface ImagePanelProps {
  value?: string
  onChange: (base64?: string) => void
  onClearImage?: () => void
  label?: string
  name?: string
  error?: FieldError | any
}

const ImagePanel: React.FC<ImagePanelProps> = ({ value, onChange, onClearImage, label, name, error }) => {
  const [file, setFile] = useState<ImageFile | undefined>()
  const [isRemoteUrl, setIsRemoteUrl] = useState<boolean>(false)
  const initImageFilename: string | null = null

  const [imageFileName, setImageFileName] = useState<string | null>(initImageFilename)
  const imageActionsRef = useRef<HTMLDivElement | null>(null)
  const urlInputRef = useRef<HTMLInputElement | null>(null)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: imageTypesAllowed,
    onDrop: (acceptedFiles) => {
      const [imageFile] = acceptedFiles
      if (!window.BILDIT?.persistent && // storing images as b64 strings for local storage limits us to ~5MB images
        imageFile.size > 1e6) {
        setInputError('Selected file is too large (~1MB limit due to current localStorage constraints')
        return
      }
      setFile(imageFile)
      setIsRemoteUrl(false)
    }
  })
  const [inputError, setInputError] = useState<string>('')

  const handleDelete = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation()
    setFile(undefined)
    setIsRemoteUrl(false)
    setImageFileName(null)
    onChange()
    onClearImage?.()
  }

  const handleAddFromUrl: MouseEventHandler = () => {
    try {
      const url = new URL(urlInputRef.current?.value ?? '')
      if (url) {
        setFile(undefined)
        setIsRemoteUrl(true)
        onChange(urlInputRef.current?.value)
      } else {
        setInputError('Not a valid URL')
      }
    } catch (error: any) {
      const errMsg = 'Invalid URL'
      setInputError(errMsg)
      console.error(`${errMsg}:`, error)
    }
  }

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        if (reader.result) {
          if (typeof reader.result === 'object' || isRemoteUrl) return
          const base64: string = reader.result
          onChange(base64)
          setIsRemoteUrl(false)
        }
      }
    }
  }, [file, isRemoteUrl, onChange])

  const inputClass = inputError.length > 0 ? 'error' : ''

  return (
    <>
      <ImageContainer className={classNames({ empty: !value, error: !!error })}>
        <DragPanel {...getRootProps()}>
          <input {...getInputProps()} />
          {isRemoteUrl && <img style={{ maxWidth: '100%' }} src={value} />}
          {!isRemoteUrl && <SelectedImageInputContent image={value} />}
          {value && (
            <>
              <ImageReference>{imageFileName ?? 'Remote/Loaded Image'}</ImageReference>
              <ImageActionsContainer ref={imageActionsRef}>
                <TrashIcon width={18} fill="#646464" onClick={handleDelete} />
              </ImageActionsContainer>
            </>
          )}
        </DragPanel>
        {!value && (
          <InputGroup>
            <InputLabel className={inputClass}>{label ?? 'Image URL'}</InputLabel>
            <InputLine>
              <ImageInput className={inputClass} ref={urlInputRef} name={name} />
              <Button type="primary" onClick={handleAddFromUrl}>ADD</Button>
            </InputLine>
            <InputHelperText>You can also add image using a URL</InputHelperText>
            {inputError.length > 0 && <InputErrorText>{inputError}</InputErrorText>}
          </InputGroup>
        )}
      </ImageContainer>
      {error && <ErrorText>{error?.message}</ErrorText>}
    </>
  )
}

export default ImagePanel
