import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import BannerListModal from 'components/BannerListModal'
import { useDispatch, useSelector } from 'react-redux'
import CreateBannerButton from 'components/EditingMenu/CreateBannerButton'
import { TEMP_ID, type BannerType } from 'utils/types'
import { addBannerToSlot, setSlotLoading } from 'redux/slotsSlice'
import BannerFormModal from './BannerFormModal'
import CodeLibraryModal from './CodeLibraryModal'
import CreateMenu from './CreateMenu'
import { convertSchedules, getDefaultSchedules, getTodaysSchedule } from 'utils/date'
import { parseMetadata, replaceTemplateData } from 'utils/metadata'
import { bannersListStateSelector, transpileCode } from 'redux/bannersSlice'
import { DEFAULT_SCREENS, updateBannerLocation } from 'utils/adapters'
import { v4 } from 'uuid'
import { validateMetadata } from './BannerFormModal/BannerFormModal'

interface Props {
  slotId: string
}

export const copyBanners = (banners: BannerType[], slotId: string): BannerType[] => {
  return banners.map(banner => {
    const bannerCopy = cloneDeep(banner)
    bannerCopy.name = `${bannerCopy.name} Copy`
    bannerCopy.id = TEMP_ID()
    bannerCopy.key = undefined
    bannerCopy.webSlots = [slotId]
    bannerCopy.variants[0].id = v4()
    bannerCopy.schedules = getTodaysSchedule()
    return bannerCopy
  })
}

const CreateBanner: React.FC<Props> = ({ slotId }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timeout>()
  const dispatch = useDispatch()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showListModal, setShowListModal] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const [showLibraryModal, setShowLibraryModal] = useState(false)
  const { displayCode } = useSelector(bannersListStateSelector)

  const handleShowListModal = (show: boolean) => {
    setShowListModal(show)
  }

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    setShowMenu(true)
  }
  const handleMouseLeave = (_e: React.MouseEvent) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setShowMenu(false)
    }, 50)
  }

  const handleShowLibraryModal = (show: boolean) => {
    setShowLibraryModal(show)
  }

  return (
    <Wrapper ref={containerRef}>
      <Container>
        <EmptyBackground>
          <EmptyLabel>ADD BANNER HERE</EmptyLabel>
        </EmptyBackground>
        <CreateBannerButtonWrapper
          ref={buttonRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <CreateBannerButton />
        </CreateBannerButtonWrapper>
        {showMenu && (
          <CreateMenu
            ref={menuRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onCreateNew={() => {
              setShowCreateModal(true)
            }}
            onSelectFromBannerList={() => {
              handleShowListModal(true)
            }}
          />
        )}
      </Container>
      <BannerFormModal
        open={showCreateModal}
        slotId={slotId}
        onCancel={() => { setShowCreateModal(false) }}
        onSubmit={(values) => {
          const { id: bannerId, schedules, variants } = values
          const variant = variants[0] ?? {}
          const { code, codeType, metadata } = variant

          const isValid = validateMetadata(metadata)
          if (!isValid) return

          values.schedules = convertSchedules(schedules)

          setShowCreateModal(false)
          if (code && codeType) {
            dispatch(setSlotLoading({ slotId, bannerId }))
            const { code: tmpCode, id: displayCodeId } = displayCode
            // if preview has already compiled our code, proceed
            dispatch(tmpCode?.length > 0 && displayCodeId === bannerId
              ? addBannerToSlot({
                slotId,
                banner: {
                  ...values,
                  variants: [{
                    ...variant,
                    code: {
                      raw: code,
                      compiled: tmpCode
                    }
                  }]
                }
              })
              : transpileCode({
                bannerId,
                code: replaceTemplateData(typeof code === 'object' ? code.raw : code, metadata),
                codeType: codeType ?? 'jsx',
                onSuccess: (code) => {
                  // values from form would only have raw code
                  // don't wipe out object and break banner display until user saves
                  values.variants[0].code = {
                    raw: variant.code,
                    compiled: code
                  }
                  dispatch(addBannerToSlot({ slotId, banner: values }))
                },
                onFailure: (err) => {
                  console.error(err)
                  dispatch(setSlotLoading({ slotId, bannerId }))
                }
              }))
          } else {
            dispatch(addBannerToSlot({ slotId, banner: values }))
          }
        }}
        onClickSelectFromList={() => { handleShowLibraryModal(true) }}
      />
      {showListModal && (
        <BannerListModal
          open
          onConfirm={(banners: BannerType[]) => {
            copyBanners(banners, slotId).map((banner) =>
              dispatch(addBannerToSlot({
                slotId,
                banner: updateBannerLocation(banner, slotId)
              })))
            handleShowListModal(false)
          }}
          onClose={() => {
            handleShowListModal(false)
          }}
        />
      )}
      {showLibraryModal && (
        <CodeLibraryModal
          open
          onConfirm={(snippet) => {
            const banner: BannerType = {
              name: `${snippet.name} Copy`,
              id: TEMP_ID(),
              key: undefined,
              webSlots: [slotId],
              variants: [
                {
                  id: v4(),
                  categories: [],
                  code: snippet.code,
                  codeType: snippet.codeType,
                  metadata: snippet.metadata?.length ? snippet.metadata : parseMetadata(snippet.code?.raw ?? ''),
                  locations: [window.location.pathname],
                  templateName: snippet.name
                }
              ],
              schedules: [
                {
                  ...getDefaultSchedules(),
                  slot: 1
                }
              ],
              screenTypes: DEFAULT_SCREENS,
              locations: [window.location.pathname],
              stale: false,
              dirty: true,
              published: false
            }
            dispatch(addBannerToSlot({ slotId, banner }))
            handleShowLibraryModal(false)
          }}
          onClose={() => {
            handleShowLibraryModal(false)
          }}
        />
      )}
    </Wrapper>
  )
}

export default CreateBanner

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding-top: 0;
  flex-direction: column;
`

const EmptyBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EmptyLabel = styled.div`
  color: #D9D9D9;
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
  user-select: none;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  
  &:hover > div {
    opacity: 1;
    pointer-events: all;
  }
`

const CreateBannerButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`
