import styled from 'styled-components'

export const ImageActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
`
export const ImageInput = styled.input`
  border: 1px solid #697b8c;
  outline: none;
  height: 34px;
  flex: 1;
  margin-right: 10px;
  padding: 0 10px;

  &.error {
    border-color: red;
  }
`
export const ImageReference = styled.p`
  color: black;
  text-align: left;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px 0;
  word-break: break-all;
  box-sizing: border-box;
`
export const InputLine = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`
export const InputGroup = styled.div`
  display: block;
`
export const InputLabel = styled.div`
  font-size: 14px;
  margin-top: 15px;

  &.error {
    color: red;
  }
`
export const InputHelperText = styled.div`
  color: #3A94FD;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
`
export const InputErrorText = styled.div`
  color: red;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
`
export const DragPanel = styled.div`
  background-color: #d4d4d4;
  flex: 1;
  text-align: center;
  cursor: pointer;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const ImageContainer = styled(Container)`
  &.empty {
    border: 1px solid #d9d9d9;
    padding: 10px;
  }
  &.error {
    border: 1px solid #ff4d4f;
  }
`
export const ErrorText = styled.p`
  color: #ff4d4f;
  margin-top: 0px;
`
