import React from 'react'
import { MdOutlineAddPhotoAlternate } from 'react-icons/md'
import { ChooseText, DropContainer, DropText, ImagePreview, PhotoContainer } from './styles'

interface SelectedImageInputContentProps {
  image?: string
}

const SelectedImageInputContent: React.FC<SelectedImageInputContentProps> = ({ image }) => {
  if (!image) {
    return (
      <DropContainer>
        <PhotoContainer>
          <MdOutlineAddPhotoAlternate size={20} color="#A6A6A6"/>
        </PhotoContainer>
        <DropText>Drop banner image here</DropText>
        <DropText>or</DropText>
        <ChooseText>Choose file</ChooseText>
      </DropContainer>
    )
  }

  return (
    <ImagePreview src={image} />
  )
}
export default SelectedImageInputContent
