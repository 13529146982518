import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import { bannersForLocation, type SetDefaultFn } from 'services/banners.public'
import type { BannerType } from 'utils/types'
import type { RootState } from './store'
import { SESSION_SFCC_SITE_DATE } from 'utils/urlParams'
import { dateFromSFCC } from 'hooks/useSiteDate'
import { isCurrentDateWithinRange } from 'utils/date'
import { v4 } from 'uuid'
import { getCookieSync, mapClientCookies } from 'utils/cookies'
import type { ParsedCookie } from 'contexts/cookies'
import { appConfig } from 'config'
import { getAjaxCategory, getBreadcrumbCategory, getDemandwareContextCategory, getMetaCategory, getTealiumCategory } from 'hooks/useCategory'

export interface Slot {
  id: string
  banners: Array<{ deleting?: boolean } & BannerType>
  loading: boolean
  dirty: boolean
  dirtyOrder?: boolean
}

export interface SlotState {
  slots: Slot[]
  loading: boolean
  remoteFn?: SetDefaultFn
  remoteFnStr?: string
}

const initialState: SlotState = {
  slots: [],
  loading: false
}

interface UpdateSlotBannersPayload {
  slotId: string
  banners: BannerType[]
}

const NAME = 'slots'
export const CLIENT_COOKIES = (): ParsedCookie[] => {
  const cookies = mapClientCookies(process.env.REACT_APP_CLIENT_COOKIES)

  return cookies.map((cookie) => {
    const { name, encoded = false, json = false } = cookie
    const cookieVal = getCookieSync(name)
    if (cookieVal) {
      const clientMappedValues = appConfig.cookiesValueMaps.get(name)
      let value = decodeURIComponent(cookieVal)
      if (encoded) value = atob(value)
      if (json) value = JSON.parse(value)
      return { name, value, map: clientMappedValues }
    }
    return { name, value: null, map: null }
  })
}

const categoryFunctions: any = {
  getAjaxCategory,
  getTealiumCategory,
  getBreadcrumbCategory,
  getDemandwareContextCategory,
  getMetaCategory
}
export const CURRENT_CATEGORY = (): string => {
  if (Object.keys(window?.BILDIT?.categoryValidations)?.length === 0) {
    appConfig.categoryChecks?.forEach((fnName) => {
      if (window.BILDIT && typeof categoryFunctions[fnName] === 'function') {
        window.BILDIT.categoryValidations[fnName] = categoryFunctions[fnName]
      }
    })
  }

  const VALID_SET = window.BILDIT?.categoryValidations
  if (!VALID_SET || VALID_SET.size === 0) return ''

  const ajaxCategory = VALID_SET.getAjaxCategory ? getAjaxCategory() : ''
  if (ajaxCategory) return ajaxCategory
  // if we have an ajax category update, it is the only correct option
  // tealium data may be updated dynamically, meta is 100% correct but possibly stale due to ajax updates
  // Use a ternary expression to simplify the logic
  const possibleMatches = new Set(Object.keys(VALID_SET).map((key) => VALID_SET[key]?.(location.href) ?? '').filter((x) => !!x && x.length > 0))
  return Array.from(possibleMatches)?.[0] ?? ''
}

const { actions, reducer } = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addSlot: (state, action: PayloadAction<{ id: string }>) => {
      if (!state.slots.some(slot => slot.id === action.payload.id)) {
        state.slots.push({
          id: action.payload.id,
          banners: [],
          loading: true,
          dirty: false,
          dirtyOrder: false
        })
      }
    },
    setSlotLoading: (state, action: PayloadAction<{ slotId: string, bannerId: string }>) => {
      const { slotId, bannerId } = action.payload
      const slotIndex = state.slots.findIndex((s) => s.id === slotId)
      if (slotIndex !== -1) {
        const slot = state.slots[slotIndex]
        state.slots.splice(slotIndex, 1, {
          ...state.slots[slotIndex],
          banners: slot.banners.map((banner) => {
            return banner.id === bannerId
              ? {
                  ...banner,
                  loading: true
                }
              : banner
          }),
          loading: true
        })
      }
    },
    organizeBannersIntoSlots: (state, action: PayloadAction<{ banners: BannerType[] }>) => {
      const { banners } = action.payload
      state.slots.forEach(slot => {
        const incomingSlotBanners = bannersForLocation(
          banners.filter(({ webSlots }) => webSlots.includes(slot.id)),
          location.href,
          CURRENT_CATEGORY(),
          CLIENT_COOKIES()
        )
        slot.banners = uniqBy([...slot.banners, ...incomingSlotBanners], 'id')
          .map(banner => ({
            ...banner,
            // Assign unique IDs to variants for internal matching
            variants: banner.variants.map((v) => ({ ...v, id: v4() })),
            bannerSortOrder: banner.bannerSortOrder ?? {}
          }))
          .sort((a, b) => {
            const orderA = a.bannerSortOrder?.[slot.id] ?? Infinity
            const orderB = b.bannerSortOrder?.[slot.id] ?? Infinity
            return orderA - orderB
          });
        slot.loading = false
        slot.dirty = slot.banners.some((banner) => !banner.id)
      })
    },
    addBannerToSlot: (state, action: PayloadAction<{ slotId: string, banner: BannerType }>) => {
      const { slotId, banner } = action.payload
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        slot.banners.push({
          ...banner,
          dirty: true
        })
        slot.dirty = true
        slot.loading = false
      }
    },
    updateBannerInSlot: (
      state,
      action: PayloadAction<{ slotId: string, updatedBanner: Partial<BannerType> }>
    ) => {
      const { slotId, updatedBanner } = action.payload
      const { id } = updatedBanner
      const queryParams = new URLSearchParams(window.location.search)
      const siteDate = queryParams.get(SESSION_SFCC_SITE_DATE)
      const selectedDate = siteDate ? dateFromSFCC(siteDate) : new Date()

      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        // this fixes bug where editing metadata on an unpublished banner duplicates it
        // bannerIndex passed is not accurate, we must filter our current slot banners by location + schedule
        // always include current banner id we're editing in the results
        // (12-21-24: bannerIndex as an API parameter is dead throughout codebase)
        const slotBanners = bannersForLocation([...slot.banners], location.href, CURRENT_CATEGORY(), CLIENT_COOKIES())
          .filter((banner) => banner.id === id || banner.schedules.some((schedule) => selectedDate ? isCurrentDateWithinRange(schedule, selectedDate) : true))
        // find original index to update, as the base data is unfiltered
        const origIndex = slot.banners.findIndex((val) => val.id === id)
        const bannerIndex = slotBanners.findIndex((val) => val.id === id)

        if (origIndex !== -1) {
          slot.banners.splice(origIndex, 1, {
            ...(bannerIndex !== -1
              ? slotBanners[bannerIndex]
              : slot.banners[origIndex]),
            ...updatedBanner,
            dirty: true
          })
          slot.dirty = true
        }

        slot.loading = false
      }
    },
    setBannerForSlotRemoval: (state, action: PayloadAction<{ slotId: string, bannerReference: { id?: string } }>) => {
      const { slotId, bannerReference } = action.payload
      const { id } = bannerReference
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        const banner = slot.banners.find((banner) => banner.id === id)
        if (banner) {
          banner.deleting = true
        }
      }
    },
    setBannerForDeletion: (state, action: PayloadAction<{ slotId: string, bannerReference: { id?: string } }>) => {
      const { slotId, bannerReference } = action.payload
      const { id } = bannerReference
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        const banner = slot.banners.find((banner) => banner.id === id)
        if (banner) {
          banner.deleting = true
        }
      }
    },
    deleteBannerFromSlot: (state, action: PayloadAction<{ slotId: string, bannerReference: { id?: string } }>) => {
      const { slotId, bannerReference } = action.payload
      const { id } = bannerReference
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        const index = slot.banners.findIndex((banner) => banner.id === id)
        if (index !== -1) {
          slot?.banners.splice(index, 1)
        }
      }
    },
    deleteBanner: (state, action: PayloadAction<{ slotId: string, bannerReference: { id?: string } }>) => {
      const { slotId, bannerReference } = action.payload
      const { id } = bannerReference
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        const index = slot.banners.findIndex((banner) => banner.id === id)
        if (index && index !== -1) {
          slot?.banners.splice(index, 1)
        }
      }
    },
    copyBannersToSlot: (state, action: PayloadAction<{ slotId: string, banners: BannerType[] }>) => {
      const { slotId, banners } = action.payload
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        slot.banners = banners
        slot.banners.forEach((banner) => { banner.dirty = true })
      }
    },
    addBannersToSlot: (state, action: PayloadAction<{ slotId: string, banners: BannerType[] }>) => {
      const { slotId, banners } = action.payload
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        banners.forEach(banner => slot.banners.push({
          ...banner,
          dirty: true
        }))
      }
    },
    resetSlotsDraftBanners: (state) => {
      state.slots.forEach(slot => {
        slot.banners = []
      })
    },
    reorderBannersInSlot: (state, action: PayloadAction<{ slotId: string, banners: BannerType[] }>) => {
      const { slotId, banners } = action.payload
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        slot.banners = banners.map((banner, index) => ({
          ...banner,
          bannerSortOrder: {
            ...banner.bannerSortOrder,
            [slotId]: index
          }
        }))
        slot.dirty = true
      }
    },
    setSlotDirtyOrder: (state, action: PayloadAction<{ slotId: string, dirty: boolean }>) => {
      const { slotId, dirty } = action.payload
      const slot = state.slots.find((slot) => slot.id === slotId)
      if (slot) {
        slot.dirtyOrder = dirty
      }
    },
    updateSlotBanners: (state, action: PayloadAction<UpdateSlotBannersPayload>) => {
      const { slotId, banners } = action.payload
      const slot = state.slots.find((s) => s.id === slotId)
      if (slot) {
        slot.banners = banners
        slot.dirtyOrder = true
      }
    },
    markBannerStale: (state, action: PayloadAction<{ id: string }>) => {
      const { id: bannerId } = action.payload
      state.slots.forEach((slot) => {
        slot.banners.forEach((banner) => {
          if (bannerId === banner.id) {
            banner.stale = true
          }
        })
      })
    },
    setClientDefaultFn (state, action: PayloadAction<{ fn: string }>) {
      state.remoteFnStr = action.payload.fn
    }
  }
})

export const {
  addBannersToSlot,
  addBannerToSlot,
  addSlot,
  copyBannersToSlot,
  deleteBanner,
  deleteBannerFromSlot,
  markBannerStale,
  organizeBannersIntoSlots,
  reorderBannersInSlot,
  resetSlotsDraftBanners,
  setBannerForDeletion,
  setBannerForSlotRemoval,
  setClientDefaultFn,
  setSlotDirtyOrder,
  setSlotLoading,
  updateBannerInSlot,
  updateSlotBanners
} = actions

export default reducer
export const slotsListStateSelector = (state: RootState) => state.slots
export const slotsListSelector = (state: RootState) => state.slots.slots
