import styled from 'styled-components'
import { Button, Row } from 'antd'

export const ScheduleItemContainer = styled.div<{ index?: number }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ index }) => index === 0 ? '27px' : 'initial'}
`

export const ScheduleContainer = styled.div`
  h6 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }

  .delete-button {
    align-self: end;
  }
`
export const ScheduleHeader = styled(Row)`
  margin-bottom: 12px;
`

export const ButtonCode = styled(Button)`
  margin-top: 12px;
`
export const SelectFromListContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const TemplatePanel = styled.div`
  margin-bottom: 20px;
`

export const TemplateFieldsContainer = styled.div`
  padding: 20px;
  border: 1px solid rgb(209 213 219);
  background: rgb(249 250 251);
`
