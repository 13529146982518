import { addDays } from 'date-fns/addDays';
import { parse } from 'date-fns/parse';
import { subDays } from 'date-fns/subDays';
import { startOfMonth } from 'date-fns/startOfMonth';
import { endOfMonth } from 'date-fns/endOfMonth';
import { startOfWeek } from 'date-fns/startOfWeek';
import { endOfWeek } from 'date-fns/endOfWeek';
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval';
import type { ScheduleType } from './types';
import { appConfig } from 'config';
import { TZDate } from '@date-fns/tz'
import { parseISO } from 'date-fns/parseISO';
import { endOfDay, startOfDay } from 'date-fns';

const LOCALE: Intl.LocalesArgument = 'en-US'
export const DEFAULT_TIME_ZONE = appConfig.ianaTimezone

export const ISO8601RE = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/

export const getCurrentDate = (timeZone: string, selectedDate?: Date) => {
  const userDateNow = selectedDate ? new Date(selectedDate) : new Date();
  return new Date(userDateNow.toLocaleString(LOCALE, { timeZone }));
}

export const getCurrentDateWithinTZ = (timeZone: string = DEFAULT_TIME_ZONE): TZDate => {
  const now = new Date().setMilliseconds(0)
  return new TZDate(now, timeZone)
}

export const getUserCurrentDate = (selectedDate?: Date): Date => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return getCurrentDate(userTimeZone, selectedDate);
};

// Function check schedule time with considering user Time Zone
export const isCurrentDateWithinRange = (
  schedule: ScheduleType,
  selectedDate?: Date
): boolean => {
  const startDate = new Date(schedule.startDate).setMilliseconds(0)
  const endDate = new Date(schedule.endDate).setMilliseconds(999)
  const selDate = selectedDate
    ? new Date(selectedDate).setMilliseconds(0)
    : new Date().setMilliseconds(0)
  const currentDateInUserTZ = new Date(selDate).setMilliseconds(0)

  return currentDateInUserTZ >= startDate && currentDateInUserTZ <= endDate
}

export const parseDateString = (date?: string) => {
  if (!date) {
    return getCurrentDateWithinTZ(appConfig.ianaTimezone);
  }

  return parse(date, 'yyyyMMddHHmm', new Date())
}

export const subtractDaysWithinTZ = (countDays: number = 1) => {
  return subDays(getCurrentDateWithinTZ(), countDays)
}

export const getDefaultSchedules = (schedule?: ScheduleType) => {
  if (schedule) {
    const unixStart = typeof schedule.startDate === 'string' ? parseISO(schedule.startDate).valueOf() : +schedule.startDate
    const unixEnd = typeof schedule.endDate === 'string' ? parseISO(schedule.endDate).valueOf() : +schedule.endDate
    return {
      startDate: new TZDate(unixStart, DEFAULT_TIME_ZONE).toISOString(),
      endDate: new TZDate(unixEnd, DEFAULT_TIME_ZONE).toISOString()
    }
  }

  return {
    startDate: getCurrentDateWithinTZ().toISOString(),
    endDate: addDays(getCurrentDateWithinTZ(), 1).toISOString()
  }
}

export const getTodaysSchedule = (timeZone: string = DEFAULT_TIME_ZONE): ScheduleType[] => {
  const now = Date.now()
  return [{
    startDate: startOfDay(new TZDate(now, timeZone)).valueOf(),
    endDate: endOfDay(new TZDate(now, timeZone)).valueOf(),
    slot: 1
  }]
}

export const getDaysForCalendar = (currentDate: TZDate): Date[] => {
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);

  const calendarStart = startOfWeek(monthStart);
  const calendarEnd = endOfWeek(monthEnd);

  return eachDayOfInterval({ start: calendarStart, end: calendarEnd });
}

export const convertSchedules = (schedules: ScheduleType[]) =>
  schedules.map((schedule: ScheduleType) => ({
    ...schedule,
    startDate: typeof schedule.startDate !== 'number' ? new TZDate(schedule.startDate).valueOf() : schedule.startDate,
    endDate: typeof schedule.endDate !== 'number' ? new TZDate(schedule.endDate).valueOf() : schedule.endDate
  }))
