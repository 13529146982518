import React, { useMemo, useState } from 'react';
import CodeLibraryList from 'components/CodeLibraryList/CodeLibraryList';
import AdaptiveModal from 'components/AdaptiveModal';
import { type CodeSnippetMinimized, type CodeSnippet } from 'services/codeLibrary.d';
import { useGetCodeSnippetsQuery } from 'services/codeLibrary';
import { Button, Tabs } from 'antd';
import { SearchContainer, CancelButtonContainer, FooterContainer, SortContainer } from './styles';
import SearchInputWithSuggest from 'components/SearchInputWithSuggest';
import SelectPicker from 'components/SelectPicker';
import { CODE_LIBRARY_SORT_OPTIONS, CodeLibrarySortOption, minimizeDataForSearch, sortingCodeLibraryData } from 'utils/codeLibraryData';

const FIRST_TAB_KEY = '1';
const SECOND_TAB_KEY = '2';

const LIBRARY_KEY = 'library';
const CUSTOM_LIBRARY_KEY = 'customLibrary';

interface CodeLibraryModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (snippet: CodeSnippet) => void
}

const CodeLibraryModal = ({
  open,
  onClose,
  onConfirm
}: CodeLibraryModalProps) => {
  const [selectedSnippet, setSelectedSnippet] = useState<CodeSnippet | null>(
    null
  );
  const [activeTabKey, setActiveTabKey] = useState(FIRST_TAB_KEY);
  const [sortBy, setSortBy] = useState<CodeLibrarySortOption>(CodeLibrarySortOption.AZ);
  const { data } = useGetCodeSnippetsQuery({});

  const handleSelect = (snippet: CodeSnippet) => {
    setSelectedSnippet(snippet);
  };

  const handleConfirm = (_event: React.MouseEvent) => {
    if (selectedSnippet) {
      onConfirm(selectedSnippet)
    }
  };

  const tabData = useMemo(() => {
    const libraryTabData: CodeSnippet[] = []
    const customLibTabData: CodeSnippet[] = []

    sortingCodeLibraryData(data, sortBy).forEach(item => item.isCustom
      ? customLibTabData.push(item)
      : libraryTabData.push(item)
    )

    return {
      libraryTabData,
      customLibTabData
    }
  }, [data, sortBy])

  const onChangeTab = (activeTab: string) => {
    setActiveTabKey(activeTab);
  }

  const itemsForSearch = useMemo(() => {
    switch (activeTabKey) {
      case FIRST_TAB_KEY: {
        return minimizeDataForSearch(tabData.libraryTabData, FIRST_TAB_KEY)
      }
      case SECOND_TAB_KEY: {
        return minimizeDataForSearch(tabData.customLibTabData, SECOND_TAB_KEY)
      }
      default: {
        return []
      }
    }
  }, [activeTabKey, tabData]);

  const getCodeSnipperDataByTabKey = (tabKey?: string) => {
    switch (tabKey) {
      case FIRST_TAB_KEY: {
        return tabData.libraryTabData;
      }
      case SECOND_TAB_KEY: {
        return tabData.customLibTabData;
      }
      default: {
        return [];
      }
    }
  };

  return (
    <AdaptiveModal
      openInNewWindow
      centered
      open={open}
      width={815}
      height={700}
      onClose={onClose}
      footer={(
        <FooterContainer>
          <CancelButtonContainer>
            <Button key="cancel" type="default" onClick={onClose}>
              CANCEL
            </Button>
          </CancelButtonContainer>
          <Button key="confirm" type="primary" onClick={handleConfirm} disabled={!selectedSnippet}>
            INSERT COMPONENT
          </Button>
        </FooterContainer>
      )}
    >
      <Tabs
        type="card"
        onChange={onChangeTab}
        activeKey={activeTabKey}
        items={[
          {
            key: FIRST_TAB_KEY,
            label: 'Library',
            children: (
              <CodeLibraryList
                selected={selectedSnippet}
                items={tabData.libraryTabData}
                onSelect={handleSelect}
                customKey={LIBRARY_KEY}
              />
            )
          },
          {
            key: SECOND_TAB_KEY,
            label: 'Custom Library',
            children: (
              <CodeLibraryList
                selected={selectedSnippet}
                items={tabData.customLibTabData}
                onSelect={handleSelect}
                customKey={CUSTOM_LIBRARY_KEY}
              />
            )
          }
        ]}
      />

      <SearchContainer>
        <SearchInputWithSuggest<CodeSnippetMinimized>
          suggestedItems={itemsForSearch}
          onSelectSuggestItem={(item) => {
            const snippet = getCodeSnipperDataByTabKey(item.tabKey).find(snippet => snippet.id === item.id);
            if (!snippet) {
              return;
            }
            if (!!item?.tabKey && activeTabKey !== item.tabKey) {
              setActiveTabKey(item.tabKey);
            }
            setSelectedSnippet(snippet);
          }}
        />

        <SortContainer>
          <SelectPicker
            options={CODE_LIBRARY_SORT_OPTIONS}
            onChange={(item) => {
              if (!item) {
                return;
              }
              setSortBy(item as CodeLibrarySortOption);
            }}
            selectedValue={sortBy}
          />
        </SortContainer>

      </SearchContainer>
    </AdaptiveModal>
  );
};

export default CodeLibraryModal;
