import { format } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import { type BannerType } from '../../utils/types'

interface Props {
  banner: BannerType
}

const ScheduleCell: React.FC<Props> = ({ banner }) => {
  const slot = banner.schedules[0].slot

  const hasMore = banner.schedules.length > 1
  return (
    <ScheduleCellWrapper>
      {banner.schedules && banner.schedules.length > 0 && (
        <div className="schedules-row">
          <div className="schedule-status">
            <div className="schedule-period">{`${format(
              typeof banner.schedules[0].startDate === 'number'
                ? banner.schedules[0].startDate
                : new Date(banner.schedules[0].startDate),
              'MMM dd'
            )} - ${format(typeof banner.schedules[0].endDate === 'number'
              ? banner.schedules[0].endDate
              : new Date(banner.schedules[0].endDate), 'MMM dd')}`}</div>
            {!!slot && (
              <>
                <div className="center-dot" />
                <div className="schedule-cnt">
                  Slot {slot}
                </div>
              </>
            )}
          </div>
          <div className="more-schedules">
            {hasMore
              ? (<>Scheduled<span className="more-count">ON&quot; </span></>)
              : (<>Scheduled</>)}
          </div>
        </div>
      )}
    </ScheduleCellWrapper>
  )
}

export default ScheduleCell
const ScheduleCellWrapper = styled.div`
  .schedule-period {
    font-size: 14px;
  }
  .schedule-status {
    align-items: center;
    display: flex;
  }
  .schedules-row {
    display: flex;
    flex-direction: column;
  }
  .center-dot {
    background-color: #0c0c0c;
    border-radius: 2px;
    height: 4px;
    margin-left: 6px;
    width: 4px;
  }
  .schedule-cnt {
    font-size: 12px;
    margin-left: 6px;
  }
  .more-schedules {
    color: grey;
    font-size: 12px;
  }
  .more-count {
    color: #ff83a7;
    font-size: 12px;
    margin-left: 5px;
  }
`
