import React, { useLayoutEffect, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'

import ShowBanner from 'components/ShowBanner'
import type { BannerTypeRender, BannerType, Variant } from 'utils/types'
import { BILDIT_DATA_ATTR, CURRENT_URL } from 'utils/urlParams'
import { variantsForLocation } from 'services/banners.public'

interface SlotPortalProps {
  banners: BannerType[] | BannerTypeRender[]
  slotId: string
  loading: boolean
}

const SlotBannersPortal: React.FC<SlotPortalProps> = ({
  banners,
  slotId,
  loading
}) => {
  const slotElement = document.querySelector(`[${BILDIT_DATA_ATTR}=${slotId}]`)
  const [isSlotCleared, setIsSlotCleared] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (slotElement) {
      if (!isSlotCleared) {
        slotElement.innerHTML = ''
        setIsSlotCleared(true)
      }
    }
  }, [isSlotCleared, slotId, slotElement])

  const renderedBanners = useMemo(() => {
    return banners.flatMap((banner) => {
      const variants = banner?.variants
        ? variantsForLocation(banner.variants, CURRENT_URL)
        : []

      const renderShowBanner = (variant?: Variant) => (
        <ShowBanner
          key={`${slotId}-${banner.id}${variant ? `-${variant.id}` : ''}`}
          banner={
            variant
              ? {
                  ...banner,
                  url: variant?.url ?? banner?.url,
                  variants: [variant]
                }
              : banner
          }
        />
      )

      return variants.length > 0
        ? variants.map((element) => renderShowBanner(element))
        : renderShowBanner()
    })
  }, [banners, slotId])

  if (!isSlotCleared || !slotElement || loading) {
    return null
  }

  return createPortal(renderedBanners, slotElement)
}

export default React.memo(SlotBannersPortal)
