import { appConfig } from 'config'

const breakpoints: Record<string, { min: number, max: number }> = JSON.parse(appConfig.breakpoints, (key, value) => {
  if (value === null) {
    return key === 'max' ? Infinity : key === 'min' ? -Infinity : value
  }
  return value
})

export default breakpoints
