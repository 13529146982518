import React, { useEffect, useMemo, useState } from 'react'
import { replaceTemplateData } from 'utils/metadata'
import { bannersListStateSelector, transpileCode } from 'redux/bannersSlice'
import { useDispatch, useSelector } from 'react-redux'
import Remote from 'remote/Remote'
import type { BannerCode, CodeType, VariantMetadata } from 'utils/types'
import styled from 'styled-components'
import { useDebounceEffect } from 'ahooks'
import { validateMetadata } from 'components/BannerFormModal/BannerFormModal'

interface RemotePreviewProps {
  bannerId: string
  code: string | BannerCode
  codeType?: CodeType
  metadata: VariantMetadata[]
  onCompiled?: (code: string, metadata: VariantMetadata[]) => void
}

const RemotePreview: React.FC<RemotePreviewProps> = ({
  bannerId,
  code,
  codeType,
  metadata,
  onCompiled
}) => {
  const actualCode = useMemo(() => typeof code === 'string' ? code : code.raw ?? '', [code])
  const needsCompilation = useMemo(() => !['json', 'html'].includes(codeType ?? ''), [codeType])
  const [loading, setLoading] = useState(needsCompilation)
  const [renderedCode, setRenderedCode] = useState<string | null>(needsCompilation ? null : actualCode)
  const { transpileError: error } = useSelector(bannersListStateSelector)
  const replacedCode = replaceTemplateData(actualCode, metadata)
  const dispatch = useDispatch()

  useDebounceEffect(
    () => {
      if (code && codeType) {
        if (!validateMetadata(metadata)) return

        setLoading(true)
        dispatch(
          transpileCode({
            bannerId,
            code: replacedCode,
            codeType: codeType ?? 'jsx',
            onSuccess: (compiledCode) => {
              setRenderedCode(compiledCode)
              onCompiled?.(compiledCode, metadata)
              setLoading(false)
            },
            onFailure: (err) => {
              console.error(`Transpilation failed for banner ${bannerId}:`, err)
              setRenderedCode(null)
              setLoading(false)
            }
          })
        )
      }
    },
    [code, codeType, dispatch, metadata, replacedCode],
    { wait: 2000 }
  )

  // Reset state if `code` or `metadata` changes unexpectedly
  useEffect(() => {
    setRenderedCode(needsCompilation ? null : actualCode)
    setLoading(needsCompilation)
  }, [actualCode, metadata, needsCompilation])

  return (
    <NonInteractiveContainer>
      {error?.message
        ? <Error>{error.code} &mdash; {error.message}</Error>
        : null}
      {loading
        ? <Message>Loading preview&hellip;</Message>
        : renderedCode
          ? <NonInteractive code={renderedCode} codeType={codeType} />
          : <Message>No preview available.</Message>
      }
    </NonInteractiveContainer>
  )
}

export default RemotePreview

const Error = styled.pre`
    color: red;
    font-size: smaller;
`

const Message = styled.span`
  color: rgb(209 213 219);
`

const NonInteractiveContainer = styled.div`
    align-items: center;
    background-color: rgb(249 250 251);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    border: dashed 1px rgb(209 213 219);
    margin: 10px 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    pointer-events: none !important;
    position: relative;
    min-height: 60px;
`
const NonInteractive = styled(Remote)`
    pointer-events: none !important;
`
