import React from 'react'
import styled from 'styled-components'
import { AiFillPlusCircle } from 'react-icons/ai'

interface Props {
  onClick?: () => void
}
const CreateBannerButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconContainer>
      <AiFillPlusCircle
        style={{ cursor: 'pointer' }}
        size={30}
        color="#0559fd"
        onClick={onClick}
      />
    </IconContainer>
  )
}

export default CreateBannerButton

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 15px));
  border-radius: 9999px;
`
