import {
  fetchBanners,
  setBannersUpdating,
  updateBanners,
  transpileCode,
  setTranspiledCode,
  setTranspileError,
  fetchDefaultTemplate,
  setDefaultTemplate,
  fetchBaseComponent,
  setLoadingBanners,
  setBanners
} from './bannersSlice'

import api, { type GetBannersParams } from '../utils/api'
import { startAppListening } from './listenerMiddleware'
import type { BannerType, CodeType, ImageFile, ResponsiveImage } from '../utils/types'
import {
  type BannerSubmitData,
  bannerToBannerSubmitData,
  isEphemeral,
  remoteSchemeV4ToBanner
} from '../utils/adapters'
import { AxiosError } from 'axios'
import {
  deleteBanner,
  deleteBannerFromSlot,
  organizeBannersIntoSlots,
  resetSlotsDraftBanners,
  setBannerForDeletion,
  setBannerForSlotRemoval,
  reorderBannersInSlot,
  setClientDefaultFn
} from './slotsSlice'
import { appConfig, firebaseConfig } from '../config'
import fb from '../firebaseConfig'
import toast from 'react-hot-toast'
import { parseISO } from 'date-fns'
import { b64ToBlob, base64Pattern, getImageDims } from '../utils/b64'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { variantsIndex } from 'services/banners.public'
import { CURRENT_URL } from 'utils/urlParams'
import { replaceTemplateData } from 'utils/metadata'
// import { CURRENT_CATEGORY } from 'redux/slotsSlice'

const uploadImage = async (uploadingData: ImageFile, dims?: { height?: number, width?: number }): Promise<string | undefined> => {
  let uploadedFile: string | undefined
  const metadata = {
    contentType: uploadingData.type,
    customMetadata: {
      appId: appConfig.webAppId
    }
  }
  let name = uploadingData?.name ?? ''
  if (dims?.width && dims?.height) {
    name += `&w=${dims.width}&h=${dims.height}`
  }
  const path = [appConfig.webAppId, name].join('/')
  await new Promise<string>((resolve, reject) => {
    const storageRef = ref(fb.storage)
    uploadedFile = undefined
    uploadBytes(ref(storageRef, path), uploadingData, metadata).then(async (snapshot) => {
      uploadedFile = await getDownloadURL(snapshot.ref)
      resolve(uploadedFile)
    }).catch((error) => {
      reject(error)
      console.error('error on upload:', error)
    })
  })
  return uploadedFile
}

const decodeImage = async (data: string): Promise<{ height: number, width: number } | null> => {
  return await new Promise((resolve, reject) => {
    try {
      const img = new Image()
      img.src = data
      img.addEventListener('load', () => {
        resolve({
          height: img.naturalHeight,
          width: img.naturalWidth
        })
      })
      img.addEventListener('error', (error) => {
        reject(error)
      })
    } catch (ex) {
      reject(ex)
    }
  })
}

startAppListening({
  actionCreator: updateBanners,
  effect: async (_action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    const { slots } = listenerApi.getState().slots

    try {
      for (const slot of slots) {
        for (const banner of slot.banners) {
          // do not re-save banners we haven't edited
          if (banner.dirty) {
            const [origVariant] = banner.variants
            const { image, responsiveImage } = origVariant
            const bannerData: BannerSubmitData = bannerToBannerSubmitData(banner)

            let uploadedFile
            let height = -1
            let width = -1
            if (image) {
              if (base64Pattern.test(image)) {
                const file = b64ToBlob(image, banner.name)
                try {
                  const dims = await decodeImage(image)
                  if (dims && dims.height > 0 && dims.width > 0) {
                    height = dims.height
                    width = dims.width
                    uploadedFile = await uploadImage(file, dims)
                  } else uploadedFile = await uploadImage(file)
                } catch (ex: any) {
                  console.warn(`Unable to decode image: ${ex.message}`)
                  uploadedFile = await uploadImage(file)
                }
              } else {
                uploadedFile = image
                const url = new URL(image)
                // Check if this is our Firebase storage
                if (url.hostname !== 'firebasestorage.googleapis.com' &&
                    !url.pathname.startsWith('/v0/b/') &&
                    !url.pathname.includes('/o/') &&
                    !url.pathname.includes(firebaseConfig.storageBucket)) {
                // if isn't stored in our fb storage, fetch dims
                  try {
                    const dims = await getImageDims(image, banner.name)
                    height = +dims.height
                    width = +dims.width
                  } catch (ex) {
                    console.error(ex)
                  }
                }
              }
            } else if (responsiveImage && Object.keys(responsiveImage).length > 0) {
              // convert form objects into array of objects
              const tmpImages: ResponsiveImage[] = []
              responsiveImage.forEach((img) => {
                tmpImages.push({
                  height: -1,
                  screen: img.screen,
                  url: img.url,
                  width: -1
                })
              })

              // upload + get dims for responsive
              const clonedData: ResponsiveImage[] = []
              for await (const img of tmpImages) {
                if (img.url) {
                  const { height, width } = await getImageDims(img.url, banner.name)
                  const resImg = {
                    height: height ?? -1,
                    screen: img.screen,
                    width: width ?? -1
                  }
                  if (base64Pattern.test(img.url ?? '')) {
                    const file = b64ToBlob(img.url, banner.name)
                    const imageUrl = await uploadImage(file)
                    if (imageUrl) {
                      clonedData.push({
                        ...resImg,
                        url: imageUrl
                      })
                    }
                  } else { // URL
                    clonedData.push({
                      ...resImg,
                      url: img.url.trim()
                    })
                  }
                }
              }
              // set responsive, clear image + code
              const variantIndex = variantsIndex(bannerData.variants, location.href)
              if (variantIndex) {
                bannerData.variants.splice(variantIndex, 1, {
                  ...origVariant,
                  locations: origVariant.locations ?? [],
                  responsiveImage: clonedData,
                  code: undefined,
                  image: uploadedFile
                })
                delete bannerData.variants[variantIndex].image
                delete bannerData.variants[variantIndex].code
              }
            }

            bannerData.schedules = bannerData.schedules.map((schedule) => ({
              ...schedule,
              startDate: typeof schedule.startDate === 'string' ? parseISO(schedule.startDate).valueOf() : +schedule.startDate,
              endDate: typeof schedule.endDate === 'string' ? parseISO(schedule.endDate).valueOf() : +schedule.endDate
            }))
            // only set by single image upload
            if (uploadedFile) {
              const variantIndex = variantsIndex(bannerData.variants, location.href)
              if (variantIndex !== -1) {
                bannerData.variants.splice(variantIndex, 1, {
                  ...origVariant,
                  locations: Array.isArray(origVariant.locations) && !origVariant.locations.includes(CURRENT_URL)
                    ? [...origVariant.locations, CURRENT_URL]
                    : origVariant.locations ?? [],
                  code: undefined,
                  image: uploadedFile
                })

                if (height >= 0 && width >= 0) {
                  bannerData.variants[variantIndex].preview = {
                    h: height,
                    w: width
                  }
                }
              }
            }
            bannerData.variants.forEach((v) => delete v.id)

            await (bannerData.id ? api.updateBanner(bannerData) : api.createBanner(bannerData));
          }
        }
      }
      void toast.success('Banner successfully saved!')
      listenerApi.dispatch(resetSlotsDraftBanners())
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 403) {
        //
      }
      void toast.error('Something went wrong')
    } finally {
      listenerApi.dispatch(fetchBanners())
      listenerApi.dispatch(setBannersUpdating({ updating: false }))
    }
  }
})

startAppListening({
  actionCreator: setBannerForSlotRemoval,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    const { bannerReference, slotId } = action.payload
    const { id } = bannerReference
    try {
      if (id && !isEphemeral(bannerReference as BannerType)) await api.deleteSlotFromBanner(id, slotId)
      void toast.success('Banner removed from slot')
      listenerApi.dispatch(deleteBannerFromSlot({ slotId, bannerReference }))
    } catch (e) {
      console.error(e)
      void toast.error(`Failed to remove banner ${id} from slot ${slotId}`)
    }
  }
})

startAppListening({
  actionCreator: setBannerForDeletion,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    const { bannerReference, slotId } = action.payload
    const { id } = bannerReference
    try {
      if (id) await api.deleteBanner(id)
      void toast.success('Banner removed')
      listenerApi.dispatch(deleteBanner({ slotId, bannerReference }))
    } catch (e) {
      console.error(e)
      void toast.error(`Failed to delete banner ${id}`)
    }
  }
})

startAppListening({
  actionCreator: fetchDefaultTemplate,
  effect: async (_, listenerApi) => {
    listenerApi.cancelActiveListeners()
    try {
      const res = await api.getDefaultTemplate()
      const codeType = res.result?.codeType as CodeType
      if (!['json', 'html'].includes(codeType)) {
        const { compiled, raw, metadata } = res.result.code
        listenerApi.dispatch(setDefaultTemplate({
          template: {
            code: compiled,
            codeType,
            metadata,
            raw,
            name: res.result.name
          }
        }))
      }
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 403) {
        //
      }
      console.error(e)
    }
  }
})

startAppListening({
  actionCreator: fetchBaseComponent,
  effect: async (_, listenerApi) => {
    listenerApi.cancelActiveListeners()
    try {
      const res = await api.getRemoteCodelib()
      listenerApi.dispatch(setClientDefaultFn({ fn: res.result }))
    } catch (e) {
      console.error(e)
    }
  }
})

startAppListening({
  actionCreator: fetchBanners,
  effect: async (_action, listenerApi) => {
    listenerApi.cancelActiveListeners()
    listenerApi.dispatch(setLoadingBanners({ loading: true }))

    try {
      const params: GetBannersParams = {
        archived: false
        // TODO: BWC-1197
        // temporarily disable these until CMS updates are deployed
        // to prevent page being 'wiped' on save of category-only banners (all SFCC content)
        // not yet reported by belk qa

        // locations: encodeURIComponent(location.pathname),
        // categories: encodeURIComponent(CURRENT_CATEGORY())
      }

      const bannersResponse = await api.getBanners(params)
      const banners = bannersResponse.result.data?.map(
        (banner) => remoteSchemeV4ToBanner(banner)
      )

      listenerApi.dispatch(setBanners({ banners }))
      listenerApi.dispatch(organizeBannersIntoSlots({ banners }))
    } catch (e) {
      console.error(e)
    } finally {
      listenerApi.dispatch(setLoadingBanners({ loading: false }))
    }
  }
})

startAppListening({
  actionCreator: reorderBannersInSlot,
  effect: async (action, listenerApi) => {
    const { slotId, banners } = action.payload
    try {
      const updatedBanners = banners
        .filter((banner) => banner.id)
        .map((banner, index) => ({
          id: banner.id,
          bannerSortOrder: {
            ...banner.bannerSortOrder,
            [slotId]: index
          }
        }))

      if (updatedBanners.length > 0) {
        await api.updateBulkBanners({ banners: updatedBanners })
        listenerApi.dispatch(fetchBanners())
      }
    } catch (error) {
      console.error('Failed to update banners on server:', error)
      void toast.error('Something went wrong')
    } finally {
      listenerApi.dispatch(setBannersUpdating({ updating: false }))
    }
  }
})

startAppListening({
  actionCreator: transpileCode,
  effect: async (action, listenerApi) => {
    const { code, codeType, onSuccess, onFailure } = action.payload
    let outputCode
    try {
      const response = ['ts', 'tsx', 'js', 'jsx'].includes(codeType)
        ? await api.remoteTranspileCode(code, codeType)
        : {
            result: {
              data: replaceTemplateData(code, [])
            }
          }
      outputCode = response.result.data
      listenerApi.dispatch(setTranspiledCode({ code: outputCode }))
      onSuccess?.(outputCode)
    } catch (error: any) {
      void toast.error(`Failed to compile banner`)
      listenerApi.dispatch(setTranspileError({
        code: error?.code,
        message: error?.message,
        status: error?.status
      }))
      onFailure?.(error)
    } finally {
      listenerApi.dispatch(setBannersUpdating({ updating: false }))
    }
  }
})
