import React, { useLayoutEffect, useMemo, useState, useCallback } from 'react'
import SlotBannersPortal from '../SlotBannersPortal'
import { useShallow } from 'zustand/react/shallow'
import { bannersForLocation, useBannersStore } from 'services/banners.public'
import { BILDIT_DATA_ATTR } from 'utils/urlParams'
import type { BannerTypeRender } from 'utils/types'
import { isCurrentDateWithinRange } from 'utils/date'
import { useMutationObserver } from 'ahooks'
import { appConfig } from 'config'
import { type ParsedCookie, useClientCookies } from 'contexts/cookies'
import { type Slot } from '../../redux/slotsSlice';
import useSiteDate from 'hooks/useSiteDate'
import { useAjaxCategory } from 'hooks/useCategory'
import { useProxyLocation } from 'contexts/location'
import { debounce } from 'utils/debounce'

interface Props {
  isLoading?: boolean
  banners?: BannerTypeRender[]
  slots?: Slot[]
}

const filteredBanners = (banners: BannerTypeRender[], url: string, category?: string, cookies?: ParsedCookie[], previewDate?: Date) => {
  const filterDate = previewDate ?? new Date()
  return bannersForLocation(banners, url, category, cookies)
    .filter((banner) =>
      banner.schedules.some((schedule) =>
        isCurrentDateWithinRange(schedule, filterDate)
      )
    )
}
const ATTR_SELECTOR = `[${BILDIT_DATA_ATTR}]`

const PublicSlotListing: React.FC<Props> = ({ isLoading = false, banners: initialBanners = [], slots: providedSlots }) => {
  const [
    slots,
    reset,
    addSlot,
    organizeBannersIntoSlots
  ] = useBannersStore(useShallow((state) => [state.slots, state.reset, state.addSlot, state.organizeBannersIntoSlots]))
  const [hasInvalidation, setInvalidation] = useState(false)
  const { cookies, loading: cookiesLoading } = useClientCookies()
  const isRenderBlocked = useMemo(() => cookiesLoading || isLoading || hasInvalidation, [isLoading, cookiesLoading, hasInvalidation])
  const { url } = useProxyLocation()
  const { href } = window.location
  const providedDate = useSiteDate()
  const currentCategory = useAjaxCategory(url)
  const memoizedFilteredBanners = useMemo(() => {
    return filteredBanners(initialBanners, href, currentCategory, cookies, providedDate)
  }, [initialBanners, href, currentCategory, cookies, providedDate])

  useLayoutEffect(() => {
    if (isRenderBlocked || providedSlots) return

    const slotElements = window.BILDIT?.pageSlots ?? document.querySelectorAll(ATTR_SELECTOR)
    slotElements.forEach((node) => { addSlot(node.getAttribute(BILDIT_DATA_ATTR)) })
    reset()
    organizeBannersIntoSlots(memoizedFilteredBanners)
  }, [addSlot, isLoading, reset, organizeBannersIntoSlots, isRenderBlocked, providedSlots, memoizedFilteredBanners])

  const observerTarget = useMemo(() => document.getElementById(!currentCategory ? '' : appConfig.observerId), [currentCategory])
  const debouncedSetInvalidation = useCallback(() => {
    debounce(() => { setInvalidation(true) }, 100)()
  }, [])

  const mutationCallback = useCallback((mutations: MutationRecord[]) => {
    let invalidFlag = false

    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((n) => {
        const nodes: Element = n as Element
        if (nodes.nodeType === Node.ELEMENT_NODE) {
          nodes.querySelectorAll(ATTR_SELECTOR).forEach((node) => {
            addSlot(node.getAttribute(BILDIT_DATA_ATTR))
            invalidFlag = true
          })
        }
      })
    })

    if (invalidFlag) debouncedSetInvalidation()
  }, [addSlot, debouncedSetInvalidation])

  useMutationObserver(mutationCallback, observerTarget, { subtree: true, childList: true })

  useLayoutEffect(() => {
    if (hasInvalidation) {
      reset()
      organizeBannersIntoSlots(memoizedFilteredBanners)
      setInvalidation(false)
    }
  }, [hasInvalidation, reset, organizeBannersIntoSlots, memoizedFilteredBanners, initialBanners, href, currentCategory, cookies, providedDate])

  const activeSlots = useMemo(() => {
    const data = providedSlots ?? slots
    return data.map(({ banners, id, loading }) => {
      return (
        <SlotBannersPortal
          key={id}
          banners={banners}
          slotId={id}
          loading={loading}
        />
      )
    })
  }, [providedSlots, slots])

  if (isRenderBlocked) return null

  return (
    <div id="BILDIT-portal">
      {activeSlots}
    </div>
  )
}

export default React.memo(PublicSlotListing)
