import React, { useState } from 'react'
import styled from 'styled-components'
import notAvailableImage from '../../assets/notavailable.webp'
import { type BannerType, type ImageFile, type Variant } from '../../utils/types'

interface Props {
  banner: BannerType
}

const NameCell: React.FC<Props> = ({ banner }) => {
  const [hasImageError, setHasImageError] = useState(false)
  let mainVariant: Variant | undefined
  if (banner.variants && banner.variants.length > 0) {
    mainVariant = banner.variants[0]
  }

  // const tooltipString = (str: string) => {
  //   const string = str.split(':')
  //   if (string[0] === 'data') {
  //     return ''
  //   }
  //   return str
  // }

  let imageUrl: string | ImageFile & { preview?: string } = notAvailableImage
  if (mainVariant?.image) {
    imageUrl = mainVariant.image
  } else if (mainVariant?.codePreview) {
    imageUrl = mainVariant.codePreview
  }

  return (
    <NameCellWrapper>
      <img
        className="avatar"
        onError={() => {
          setHasImageError(true)
        }}
        alt=""
        src={hasImageError ? '' : imageUrl}
      />
      {banner && (
        <div className="description">
          <div className="name">{banner.name}</div>
        </div>
      )}
    </NameCellWrapper>
  )
}

export default NameCell

const NameCellWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;

  .description {
    margin-left: 1rem;
  }

  .avatar {
    width: 100px;
    height: 70px;
    margin-right: 10px;
    border: 1px solid #ddd;
    object-fit: contain;
  }

  .name {
    font-size: 1rem;
    max-width: 300px;
    font-weight: 500;
  }

  .name > a {
    color: #000000;
    font-weight: 500;
  }

  .details {
    color: #9f9f9f;
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.35rem;

    span {
      padding: 0 0.25rem;

      &.first,
      &.sep {
        padding-left: 0;
      }
    }
  }

  .details .sep::after {
    content: ".";
    position: relative;
    display: flex;
    align-items: center;
    align-contents: center;
    font-weight: 700;
    font-size: 1.1rem;
    max-height: 1rem;
    line-height: 0.75rem;
  }

  .details .bold {
    font-weight: 600;
  }
`
