import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dropdown, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { type BannerType } from '../../utils/types'
import { updateBannerInSlot } from '../../redux/slotsSlice'
import { ReactComponent as CaretDown } from 'assets/icons/caret.svg'

interface Props {
  banner: BannerType
  slotId: string
  disabled?: boolean
  dropdownContainer?: HTMLElement
}

const PublishBannerMenu: React.FC<Props> = ({ dropdownContainer, banner, slotId, disabled }) => {
  const dispatch = useDispatch()
  const [selectedMenu, setSelectedMenu] = useState(0)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setSelectedMenu(banner.published ? 0 : 1)
  }, [banner])

  const onSelectMenu = (value: number) => {
    if (selectedMenu === value) return
    dispatch(updateBannerInSlot({ slotId, updatedBanner: { ...banner, published: value === 0 } }))
  }

  const items = [
    {
      key: 1,
      label: (
        <MenuItem onClick={() => { onSelectMenu(0) }}>
          Publish
        </MenuItem>
      )
    },
    {
      key: 2,
      label: (
        <MenuItem onClick={() => { onSelectMenu(1) }}>
          Unpublish
        </MenuItem>
      )
    }
  ]

  const status = selectedMenu === 0 ? 'Published' : 'Unpublished'

  return (
    <>
      <Dropdown
        menu={{ items }}
        placement="bottomLeft"
        disabled={disabled}
        onOpenChange={(open) => {
          setOpen(open)
        }}
        arrow={{ pointAtCenter: true }}
        overlayClassName="publishOverlay"
        overlayStyle={{ padding: 0 }}
        {...dropdownContainer && { getPopupContainer: () => dropdownContainer }}
      >
        <Status disabled={disabled}>{status}</Status>
      </Dropdown>
      <Caret style={{ fill: open ? '#ed1e79' : '#404040' }} isOpen={open} />
      {banner.draft && (
        <Tooltip title="This banner is a draft" {...dropdownContainer && { getPopupContainer: () => dropdownContainer }}>
          <EphemeralIndicator>Unsaved</EphemeralIndicator>
        </Tooltip>
      )}
    </>
  )
}

export default PublishBannerMenu

export const Caret = styled(CaretDown)<{ isOpen: boolean }>`
  border-color: #f2f2f2;
  border-style: solid;
  border-width: 0;
  border-left-width: ${({ isOpen }) => (isOpen ? 1 : 0)}px;
  border-right-width: ${({ isOpen }) => (isOpen ? 0 : 1)}px;
  height: 20px;
  fill: #404040;
  margin: 0;
  padding: 0 10px;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
  width: 10px;

  .hovered,
  &:hover {
    fill: #ed1e79;
  }
`

export const Status = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? 'black' : '#ed1f78')};
  display: block;
  font-size: 14px;
  letter-spacing: -0.41px;
  padding: 5px 0 0;
  width: 70px;
`

const EphemeralIndicator = styled.sup`
  font-size: 10px;
  line-height: 20px;
  padding-left: 10px;
`

const MenuItem = styled.div`
  font-size: 14px;
  color: #404040;

  &:hover {
    color: #171717!important;
  }
`
