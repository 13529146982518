import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { basicSetup } from 'codemirror'
import { EditorState, Compartment } from '@codemirror/state'
import { EditorView, keymap } from '@codemirror/view'
import { search, searchKeymap } from '@codemirror/search'
import { javascript } from '@codemirror/lang-javascript'
import { lintKeymap } from '@codemirror/lint'
import { boysAndGirls } from 'thememirror'
import { type FieldError } from 'react-hook-form'
import { ErrorText } from './ImagePanel/styles'

interface CodePanelProps {
  value?: string
  setValue: (v: string) => void
  error?: FieldError
  readonly?: boolean
  style?: React.CSSProperties
}

const DEFAULT_VALUE = '\n'.repeat(13)
const CodePanel: React.FC<CodePanelProps> = ({ value, setValue, error, readonly, style }) => {
  const editor = useRef<HTMLDivElement>(null)
  const tabSize = new Compartment()
  const state = EditorState.create({
    doc: value,
    extensions: [
      basicSetup,
      javascript({ jsx: true }),
      tabSize.of(EditorState.tabSize.of(4)),
      keymap.of([...searchKeymap, ...lintKeymap]),
      search({ top: true }),
      // TODO: BWC-1052 Disabling Linter as it doesn't have support to template language
      // lintGutter(),
      // linter(
      //   esLint(
      //     new Linter(),
      //     {
      //       parserOptions: {
      //         ecmaVersion: 2019,
      //         sourceType: 'module',
      //         ecmaFeatures: { jsx: true }
      //       }
      //     }
      //   )
      // ),
      boysAndGirls,
      EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          setValue(update.state.doc.toString())
        }
      }),
      EditorView.editable.of(!readonly)
    ]
  })

  useEffect(() => {
    if (!editor.current) return
    const view = new EditorView({
      state,
      parent: editor.current
    })

    view.dispatch({ changes: { from: 0, to: view.state.doc.length, insert: value ?? DEFAULT_VALUE } })

    return () => {
      view.destroy()
    }
  }, [state, value])

  return (
    <>
      <Container style={style} ref={editor} />
      {error && <ErrorText>{error?.message}</ErrorText>}
    </>
  )
}
export default CodePanel

const Container = styled.div`
    border-radius: 4px;
    margin: 20px 0 12px;
    max-height: 300px;
    overflow-y: scroll;
`
