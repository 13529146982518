const globalSheets: CSSStyleSheet[] = []

const importRE = /@import\s/
export function getGlobalStyleSheets () {
  if (globalSheets.length === 0) {
    Array.from(document.styleSheets)
      .forEach(x => {
        // TODO: can likely optimize memory usage by only copying those with href
        const sheet = new CSSStyleSheet()
        const css = Array.from(x.cssRules)
          .map(rule => rule.cssText)
          // filter out @import rules, as they will be ignored anyway
          // https://github.com/WICG/construct-stylesheets/issues/119#issuecomment-588352418
          .filter((rule) => !importRE.test(rule))
          .join('\n')

        try {
          sheet.replaceSync(css)
          globalSheets.push(sheet)
        } catch (error) {
          console.warn('Failed to clone stylesheet:', error)
        }
      })
  }

  return globalSheets
}

let memoizedStyleString: string | null = null
export function getGlobalStyleSheetString () {
  if (memoizedStyleString === null) {
    memoizedStyleString = getGlobalStyleSheets().map((sheet) => {
      return Array.from(sheet.cssRules).map((css) => css.cssText).join('\n')
    }).join('\n')
  }
  return memoizedStyleString
}

export function addGlobalStylesToShadowRoot (shadowRoot: ShadowRoot) {
  shadowRoot.adoptedStyleSheets.push(
    ...getGlobalStyleSheets()
  )
}
