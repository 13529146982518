// Copyright 2022, BILDIT, INC.
//
// Licensed under the ENT License (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      https://bildit.co/ENTLicense
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
// SOFTWARE.
//
// See the License for the specific language governing permissions and
// limitations under the License.
//

import dependenciesMap from './dependenciesMap'

// Replace dependency array values which
// are string with real modules.
/**
 *
 * @param dependencies
 * @param fn
 */
export default function (dependencies, fn) {
  // Collect all data exported
  // from the remote code.
  const exports = {}
  const dependenciesMapVar = dependenciesMap
  const deps = dependencies.map((dep) => {
    if (dep === 'exports') {
      return exports
    }
    return dependenciesMapVar[dep]
  })

  fn(...deps)

  return exports
}
