import React from 'react'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { LaptopOutlined, TabletOutlined, MobileOutlined, EyeOutlined, EyeInvisibleOutlined, DownOutlined, CheckOutlined } from '@ant-design/icons'
import { type PreviewSize, usePreview } from '../../contexts/preview';
import { MenuItem } from './styles';

export default function PreviewSelector () {
  const { togglePublicMode, isPublicMode, previewSize, setPreviewSize } = usePreview()

  const handleTogglePreview = () => {
    togglePublicMode()
    if (!isPublicMode && !previewSize) {
      setPreviewSize('desktop')
    }
  }

  const handleSelectPreviewMode = (mode: PreviewSize) => {
    setPreviewSize(mode)
  }

  const getPreviewIcon = () => {
    if (isPublicMode) {
      switch (previewSize) {
        case 'desktop':
          return <LaptopOutlined />
        case 'tablet':
          return <TabletOutlined />
        case 'mobile':
          return <MobileOutlined />
        default:
          return <EyeOutlined />
      }
    } else {
      return <EyeInvisibleOutlined />
    }
  }

  const menu = (
    <Menu>
      <MenuItem onClick={handleTogglePreview}>
        {isPublicMode
          ? (
          <>
            <EyeInvisibleOutlined style={{ marginRight: 8 }} />
            Disable Preview
          </>
            )
          : (
          <>
            <EyeOutlined style={{ marginRight: 8 }} />
            Enable Preview
          </>
            )}
      </MenuItem>
      <Menu.Divider />
      <MenuItem onClick={() => { handleSelectPreviewMode('desktop'); }} disabled={!isPublicMode}>
        <LaptopOutlined style={{ marginRight: 8 }} />
        Desktop
        {previewSize === 'desktop' && <CheckOutlined style={{ marginLeft: 'auto' }}/>}
      </MenuItem>
      <MenuItem onClick={() => { handleSelectPreviewMode('tablet'); }} disabled={!isPublicMode}>
        <TabletOutlined style={{ marginRight: 8 }} />
        Tablet
        {previewSize === 'tablet' && <CheckOutlined style={{ marginLeft: 'auto' }}/>}
      </MenuItem>
      <MenuItem onClick={() => { handleSelectPreviewMode('mobile'); }} disabled={!isPublicMode}>
        <MobileOutlined style={{ marginRight: 8 }} />
        Mobile
        {previewSize === 'mobile' && <CheckOutlined style={{ marginLeft: 'auto' }}/>}
      </MenuItem>
    </Menu>
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginRight: 12 }}>
      <Tooltip title="Toggle preview mode">
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type={isPublicMode ? 'primary' : 'default'}>
            {getPreviewIcon()}
            <span style={{ marginLeft: 6 }}>
              {isPublicMode
                ? previewSize
                  ? `${previewSize.charAt(0).toUpperCase() + previewSize.slice(1)} Preview`
                  : 'Preview On'
                : 'Preview Off'}
            </span>
            <DownOutlined style={{ marginLeft: 6 }} />
          </Button>
        </Dropdown>
      </Tooltip>
    </div>
  )
}
