import React, { useEffect, useRef, type FC, useState } from 'react'
import { type ModalProps, ConfigProvider } from 'antd'
import { AdaptiveModalContainer } from './styles'
import { ReduxWrappers } from 'redux/store'
import theme from 'config/theme'
import { StyleSheetManager } from 'styled-components'
import { StyleProvider } from '@ant-design/cssinjs'
import { createPortal } from 'react-dom'
import debounce from 'lodash/debounce'
import en_US from 'antd/lib/locale/en_US'
import { useMemoizedFn } from 'ahooks'
import { getGlobalStyleSheetString } from 'utils/cloneStyles'

interface AdaptiveModalProps extends ModalProps {
  openInNewWindow?: boolean
  onClose?: () => void
  width?: number
  height?: number
  x?: number
  y?: number
}

const AdaptiveModal: FC<AdaptiveModalProps> = ({
  children,
  openInNewWindow,
  onClose,
  width = 415,
  height = 800,
  x = 415,
  y = 200,
  open,
  centered,
  ...props
}) => {
  const windowRef = useRef<Window | null>(null)
  const [storedWindow, setStoredWindow] = useState<Window | null>(null)
  const xVal = useRef<number>(x)
  const yVal = useRef<number>(y)
  const timerRef = useRef<NodeJS.Timeout>()
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null)
  const shouldOpenNewWindow = openInNewWindow && open

  const openNewWindow = useMemoizedFn(() => {
    if (centered) {
      const { outerWidth, outerHeight, screenX, screenY } = window.top ?? {}
      xVal.current = (outerWidth ?? 0) / 2 + (screenX ?? 0) - width / 2
      yVal.current = (outerHeight ?? 0) / 2 + (screenY ?? 0) - height / 2
    }
    const newWindow = window.open(
      '',
      '_blank',
      `width=${width},height=${height},screenX=${xVal.current},screenY=${yVal.current}`
    )
    if (newWindow) {
      const newWindowRoot = newWindow.document.createElement('div')
      windowRef.current = newWindow
      setStoredWindow(newWindow)
      windowRef.current.document.body.appendChild(newWindowRoot)

      // remove any margin on the body that creates an area where clicking outside doesn't trigger on modal-root
      const bodyReset = document.createElement('style')
      bodyReset.innerText = `
        body { margin: 0; font-family: ${theme.token?.fontFamily}; }
        ${getGlobalStyleSheetString()}
      `
      windowRef.current.document.head.appendChild(bodyReset)

      // Debouncing setPortalContainer prevents rapid state updates from causing multiple reflows and re-renders,
      // reducing layout thrashing and improving performance during frequent window operations.
      debounce(() => { setPortalContainer(newWindowRoot) }, 100)()

      // Add event listener to close the new window when the main window is closed
      window.addEventListener('beforeunload', () => {
        newWindow.close()
      })
    }
    return newWindow
  })

  useEffect(() => {
    // needed to fix FF issue: https://github.com/facebook/react/issues/17355
    timerRef.current = setTimeout(() => {
      if (shouldOpenNewWindow && !windowRef.current) {
        windowRef.current = openNewWindow()
        setStoredWindow(windowRef.current)
      }

      if (windowRef.current) {
        windowRef.current.addEventListener('beforeunload', () => {
          windowRef.current = null
          setStoredWindow(null)
          setPortalContainer(null)
          onClose?.()
        })
      }
    }, 0)

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [shouldOpenNewWindow, openNewWindow, onClose])

  useEffect(() => {
    if (!open) {
      windowRef.current?.close()
    }
  }, [open])

  useEffect(() => {
    return () => {
      windowRef.current?.close()
      const closeBeforeUnload = () => { windowRef.current?.close() }
      // Add event listener to close the new window when the main window is closed
      window.removeEventListener('beforeunload', closeBeforeUnload)
    }
  }, [])

  if (portalContainer) {
    return createPortal(
      <ReduxWrappers>
        <ConfigProvider locale={en_US} theme={theme} getPopupContainer={() => portalContainer}>
          <StyleProvider container={storedWindow?.document.head}>
            <StyleSheetManager target={storedWindow?.document.head}>
              <AdaptiveModalContainer className="modal-root">
                {children}
                {typeof props.footer !== 'function' && props.footer}
              </AdaptiveModalContainer>
            </StyleSheetManager>
          </StyleProvider>
        </ConfigProvider>
      </ReduxWrappers>,
      portalContainer
    )
  }

  // Fallback to prevent modal flicker and avoid the "ResizeObserver loop completed with undelivered notifications" warning.
  // This ensures the modal is only rendered when the portal container is available, avoiding unnecessary reflows.
  if (!portalContainer) {
    return null
  }
}

export default AdaptiveModal
