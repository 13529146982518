import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type TableRowSelection } from 'antd/lib/table/interface';
import {
  bannersCatalogStateSelector,
  loadBannersCatalog
} from '../../redux/bannersCatalogSlice';
import NameCell from './NameCell';
import ScheduleCell from './ScheduleCell';
import { type BannerType } from '../../utils/types';
import { useShadowRootElement } from 'contexts/shadowRoot';
import { BannerContainer, Container } from './styles';
import AdaptiveModal from 'components/AdaptiveModal';

interface BannerListModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (banners: BannerType[]) => void
}

const BannerListModal: React.FC<BannerListModalProps> = ({
  open,
  onClose,
  onConfirm
}) => {
  const dispatch = useDispatch();
  const { banners } = useSelector(bannersCatalogStateSelector);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [items, setItems] = useState<Array<BannerType & { key: string }>>([]);
  const shadowRootElement = useShadowRootElement();
  useEffect(() => {
    dispatch(loadBannersCatalog());
  }, [dispatch]);
  useEffect(() => {
    setItems(banners.map((banner) => ({ ...banner, key: banner.id ?? '' })));
  }, [banners]);
  const onConfirmHandle = () => {
    const selectedBanners: BannerType[] = selectedRowKeys.reduce(
      (acc: BannerType[], key) => {
        const banner = items.find((item) => item.id === key);
        if (banner) acc.push({ ...banner, draft: true });
        return acc;
      },
      []
    );
    selectedBanners.forEach((banner) => {
      if (!banner.locations || (Array.isArray(banner.locations) && banner.locations.length === 0)) {
        banner.locations = [window.location.pathname]
      }
      banner.locations = [...banner.locations, window.location.pathname];
      banner.variants = banner.variants.map((variant) => {
        if (Array.isArray(variant.locations)) {
          return {
            ...variant,
            locations: [...variant.locations, window.location.pathname]
          };
        }
        return variant;
      });
    });
    onConfirm(selectedBanners);
  };
  const rowSelection: TableRowSelection<BannerType> = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => {
      setSelectedRowKeys(selectedKeys);
    }
  };

  return (
    <AdaptiveModal
      openInNewWindow
      title="Select Banner"
      onClose={onClose}
      open={open}
      getContainer={shadowRootElement ?? false}
      width={900}
      height={600}
      centered
    >
      <Container>
        <Table
          scroll={{ y: '70vh' }}
          dataSource={items}
          showSorterTooltip={false}
          pagination={false}
          rowSelection={rowSelection}
          columns={[
            {
              title: 'BANNER NAME',
              dataIndex: 'name',
              key: 'name',
              sorter: (a, b) => (a.name > b.name ? 1 : -1),
              render: (_, item) => <NameCell banner={item} />
            },
            {
              title: 'LOCATION',
              key: 'location',
              dataIndex: 'location',
              sorter: (a, b) => {
                const [locationA] = a.locations;
                const [locationB] = b.locations;
                return (locationA || '') > (locationB || '') ? 1 : -1;
              }
            },
            {
              title: 'ONLINE',
              key: 'endpoint',
              dataIndex: 'endpoint',
              render: (_, item) => <ScheduleCell banner={item} />
            }
          ]}
        />
        <BannerContainer key="confirm">
          <Button
            onClick={() => {
              onConfirmHandle();
            }}
          >
            Confirm
          </Button>
        </BannerContainer>
      </Container>
    </AdaptiveModal>
  );
};

export default BannerListModal;
