import React, { useRef, useLayoutEffect, memo } from 'react'

interface Props {
  html: string
}

const HTMLBanner: React.FC<Props> = ({ html }) => {
  const elRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const element = elRef.current
    if (!element) return

    const range = document.createRange()
    range.selectNode(element)
    const documentFragment = range.createContextualFragment(html)

    const scripts = Array.from(documentFragment.querySelectorAll('script'))

    scripts.forEach(script => {
      // don't re-append a script with id
      // otherwise, only add scripts without type, or explicit JS type
      // prevents double videos on belk hp since video script  uses application/json script as config
      if ((script.id && !document.getElementById(script.id)) ||
          (!script.type || script.type === 'text/javascript')) {
        element.append(script)
      }
    })

    return () => {
      scripts.forEach(script => { script.remove() })
    }
  }, [html])

  return <div ref={elRef} dangerouslySetInnerHTML={{ __html: html }} />
}

export default memo(HTMLBanner)
