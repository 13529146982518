// bitmask flags
export enum CookieFlags {
  PLAIN = 0,
  BASE64 = 1,
  JSON = 2
}

export interface ClientCookie {
  name: string
  encoded: boolean
  json: boolean
}

export interface ConfigType {
  webAppId: string
  baseUrl: string
  publicBaseUrl: string
  fbAppApiKey: string
  separator: string
  ianaTimezone: string
  canonicalMetaSelector?: string
  canonicalMetaRegex?: string
  ajaxCategorySelector?: string
  ajaxCategoryAttribute?: string
  ajaxPageDataReadyEvent?: string
  ajaxPageDataSelector?: string
  tealiumCategorySelector?: string
  observerId: string
  cookies: ClientCookie[]
  cookiesValueMaps: Map<string, any>
  breakpoints: string
  breakpointsMap: string
  categoryChecks?: string[]
  cacheTime: number
  breadcrumbSelector?: string
  demandwareContext?: string
  categoryAttribute: string
}

export interface FirebaseConfigType {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
}
