import { Button } from 'antd'
import styled from 'styled-components'

export const CreateBannerButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

export const MenuContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 15px);
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  min-width: 250px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease-in;

  ${CreateBannerButtonWrapper}:hover & {
    opacity: 1;
    pointer-events: all;
  }
`

export const MenuContainerRight = styled.div`
  position: absolute;
  bottom: 20px;
  right: 16px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  min-width: 250px;
  transition: opacity 150ms ease-in;
`

export const MenuTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: black;
  padding: 0px 0px 0px 10px;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  background-color: #F5F7FA;
`

export const MenuButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #ed1e79;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 10px;
  transition: background-color 0.3s, color 0.3s;

  &.create-button {
    background-color: #ED1E79;
    border-color: #ED1E79;
    color: white;
  }

  &.select-button {
    background-color: #fff;
    color: #ed1e79;
    border: 1px solid #ed1e79;
  }

  &:hover {
    &.create-button {
      color: #fff;
      border-color: transparent;
    }

    &.select-button {
      color: #ed1e79;
      border-color: #ed1e79;
    }
  }
`
