import { FaSpinner } from 'react-icons/fa'
import styled from 'styled-components'

// adding here as its the only "global" bit of style we had from App.css
export const Spinner = styled(FaSpinner)`
  @keyframes circle {
    from {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(450deg);
    }
  }

  animation: circle 1.2s steps(8) infinite;
`
