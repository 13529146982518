import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useMemo,
  useCallback,
  memo
} from 'react'
import {
  format,
  isValid,
  addMonths,
  subMonths,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  addYears,
  subYears,
  isSameMonth,
  isToday,
  isSameDay,
  isBefore,
  isAfter,
  getSeconds,
  setSeconds
} from 'date-fns'
import {
  Header,
  DatePickerPanelContainer,
  CalendarBody,
  TimePanelColumn,
  TimePicker,
  Day,
  TimePickerContent,
  TimePickerHeader,
  DatePickerPanel,
  DatePickerTimePanel,
  DatePickerFooter,
  DatePickerDatePanel,
  WeekdaysContainer,
  TimePanelCell,
  TimePickerHeaderValue,
  HeaderMonth,
  VerticalDevider,
  TimePickerParamsContainer,
  TimeParametrValue,
  DatePickerAction
} from './styles'
import TextInput from 'components/TextInput'
import { Button, type InputRef } from 'antd'
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { useMemoizedFn } from 'ahooks'
import { getCurrentDateWithinTZ, getDaysForCalendar } from 'utils/date'
import styled from 'styled-components'
import { TZDate } from '@date-fns/tz'
import { appConfig } from 'config'
import { usePreview } from 'contexts/preview'

interface DatePickerProps {
  dateFormat?: string
  showTime?: boolean
  onChange: (date: any) => void
  placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  dismissTarget?: HTMLElement
  value?: string | Date | null
  minDate?: Date
  maxDate?: Date
  timeZone?: string
  withSeconds?: boolean
}

const getDate = (value: string | Date, timeZone: string): TZDate => {
  return new TZDate(typeof value === 'string' ? new Date(value) : value, timeZone)
}

const DatePicker: React.FC<DatePickerProps> = forwardRef<InputRef, DatePickerProps>(function DatePicker ({
  dateFormat = 'yyyy-MM-dd HH:mm',
  showTime = false,
  onChange,
  placement = 'bottomLeft',
  dismissTarget = window.document,
  value = getCurrentDateWithinTZ(),
  minDate,
  maxDate,
  timeZone = 'America/New_York',
  withSeconds = false
}, ref) {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const calendarRef = useRef<HTMLDivElement | null>(null)
  const secondRef = useRef<HTMLDivElement | null>(null)
  const minuteRef = useRef<HTMLDivElement | null>(null)
  const hourRef = useRef<HTMLDivElement | null>(null)
  const { setPreviewDate } = usePreview()

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const [displayDate, setDisplayDate] = useState<TZDate | null>(() => (value ? getDate(value, timeZone) : null))
  const [selectedDate, setSelectedDate] = useState<TZDate | null>(() => (value ? getDate(value, timeZone) : null))
  const [initSelectedDate] = useState<TZDate>(() => (value ? getDate(value, timeZone) : new TZDate(new Date(), appConfig.ianaTimezone)))
  const [selectedTime, setSelectedTime] = useState(() => {
    if (!value) return { hours: 0, minutes: 0, seconds: 0 }
    const date = getDate(value, timeZone)
    return {
      hours: getHours(date),
      minutes: getMinutes(date),
      seconds: getSeconds(date)
    }
  })

  const handleDayClick = useCallback((day: Date) => {
    const tzDay = new TZDate(day, timeZone)
    const newDate = setSeconds(setMinutes(setHours(tzDay, selectedTime.hours), selectedTime.minutes), selectedTime.seconds)
    setSelectedDate(new TZDate(newDate, timeZone))
  }, [selectedTime.hours, selectedTime.minutes, selectedTime.seconds, timeZone])

  const handleTimeChange = useCallback((name: string, value: number) => {
    const newTime = { ...selectedTime, [name]: value }
    setSelectedTime(newTime)

    if (selectedDate) {
      const newDate = setSeconds(setMinutes(setHours(selectedDate, newTime.hours), newTime.minutes), newTime.seconds)
      setSelectedDate(new TZDate(newDate, timeZone))
    }
  }, [selectedTime, selectedDate, timeZone])

  const handleYearChange = useCallback((delta: string) => {
    const dateToModify = selectedDate ?? initSelectedDate
    const newDate = new TZDate(delta === 'next' ? addYears(dateToModify, 1) : subYears(dateToModify, 1), timeZone)
    if (maxDate && newDate > maxDate) return
    if (minDate && newDate < minDate) return
    setSelectedDate(newDate)
  }, [initSelectedDate, maxDate, minDate, selectedDate, timeZone])

  const handleMonthChange = useCallback((delta: string) => {
    const dateToModify = selectedDate ?? initSelectedDate
    const newDate = new TZDate(delta === 'next' ? addMonths(dateToModify, 1) : subMonths(dateToModify, 1), timeZone)
    if (maxDate && newDate > maxDate) return
    if (minDate && newDate < minDate) return
    setSelectedDate(newDate)
  }, [initSelectedDate, maxDate, minDate, selectedDate, timeZone])

  const onApplyDate = useCallback((selectedDate: TZDate | null = null) => {
    setDisplayDate(selectedDate)
    onChange(selectedDate ? selectedDate.toISOString() : null)
    setIsCalendarOpen(false)
  }, [onChange])

  const handleInputClick = useCallback(() => {
    setIsCalendarOpen(prev => !prev)

    setTimeout(() => {
      minuteRef.current?.scrollIntoView({ behavior: 'instant' })
      hourRef.current?.scrollIntoView({ behavior: 'instant' })
      secondRef.current?.scrollIntoView({ behavior: 'instant' })
    }, 25)
  }, [])

  const handleClearClick = useCallback(() => {
    if (selectedDate !== null) {
      setSelectedDate(null)
      setDisplayDate(null)
      setPreviewDate(null)
      onChange(null)
    }
  }, [selectedDate, onChange, setPreviewDate])

  const [isHovered, setIsHovered] = useState(false)

  const memoizedSuffix = useMemo(() => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        cursor: 'pointer'
      }}
    >
      {!isHovered
        ? (
            <CalendarOutlined onClick={handleInputClick} />
          )
        : (
            <CloseOutlined color="#f4f4f5" onClick={handleClearClick} />
          )}
    </div>
  ), [isHovered, handleInputClick, handleClearClick])

  const memoizedDoubleLeftOutlined = useMemo(() => <DoubleLeftOutlined />, [])
  const memoizedDoubleRightOutlined = useMemo(() => <DoubleRightOutlined />, [])

  // Added memoizedLeftOutlined to prevent unnecessary re-renders
  const memoizedLeftOutlined = useMemo(() => <LeftOutlined />, [])

  const handleClickOutside = useMemoizedFn(({ target }: Event) => {
    if (
      wrapperRef.current &&
      target &&
      !wrapperRef.current?.contains(target as Node)
    ) {
      setIsCalendarOpen(false)
      setSelectedDate(displayDate)
    }
  })

  useEffect(() => {
    const modalRoot = wrapperRef.current?.closest('.modal-root') as HTMLElement
    if (modalRoot) {
      modalRoot.addEventListener('click', handleClickOutside)
    } else {
      (dismissTarget ?? document).addEventListener('click', handleClickOutside)
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeEventListener('click', handleClickOutside)
      } else {
        (dismissTarget ?? document).removeEventListener('click', handleClickOutside)
      }
    }
  }, [dismissTarget, handleClickOutside, selectedDate, displayDate])

  const formattedDate = useMemo(
    () => (displayDate ? format(new TZDate(displayDate, timeZone), dateFormat) : ''),
    [displayDate, dateFormat, timeZone]
  )

  const memoizedDaysForCalendar = useMemo(
    () => getDaysForCalendar(selectedDate ?? new TZDate(new Date(), timeZone))
      .map(day => new TZDate(day, timeZone)),
    [selectedDate, timeZone]
  )

  const renderTimeOptions = useCallback((max: number, unit: string) => {
    return Array.from({ length: max + 1 }, (_, i) => (
      <TimePanelCell
        ref={unit === 'minutes' && selectedTime[unit as keyof typeof selectedTime] === i
          ? minuteRef
          : unit === 'hours' && selectedTime[unit as keyof typeof selectedTime] === i
            ? hourRef
            : unit === 'seconds' && selectedTime[unit as keyof typeof selectedTime] === i
              ? secondRef
              : null}
        isSelected={selectedTime[unit as keyof typeof selectedTime] === i}
        key={i}
        onClick={() => { handleTimeChange(unit, i) }}
      >
        {String(i).padStart(2, '0')}
      </TimePanelCell>
    ))
  }, [selectedTime, handleTimeChange])

  const handleTodayClick = useCallback(() => {
    onApplyDate()
  }, [onApplyDate])

  const handleOkClick = useCallback(() => {
    onApplyDate(selectedDate)
  }, [onApplyDate, selectedDate])

  const memoizedTodayButton = useMemo(() => (
    <Button
      type="text"
      size="small"
      onClick={handleTodayClick}
    >
      Today
    </Button>
  ), [handleTodayClick])

  const memoizedOkButton = useMemo(() => (
    <Button
      type="primary"
      size="small"
      onClick={handleOkClick}
    >
      OK
    </Button>
  ), [handleOkClick])

  return (
    <Wrapper ref={wrapperRef} onMouseEnter={() => { setIsHovered(true); }} onMouseLeave={() => { setIsHovered(false); }}>
      <TextInput
        flat
        ref={ref}
        value={formattedDate || 'Select a date'}
        onClick={handleInputClick}
        readOnly
        suffix={memoizedSuffix}
      />
      <DatePickerPanelContainer
        open={isCalendarOpen}
        ref={calendarRef}
        placement={placement}
        showTime={showTime}
      >
        <DatePickerPanel>
          <DatePickerDatePanel>
            <Header>
              <DatePickerAction
                onClick={() => {
                  handleYearChange('prev')
                }}
              >
                {memoizedDoubleLeftOutlined}
              </DatePickerAction>
              <DatePickerAction
                onClick={() => {
                  handleMonthChange('prev')
                }}
              >
                {memoizedLeftOutlined}
              </DatePickerAction>
              <HeaderMonth>{format(selectedDate ?? new Date(), 'MMMM yyyy')}</HeaderMonth>
              <DatePickerAction
                onClick={() => {
                  handleMonthChange('next')
                }}
              >
                <RightOutlined />
              </DatePickerAction>
              <DatePickerAction
                onClick={() => {
                  handleYearChange('next')
                }}
              >
                {memoizedDoubleRightOutlined}
              </DatePickerAction>
            </Header>
            <WeekdaysContainer>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((weekDay) => (
                <span key={weekDay}>{weekDay}</span>
              ))}
            </WeekdaysContainer>
            <CalendarBody>
              {memoizedDaysForCalendar.map((day) => (
                <Day
                  key={day.toISOString()}
                  currentMonth={isSameMonth(day, selectedDate ?? new Date())}
                  disabled={
                    (minDate && isBefore(day, minDate)) ?? (maxDate && isAfter(day, maxDate)) ?? false
                  }
                  isSelected={isValid(selectedDate) && isSameDay(selectedDate ?? new Date(), day)}
                  isToday={isToday(day)}
                  onClick={() => {
                    handleDayClick(day)
                  }}
                >
                  {format(day, 'd')}
                </Day>
              ))}
            </CalendarBody>
          </DatePickerDatePanel>
          {showTime && (
            <DatePickerTimePanel>
              <TimePicker showTime={showTime}>
                <TimePickerHeader>
                  <TimePickerHeaderValue>
                    {format(selectedDate ?? new Date(), 'MMM dd')}
                  </TimePickerHeaderValue>
                  <VerticalDevider />
                  <TimePickerHeaderValue>
                    {withSeconds
                      ? `${String(selectedTime.hours).padStart(2, '0')}:${String(selectedTime.minutes).padStart(2, '0')}:${String(selectedTime.seconds).padStart(2, '0')}`
                      : `${String(selectedTime.hours).padStart(2, '0')}:${String(selectedTime.minutes).padStart(2, '0')}`
                    }
                  </TimePickerHeaderValue>
                </TimePickerHeader>
                <TimePickerParamsContainer>
                  {(withSeconds ? ['Hr', 'Min', 'Sec'] : ['Hr', 'Min']).map((timeParams) => (
                    <TimeParametrValue key={timeParams}>{timeParams}</TimeParametrValue>
                  ))}
                </TimePickerParamsContainer>
                <TimePickerContent>
                  <TimePanelColumn>
                    {renderTimeOptions(23, 'hours')}
                  </TimePanelColumn>
                  <TimePanelColumn>
                    {renderTimeOptions(59, 'minutes')}
                  </TimePanelColumn>
                  {withSeconds && (
                    <TimePanelColumn>
                      {renderTimeOptions(59, 'seconds')}
                    </TimePanelColumn>
                  )}
                </TimePickerContent>
              </TimePicker>
            </DatePickerTimePanel>
          )}
        </DatePickerPanel>
        <DatePickerFooter>
          {memoizedTodayButton}
          {memoizedOkButton}
        </DatePickerFooter>
      </DatePickerPanelContainer>
    </Wrapper>
  )
})

export default memo(DatePicker)

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
`
