import ReactDOM from 'react-dom/client'
import { PARAM_ACCOUNT, PARAM_PREVIEW_MODE, SFCC_PARAM_PREVIEW_ID } from './utils/urlParams'

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
  // eslint-disable-next-line no-var
  var PROD_MODE: boolean | undefined
  // eslint-disable-next-line no-var
  var JSHINT: any
}

export function getRootElement () {
  if (navigator.storage?.persist) {
    navigator.storage.persist().then((persistent) => {
      if (window.BILDIT) {
        window.BILDIT.persistent = persistent
      }
      if (persistent) {
        console.log('Storage will not be cleared except by explicit user action')
      } else {
        console.log('Storage may be cleared by the UA under storage pressure.')
      }
    }).catch((ex) => {
      console.error(ex)
    })
  }

  let toolBarElement = document.getElementById('toolbar_container')
  if (!toolBarElement) {
    toolBarElement = document.createElement('div')
    toolBarElement.id = 'toolbar_container'
    document.body.appendChild(toolBarElement)
    toolBarElement = document.getElementById('toolbar_container')
  }
  const queryParams = new URLSearchParams(window.location.search)
  const previewID = queryParams.get(SFCC_PARAM_PREVIEW_ID)
  const previewMode = queryParams.get(PARAM_PREVIEW_MODE)
  const account = queryParams.get(PARAM_ACCOUNT)
  const isPreviewMode = !!previewMode || (previewID === '' || !!previewID)
  const shouldShowAdminControls = !!account && !isPreviewMode

  if (toolBarElement) {
    if (shouldShowAdminControls) {
      const sharedPaddingTop = '80px'
      document.body.style.paddingTop = sharedPaddingTop

      // There is a case when a web with fixed header overlapped with our fixed toolbar
      // Temporary solution: just add the margin to the header
      // Future solution: maybe add custom CSS form so that user can define the style without changing the webscript
      const headers = document.getElementsByTagName('header')
      for (let i = 0; i < headers.length; i++) {
        const headerStyle = window.getComputedStyle(headers[i])
        if (headerStyle.getPropertyValue('position') === 'fixed') {
          headers[i].style.marginTop = sharedPaddingTop
        }
      }
    }
    toolBarElement.style.position = 'fixed'
    toolBarElement.style.width = '100%'
    toolBarElement.style.zIndex = '1000'
    toolBarElement.style.top = '0px'
    toolBarElement.style.left = '0px'
    toolBarElement.setAttribute('version', '1.0.10: 2023-06-28')
    return ReactDOM.createRoot(toolBarElement)
  }
}
