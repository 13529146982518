import HTMLBanner from 'components/HTMLBanner'
import React, { type FC, useMemo } from 'react'
import { isValidElementType } from 'react-is'
import { useBannersStore } from 'services/banners.public'
import strictEvaluate from 'utils/eval'
import type { CodeType } from 'utils/types'

interface Props {
  code: string
  codeType?: CodeType
  remoteProps?: Record<string, unknown>
}

/**
 * Remote
 *
 * @param param0 RemoteProps
 * @param param0.code
 * @param param0.remoteProps
 * @returns JSX.Element
 */
const Remote: FC<(Props)> = ({
  code, codeType, remoteProps, ...rest
}) => {
  const clientFn = useBannersStore((state) => state.clientDefaultFn)
  const compilationNeeded = codeType && !['html', 'json'].includes(codeType)

  let exports: any
  try {
    exports = compilationNeeded ? strictEvaluate(code, '') : code
  } catch (e) {
    console.error(e)
  }
  const RemoteComponent = useMemo(() => {
    if (codeType === 'json') return null
    if (codeType === 'html') return <HTMLBanner html={code} />
    return exports?.default
  }, [code, codeType, exports?.default])
  const RemoterComponent = useMemo(() => {
    try {
      if (codeType === 'html') return RemoteComponent
      const Remoter = typeof clientFn === 'function' ? clientFn(RemoteComponent) : RemoteComponent
      return <Remoter {...rest} remoteProps={remoteProps} />
    } catch (e) {
      console.error('Failed wrapping with default config', e)
    }
  }, [RemoteComponent, clientFn, codeType, remoteProps, rest])

  return compilationNeeded && isValidElementType(RemoteComponent) ? RemoterComponent : RemoteComponent
}

export default React.memo(Remote)
