import React, { useCallback, type FC } from 'react'
import { Button, Flex, Tabs, Form } from 'antd'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { TEMP_ID, type Variant, type BannerType, type VariantMetadata } from 'utils/types'
import AdaptiveModal from 'components/AdaptiveModal'
import ImagePanel from 'components/ImagePanel'
import TextInput from 'components/TextInput'
import FormItem from 'components/FormItem'
import DatePicker from 'components/DatePicker'
import {
  ButtonCode,
  ScheduleContainer,
  ScheduleHeader,
  ScheduleItemContainer,
  SelectFromListContainer,
  TemplateFieldsContainer,
  TemplatePanel
} from './styles'
import capitalize from 'lodash/capitalize'
import MetadataField from 'components/MetadataField'
import RemotePreview from 'remote/RemotePreview'
import { useDispatch, useSelector } from 'react-redux'
import { bannersListStateSelector } from 'redux/bannersSlice'
import { getMetadataFieldName } from 'components/MetadataField/MetadataField'
import { getDefaultSchedules, subtractDaysWithinTZ } from 'utils/date'
import { updateBannerInSlot } from 'redux/slotsSlice'
import { variantsIndex } from 'services/banners.public'
import { v4 } from 'uuid'
import { DEFAULT_SCREENS, isEphemeral, type VariantSubmitData } from 'utils/adapters'

interface Metadata {
  type: string
  name: string
  defaultValue?: any
  value?: any
  dropdownOptions?: Array<{
    label: string
    value: string | number
  }>
  initialIndex: number
}

export type CodeType = 'js' | 'jsx' | 'ts' | 'tsx' | 'html' | 'json' & string

export const validateMetadata = (submittedMetadata: VariantMetadata[]) => (
  submittedMetadata.every((md) => VariantMetadataSchema.safeParse(md).success)
)
export const validateVariants = (submittedVariant: VariantSubmitData[]) => submittedVariant.every(({ metadata }) => validateMetadata(metadata ?? []))
export const COLOR_REGEX = /^#([\dA-Fa-f]{6}|[\dA-Fa-f]{8}|[\dA-Fa-f]{3})$/

export const VariantMetadataSchema = z.object({
  name: z.string().min(1),
  type: z.string(),
  defaultValue: z.union([z.string(), z.number(), z.boolean()]).optional().nullable(),
  dropdownOptions: z.array(z.object({
    label: z.string(),
    value: z.union([z.string(), z.number()])
  })).optional(),
  value: z.union([z.string(), z.number(), z.boolean()]).optional().nullable().refine((val: any) => {
    if (val === undefined || val === null) {
      return true; // Allow optional fields when defaultValue is undefined or null
    }
    return val !== undefined || val !== null; // Ensure value is defined otherwise
  })
}).refine(
  data => {
    if (data?.defaultValue === undefined || data.defaultValue === null) {
      return true
    }

    let strValue: string
    let numValue: number

    switch (data.type) {
      case 'Boolean':
        if (data.value === undefined) return data.defaultValue === 'true' || data.defaultValue === 'false'
        strValue = String(data.value ?? data.defaultValue).toLowerCase()
        return strValue === 'true' || strValue === 'false'

      case 'Number':
        if (data.value === undefined) return !Number.isNaN(+data.defaultValue) && Number.isFinite(+data.defaultValue)
        numValue = Number(data.value ?? data.defaultValue)
        return !Number.isNaN(numValue) && Number.isFinite(numValue)

      case 'String':
        if (data.value === undefined) return typeof data.defaultValue === 'string' && data.defaultValue.length > 0
        strValue = String(data.value ?? data.defaultValue).toLowerCase()
        return strValue.length > 0

      case 'Color':
        if (data.value === undefined) return typeof data.defaultValue === 'string' && COLOR_REGEX.test(data.defaultValue)
        return typeof data.value === 'string' && COLOR_REGEX.test(data.value)

      case 'Dropdown':
      default:
        // eslint-disable-next-line eqeqeq
        return (data?.value ?? data?.defaultValue) != undefined
    }
  },
  data => ({
    message: `${getMetadataFieldName(data.name)} is required and must match the expected type.`,
    path: ['value']
  }))

const FormVariantSchema = z.object({
  id: z.string().optional(),
  categories: z.array(z.string()).optional(),
  locations: z.array(z.string()).optional(),
  code: z.string().optional(),
  codeType: z.string().optional(),
  image: z.string().url({ message: 'Upload an image or provide image URL' }).optional(),
  metadata: z.array(VariantMetadataSchema),
  templateName: z.string().optional(),
  url: z.string().optional()
}).refine(data => {
  if (data.image) return true
  if (data?.codeType === 'html' && data.code && data.templateName === undefined) return true
  if ((data.templateName === undefined || data.code === undefined) && data.image === undefined) return false
  return true
}, {
  message: 'Image is required without a template',
  path: ['image']
})

const ScheduleTypeSchema = z.object({
  startDate: z.string().min(1),
  endDate: z.string().min(1),
  slot: z.number().min(1)
}).refine(data => (typeof data.endDate === 'string' ? Date.parse(data.endDate) : data.endDate) > (typeof data.startDate === 'string' ? Date.parse(data.startDate) : data.startDate), {
  message: 'Please select a Online from that is earlier than the Online to.',
  path: ['endDate']
})

const FormFieldsSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1),
  screenTypes: z.array(z.enum(['desktop', 'tablet', 'phone'])),
  alternateText: z.string(),
  published: z.boolean().optional(),
  webSlots: z.array(z.string()).min(1),
  locations: z.array(z.string()),
  schedules: z.array(ScheduleTypeSchema).min(1),
  variants: z.array(FormVariantSchema).min(1)
})

interface BannerFormProps {
  open: boolean
  slotId: string
  banner?: BannerType
  htmlCode?: string
  onSubmit: (values: any) => void
  onCancel: () => void
  onClickSelectFromList: () => void
  onViewCode?: () => void
}

type FormFieldsSchemaType = z.infer<typeof FormFieldsSchema>

const defaultLocationsForVariant = (variant: Variant) => {
  if (variant.categories && variant.categories?.length > 0) {
    return []
  }
  return variant.locations && variant.locations?.length > 0 ? variant.locations : [window.location.pathname]
}

const BannerFormModal: FC<BannerFormProps> = ({
  banner,
  open,
  slotId,
  htmlCode,
  onSubmit,
  onCancel,
  onClickSelectFromList,
  onViewCode
}) => {
  const { defaultTemplate: template } = useSelector(bannersListStateSelector)
  // get variant index for our code banner, otherwise revert to position 0 for legacy images
  const vIndex = variantsIndex(banner?.variants ?? [], location.href)
  const variantIndex = vIndex !== -1 ? vIndex : 0

  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FormFieldsSchemaType>({
    resolver: zodResolver(FormFieldsSchema),
    defaultValues: {
      id: banner?.id ?? TEMP_ID(),
      name: banner?.name ?? '',
      alternateText: banner?.alternateText ?? '',
      published: banner?.published ?? true,
      schedules: banner?.schedules?.map((schedule) => ({
        ...getDefaultSchedules(schedule),
        slot: schedule.slot ?? 1
      })) ?? [{
        ...getDefaultSchedules(),
        slot: 1
      }],
      variants: banner?.variants?.map((v) => {
        const bannerHasImg = typeof v.image === 'string' && v?.image.length > 0
        const isHTML = v.codeType === 'html' &&
          ((typeof htmlCode === 'string' && htmlCode?.length > 0) || (
            typeof v.code === 'object' &&
          ((typeof v.code.html === 'string' && v.code.html.length > 0) ||
            (typeof v.code.compiled === 'string' && v.code.compiled.length > 0))))
        return {
          ...v,
          id: v?.id ?? v4(),
          categories: v?.categories ?? [],
          customerGroups: v?.customerGroups ?? [],
          locations: defaultLocationsForVariant(v),
          image: v?.image ?? undefined,
          code: bannerHasImg
            ? ''
            : v?.code?.raw ?? template?.raw ?? '',
          codeType: bannerHasImg
            ? ''
            : v?.codeType ?? template?.codeType ?? '',
          metadata: bannerHasImg || isHTML
            ? []
            : v?.metadata ?? template?.metadata ?? [],
          templateName: bannerHasImg || isHTML
            ? undefined
            : v?.templateName ?? template?.name
        }
      }) ?? [{
        categories: [],
        code: template?.raw,
        codeType: template?.codeType,
        id: v4(),
        image: undefined,
        locations: [window.location.pathname],
        metadata: template?.metadata ?? [],
        templateName: template?.name
      }],
      locations: banner?.locations && banner.locations?.length > 0 ? banner.locations : [window.location.pathname],
      screenTypes: banner?.screenTypes ?? DEFAULT_SCREENS,
      webSlots: [...(banner?.webSlots ?? [slotId])].sort((a, b) => a === slotId || b === slotId ? -1 : a.localeCompare(b))
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schedules'
  })

  const { metadata, code, codeType } = watch(`variants.${variantIndex}`) ?? {}
  const imageMetadata: Metadata[] = []
  const nonImageMetadata: Metadata[] = []
  metadata
    ?.map((item, index) => ({ ...item, initialIndex: index }))
    .forEach((md) => {
      md.type === 'Image' ? imageMetadata.push(md) : nonImageMetadata.push(md)
    })

  const onCompiled = useCallback((code: string, _metadata: VariantMetadata[]) => {
    if (banner) {
      dispatch(updateBannerInSlot({
        slotId,
        updatedBanner: {
          ...banner,
          variants: [{
            ...banner.variants[variantIndex],
            code: {
              ...banner.variants[variantIndex].code,
              compiled: code
            }
          }]
        }
      }))
    }
  }, [banner, dispatch, slotId, variantIndex])

  const shouldShowPreview = !!code && !!metadata

  const shouldShowTabs = imageMetadata.length > 0

  return (
    <AdaptiveModal
      width={515}
      openInNewWindow
      onClose={onCancel}
      open={open}
      footer={[
        <Button key="cancel" type="default" onClick={onCancel}>
          CANCEL
        </Button>,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <Button key="confirm" type="primary" onClick={handleSubmit(onSubmit)}>
          CONFIRM
        </Button>
      ]}
    >
      <SelectFromListContainer>
        <Button type="primary" onClick={onClickSelectFromList}>
          Select from Code Library
        </Button>
      </SelectFromListContainer>
      <Form>
        {banner?.id && !isEphemeral(banner) && (<FormItem label="Banner ID">
          <Controller
            name="id"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                readOnly
              />
            )}
          />
        </FormItem>)}
        <FormItem
          label="Banner Name"
          extra="E.g. End of Summer Promo"
          validateStatus={errors.name && 'error'}
          help={errors.name?.message}
          required
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput
                infoText="Enter the title of your banner that you wish to"
                {...field}
              />
            )}
          />
        </FormItem>

        <TemplatePanel>
          {shouldShowPreview && (
            <RemotePreview
              bannerId={banner?.id ?? ''}
              code={(codeType === 'html' ? htmlCode : code) ?? ''}
              codeType={codeType as CodeType}
              metadata={metadata}
              onCompiled={onCompiled} />
          )}

          {shouldShowTabs && (
            <Tabs
              items={imageMetadata?.map(
                ({ name, initialIndex }) => ({
                  label: capitalize(name),
                  key: name,
                  children: (
                    <Controller
                      name={`variants.${variantIndex}.metadata.${initialIndex}.value`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ImagePanel
                          value={value as string}
                          onChange={onChange}
                          onClearImage={() => { onChange('') }}
                          error={errors.variants?.[variantIndex]?.metadata?.[initialIndex]?.value}
                        />
                      )}
                    />
                  )
                })
              )}
            />
          )}

          {!!nonImageMetadata?.length && (
            <TemplateFieldsContainer>
              {nonImageMetadata?.map(({ name, type, dropdownOptions, defaultValue, initialIndex }) => (
                <Controller
                  key={initialIndex}
                  name={`variants.${variantIndex}.metadata.${initialIndex}.value`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <MetadataField
                      name={name}
                      type={type}
                      defaultValue={defaultValue}
                      value={value ?? defaultValue}
                      dropdownOptions={dropdownOptions}
                      onChange={onChange}
                      error={errors.variants?.[variantIndex]?.metadata?.[initialIndex]?.value}
                    />
                  )}
                />
              ))}
            </TemplateFieldsContainer>
          )}

          {!!code && !!onViewCode && <ButtonCode type='default' onClick={onViewCode} >
            View Code
          </ButtonCode>}
        </TemplatePanel>

        <>
          {code
            ? null
            : <FormItem label="Image" required>
                <Controller
                  name={`variants.${variantIndex}.image`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ImagePanel
                      value={value}
                      onChange={onChange}
                      onClearImage={() => { onChange('') }}
                      error={errors.variants?.[variantIndex]?.image}
                    />
                  )}
                />
              </FormItem>
          }
        </>

        <FormItem label="Location" extra="E.g. Home, PDP, PLP or URL" required>
          <Controller
            name={`variants.${variantIndex}.locations`}
            control={control}
            render={({ field }) => (
              <TextInput
                readOnly
                infoText="E.g. https://google.com"
                {...field}
              />
            )}
          />
        </FormItem>
        <FormItem label="Slot ID" extra="E.g. slot1, homepage1" required>
          <Controller
            name="webSlots.0"
            control={control}
            render={({ field }) => (
              <TextInput readOnly infoText="E.g. slot1, homepage1" {...field} />
            )}
          />
        </FormItem>
        <FormItem label="Alternative Text" extra="E.g. Spring Sale">
          <Controller
            name="alternateText"
            control={control}
            render={({ field }) => (
              <TextInput
                infoText="Extra text that will be displayed on your banner"
                {...field}
              />
            )}
          />
        </FormItem>
        <ScheduleContainer>
          <ScheduleHeader justify="space-between" align="middle">
            <h6>Schedules</h6>
            <Button
              size="small"
              type="link"
              onClick={() => {
                append({
                  ...getDefaultSchedules(),
                  slot: fields.length + 1
                })
              }}
            >
              Add new schedule
            </Button>
          </ScheduleHeader>
          {fields.map((field, index) => {
            const isFirst = index === 0
            return (
              <ScheduleItemContainer
                index={index}
                key={`${index}_field_${field.id}`}
              >
                <Flex justify="space-between" gap={6}>
                  <FormItem
                    label="Online from"
                    validateStatus={
                      errors.schedules?.[index]?.startDate && 'error'
                    }
                    help={errors.schedules?.[index]?.startDate?.message}
                  >
                    <Controller
                      control={control}
                      name={`schedules.${index}.startDate`}
                      render={({ field }) => (
                        <DatePicker
                          showTime
                          withSeconds
                          dateFormat='yyyy-MM-dd HH:mm:ss'
                          placement="topLeft"
                          minDate={subtractDaysWithinTZ()}
                          {...field}
                        />
                      )}
                    />
                  </FormItem>
                  <FormItem
                    label="Online to"
                    validateStatus={
                      errors.schedules?.[index]?.endDate && 'error'
                    }
                    help={errors.schedules?.[index]?.endDate?.message}
                  >
                    <Controller
                      control={control}
                      name={`schedules.${index}.endDate`}
                      render={({ field }) => (
                        <DatePicker
                          withSeconds
                          showTime
                          dateFormat='yyyy-MM-dd HH:mm:ss'
                          placement="topRight"
                          minDate={subtractDaysWithinTZ()}
                          {...field}
                        />
                      )}
                    />
                  </FormItem>
                </Flex>
                {!isFirst && (
                  <Flex justify="end">
                    <Button
                      type="link"
                      size="small"
                      onClick={() => {
                        remove(index)
                      }}
                    >
                      Delete
                    </Button>
                  </Flex>
                )}
              </ScheduleItemContainer>
            )
          })}
        </ScheduleContainer>
      </Form>
    </AdaptiveModal>
  )
}

export default BannerFormModal
