import { useClientCookies } from 'contexts/cookies'
import { useMemo } from 'react'
import { SESSION_SFCC_SITE_DATE, SFCC_PARAM_SITE_DATE } from 'utils/urlParams'

export const dateFromSFCC = (dateStr: string): Date => {
  const d = new Date()
  const matches = dateStr?.match(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})$/)
  if (matches?.length === 6) {
    d.setFullYear(+matches[1], -1 + +matches[2], +matches[3])
    d.setHours(+matches[4], +matches[5], 0, 0)
  }

  return d
}

const useSiteDate = () => {
  const cookies = useClientCookies()
  const siteDate = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const urlParam = queryParams.get(SESSION_SFCC_SITE_DATE) ?? queryParams.get(SFCC_PARAM_SITE_DATE)
    const cookieParam = cookies.cookies.find((c) => c.name === SESSION_SFCC_SITE_DATE)?.value
    const sessionParam = sessionStorage.getItem(SESSION_SFCC_SITE_DATE)
    return urlParam ?? cookieParam ?? sessionParam
  }, [cookies.cookies])
  const providedDate = useMemo(() => siteDate ? dateFromSFCC(siteDate) : undefined, [siteDate])

  return providedDate
}

export default useSiteDate
