import styled from 'styled-components'

export const BannerContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
`

// TODO: Temporary solution, Antd add visibility hidden to header table when we use scroll prop, so we just remove it for now
export const Container = styled.div` 
  .ant-table-header > table {
    visibility: initial !important;
  }
`
