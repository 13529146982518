import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react'

interface LocationContextParams {
  url: string
}

const LocationContext = createContext<LocationContextParams>({
  url: window.location.href
})

interface LocationProviderProps {
  children: React.ReactNode
}

const origPushState = window.history.pushState

export const LocationProvider: React.FC<LocationProviderProps> = ({ children }) => {
  const [loc, setLoc] = useState<string>(window.location.href)

  // Memoize the URL update function
  const updateUrl = useCallback((path: string) => {
    setLoc(window.location.origin + path)
  }, [])

  // Memoize the proxy to prevent recreation on each render
  const pushStateProxy = useMemo(() => new Proxy(window.history.pushState, {
    apply: (target, thisArg, argArray) => {
      updateUrl(argArray[2])
      target.apply(thisArg, [argArray[0], argArray[1], argArray[2]]);
    }
  }), [updateUrl])

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({ url: loc }), [loc])

  useEffect(() => {
    window.history.pushState = pushStateProxy
    return () => {
      window.history.pushState = origPushState
    }
  }, [pushStateProxy])

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  )
}

export const useProxyLocation = () => {
  const context = useContext<LocationContextParams>(LocationContext)
  if (!context) {
    throw new Error('useProxyLocation must be used within a LocationProvider');
  }
  return context
}
