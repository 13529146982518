import {
  type BaseQueryFn,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'
import { appConfig } from 'config'
import { type CodeSnippet } from './codeLibrary.d'
import type { RootState } from '../redux/store';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: appConfig.baseUrl
})

const baseQuery: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = (args, api, extraOptions) => {
  const { token } = (api.getState() as RootState).auth
  if (typeof args === 'string') {
    return rawBaseQuery(args, api, extraOptions)
  }
  const modifiedArgs = {
    ...args,
    headers: {
      ...args.headers,
      Authorization: `Bearer ${token}`
    }
  }
  return rawBaseQuery(modifiedArgs, api, extraOptions)
}

export const codeLibraryApi = createApi({
  reducerPath: 'codeLibraryApi',
  baseQuery,
  endpoints: builder => ({
    getCodeSnippets: builder.query<CodeSnippet[], any>({
      query: params => ({
        url: `api/apps/${appConfig.webAppId}/codelibs`,
        params
      })
    })
  })
})

export const {
  usePrefetch,
  useGetCodeSnippetsQuery
} = codeLibraryApi
