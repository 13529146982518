import { createCookieValueMaps, mapClientCookies } from 'utils/cookies'
import { type ConfigType, type FirebaseConfigType } from './types'
import { mapCategoryConfigToFunction } from 'hooks/useCategory'

const cacheEnv = process.env.REACT_APP_PUBLIC_CACHE_TIME
const appConfig: ConfigType = {
  webAppId: (window.pageData?.cmsWebAppId && window.pageData?.cmsWebAppId?.length > 0)
    ? window.pageData?.cmsWebAppId
    : process.env.REACT_APP_WEB_APP_ID ?? 'CONFIG_WEB_APP_ID',
  baseUrl: (window.pageData?.cmsFirebaseURL && window.pageData?.cmsFirebaseURL?.length > 0 && window.pageData?.cmsFirebaseURL !== 'null')
    ? window.pageData?.cmsFirebaseURL
    : process.env.REACT_APP_FUNCTIONS_URL ?? 'CONFIG_BASE_URL',
  publicBaseUrl: (window.pageData?.cmsActualFirebaseURL && window.pageData?.cmsActualFirebaseURL?.length > 0 && window.pageData?.cmsActualFirebaseURL !== 'null')
    ? window.pageData?.cmsActualFirebaseURL
    : process.env.REACT_APP_PUBLIC_FUNCTIONS_URL ?? process.env.REACT_APP_FUNCTIONS_URL ?? 'CONFIG_BASE_URL',
  fbAppApiKey: (window.pageData?.cmsFirebaseKey && window.pageData?.cmsFirebaseKey?.length > 0)
    ? window.pageData?.cmsFirebaseKey
    : process.env.REACT_APP_FUNCTIONS_API_KEY ?? 'CONFIG_FB_APP_API_KEY',
  separator: process.env.SITE_PARAM_SEPARATOR ?? '&',
  ianaTimezone: process.env.REACT_APP_IANA_TZ ?? 'America/New_York',
  categoryAttribute: process.env.REACT_APP_CLIENT_CATEGORY_ATTR ?? 'data-cgid',
  // canonical URL (containing ?cgid=) meta selector
  canonicalMetaSelector: process.env.REACT_APP_CLIENT_META_CGID_SELECTOR ?? '',
  canonicalMetaRegex: process.env.REACT_APP_CLIENT_META_CGID_REGEX ?? '/cgid=([^&]+)/',
  // selector for data attribute with cgid value
  ajaxCategorySelector: process.env.REACT_APP_CLIENT_AJAX_CGID_SELECTOR ?? '',
  ajaxCategoryAttribute: process.env.REACT_APP_CLIENT_AJAX_CGID_ATTR ?? '',
  ajaxPageDataReadyEvent: process.env.REACT_APP_CLIENT_AJAX_PAGE_DATA_READY_EVENT ?? '',
  ajaxPageDataSelector: process.env.REACT_APP_CLIENT_AJAX_PAGE_DATA_SELECTOR ?? '',
  // variable to lookup cgid (eg, utag_data.category_id)
  tealiumCategorySelector: process.env.REACT_APP_CLIENT_TEALIUM_CGID_SELECTOR ?? '',
  categoryChecks: mapCategoryConfigToFunction(process.env.REACT_APP_CLIENT_CATEGORY_CHECKS) ?? '',
  // ID of element to observer for AJAX category updates (empty string disables)
  observerId: process.env.REACT_APP_CLIENT_OBSERVER_ID ?? '',
  // configuration for client cookies to read
  cookies: mapClientCookies(process.env.REACT_APP_CLIENT_COOKIES),
  // JSON configuration of map for values if raw is not desired (ex. value in cookie is ELITE, mapped to BelkElite)
  cookiesValueMaps: createCookieValueMaps(process.env.REACT_APP_CLIENT_COOKIES_MAP),
  // JSON mapping of reakpoints. nulls maps to Infinity (positive/negative for max/min)
  // defaults are from previous example config
  breakpoints: process.env.REACT_APP_CLIENT_BREAKPOINTS ?? '{"xxl":{"min":1600,"max":null},"xl":{"min":1200,"max":1600},"lg":{"min":992,"max":1200},"md":{"min":768,"max":992},"sm":{"min":576,"max":768},"xs":{"min":null,"max":576}}',
  // JSON mapping of breakpoint keys to desktop / tablet / phone
  // defaults are from previous example config
  breakpointsMap: process.env.REACT_APP_CLIENT_BREAKPOINTS_MAP ?? '{"desktop":["md","lg","xl","xxl"],"tablet":["sm"],"phone":["xs"]}',
  cacheTime: typeof cacheEnv !== 'undefined' && !Number.isNaN(+cacheEnv) && Number.isInteger(+cacheEnv) ? +cacheEnv : 36e5, // 1 hr
  breadcrumbSelector: process.env.REACT_APP_CLIENT_BREADCRUMB_SELECTOR ?? '.breadcrumb-element.last-element[data-cgid]',
  demandwareContext: process.env.REACT_APP_CLIENT_DW_CONTEXT ?? 'dwContextID="(.+)?"'
}

const firebaseConfig: FirebaseConfigType = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? 'FB_CONFIG_API_KEY',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'FB_CONFIG_AUTH_DOMAIN',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL ?? 'FB_CONFIG_DATABASE_URL',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'FB_CONFIG_PROJECT_ID',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? 'FB_CONFIG_STORAGE_BUCKET'
}

export { appConfig, firebaseConfig }
