import React, { useState, useRef } from 'react';
import { type FieldError, type FieldErrorsImpl, type Merge } from 'react-hook-form';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { HexColorPicker } from 'react-colorful';
import * as S from './styles';
import TextInput from 'components/TextInput';

interface ColorPickerProps {
  label: string
  color: string
  onChange: (color: string) => void
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
}

const ColorPicker: React.FC<ColorPickerProps> = ({ label, color, onChange, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => { setIsOpen(false) });

  return (
    <S.ColorPickerWrapper ref={ref} isOpen={isOpen}>
      <S.Label>{label}</S.Label>
      <S.ColorPickerContainer>
        <S.Toggle
          value={color}
          onClick={() => { setIsOpen(!isOpen) }}
        />
        <TextInput
          value={color}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { onChange(e.target.value); }}
        />
      </S.ColorPickerContainer>
      {isOpen && (
        <S.Palette>
          <HexColorPicker
            color={color}
            onChange={(hex) => {
              onChange(hex)
            }}
            onMouseUp={() => { setIsOpen(false) }}
          />
        </S.Palette>
      )}
      {error && <S.ErrorMessage>{error.message?.toString()}</S.ErrorMessage>}
    </S.ColorPickerWrapper>
  );
};

export default ColorPicker;
