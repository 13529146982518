import React, { useMemo } from 'react'
import TextInput, { type TextInputProps } from 'components/TextInput'
import startCase from 'lodash/startCase'
import FormItem from 'components/FormItem'
import SelectPicker from 'components/SelectPicker'
import { type SelectPickerProps } from 'components/SelectPicker/SelectPicker'
import ColorPicker from 'components/ColorPicker'
import { type ChromeProps } from '@uiw/react-color-chrome';
import { type FieldError } from 'react-hook-form'
import { type ddOpt } from 'utils/metadata'

export type MetadataFieldProps = ChromeProps & SelectPickerProps & TextInputProps & {
  type: string
  name: string
  defaultValue?: any
  value: any
  dropdownOptions?: ddOpt[]
  onChange: (value: string) => void
  title?: string
  error?: FieldError | any
} & Omit<TextInputProps, 'onChange'>

interface ChangeTextConfig {
  upperCase?: boolean
  subdash?: boolean
}

const hasAEMToken = (str: string): boolean => str.split(' ').some((token) => /aem/i.test(token))

export const getMetadataFieldName = (name: string): string => {
  const startCaseName = startCase(name)

  if (hasAEMToken(startCaseName)) {
    return startCaseName.replace(/\baem/i, 'AEM')
  }

  return startCaseName
}

const MetadataField: React.FC<MetadataFieldProps> = ({ type, name, value, defaultValue, dropdownOptions, error, ...props }) => {
  const onChangeTextInput = (config: ChangeTextConfig) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempStr = e.target.value;

    if (config?.upperCase) {
      tempStr = tempStr.toUpperCase();
    }

    if (config?.subdash && tempStr.at(-1) === ' ') {
      tempStr = tempStr.replace(' ', '_');
    }

    props.onChange(tempStr);
  }

  const changeConfig = useMemo(() => ({
    subdash: hasAEMToken(name)
  }), [name])

  if (type === 'Color') {
    return (
      <FormItem
        required={!defaultValue}
        label={getMetadataFieldName(name)}
        validateStatus={error && 'error'}
        help={error?.message}
      >
        <ColorPicker
          label={''}
          color={value ?? defaultValue}
          onChange={props.onChange}
        />
      </FormItem>
    )
  }

  if (type === 'Dropdown') {
    const options = Array.isArray(dropdownOptions) ? dropdownOptions ?? [] : []
    return (
      <FormItem
        required={defaultValue !== null && defaultValue !== undefined}
        name={name}
        label={getMetadataFieldName(props.title ?? name)}
        validateStatus={error && 'error'}
        help={error?.message}
      >
        <SelectPicker
          options={options}
          onChange={props.onChange}
          selectedValue={value}
          defaultValue={defaultValue}
        />
      </FormItem>
    )
  }

  return (
    <FormItem
      required={defaultValue !== null && defaultValue !== undefined}
      label={getMetadataFieldName(name)}
      validateStatus={error && 'error'}
      help={error?.message}
    >
      <TextInput
        {...props}
        onChange={onChangeTextInput(changeConfig)}
        value={value ?? defaultValue}
      />
    </FormItem>
  )
}

export default MetadataField
