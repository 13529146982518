import React, { createContext, useContext, useEffect, useState } from 'react'

interface ShadowRootContextParams {
  shadowRoot: ShadowRoot | null
  fallbackRoot: Document
}
const ShadowRootContext = createContext<ShadowRootContextParams>({ shadowRoot: null, fallbackRoot: document })

interface ShadowRootProviderProps {
  children: React.ReactNode
  shadowRoot: ShadowRoot | null
  fallbackRoot?: Document
}

export const ShadowRootProvider: React.FC<ShadowRootProviderProps> = ({ children, shadowRoot, fallbackRoot = document }) => {
  return (
    <ShadowRootContext.Provider value={{ shadowRoot, fallbackRoot }}>
      {children}
    </ShadowRootContext.Provider>
  )
}

export const useShadowRootElement = (): HTMLElement | null => {
  const { shadowRoot, fallbackRoot } = useContext(ShadowRootContext)

  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!shadowRoot) {
      const element = fallbackRoot.createElement('section')
      setRootElement(element)
      return
    }
    const element = document.createElement('div')
    shadowRoot.appendChild(element)
    setRootElement(element)
  }, [shadowRoot, fallbackRoot])

  return rootElement
}
