import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import PublishBannerMenu from './PublishBannerMenu'
import { type BannerType } from 'utils/types'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { deleteBannerFromSlot, setBannerForSlotRemoval, slotsListSelector } from 'redux/slotsSlice'
import { Spinner } from 'components/Spinner'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, Row } from 'antd'
import { format, startOfDay, endOfDay, parseISO } from 'date-fns'
import { variantsForLocation } from 'services/banners.public'
import { TZDate } from '@date-fns/tz'
import { appConfig } from 'config'

interface Props {
  remoteBanner?: BannerType
  slotId: string
  openEditModal: () => void
  onSelectFromBannerList?: () => void
}

const EditBannerToolbar: React.FC<Props> = ({
  remoteBanner,
  slotId,
  openEditModal,
  onSelectFromBannerList
}) => {
  const toolbarRef = useRef<HTMLDivElement>(null)
  const [dropdownContainer, setDropdownContainer] = useState<HTMLDivElement | undefined>()
  const [scheduleVisible, setScheduleVisible] = useState(false)
  const dispatch = useDispatch()
  const slots = useSelector(slotsListSelector)
  const deleting = useMemo(() => {
    const slotIndex = slots.findIndex(({ id }) => id === slotId)
    const slot = slots.find(({ id }) => id === slotId)
    return (slotIndex !== -1 && slot?.banners?.[slotIndex]?.deleting) ?? false
  }, [slotId, slots])

  useEffect(() => {
    if (toolbarRef.current) {
      setDropdownContainer(toolbarRef.current)
    }
  }, [])

  const handleDelete = () => {
    const bannerReference = {
      id: remoteBanner?.id
    }
    if (remoteBanner?.draft) {
      dispatch(deleteBannerFromSlot({ bannerReference, slotId }))
    } else {
      dispatch(setBannerForSlotRemoval({ bannerReference, slotId }))
    }
  }

  const filteredVariants = remoteBanner?.variants ? variantsForLocation(remoteBanner?.variants, location.href) : []
  const [variant] = filteredVariants.length > 0 ? filteredVariants : remoteBanner?.variants ?? []
  const [schedule] = remoteBanner?.schedules && remoteBanner.schedules.length > 0
    ? remoteBanner.schedules.map((sched) => ({
      ...sched,
      startDate: typeof sched.startDate === 'string' ? parseISO(sched.startDate) : +sched.startDate,
      endDate: typeof sched.endDate === 'string' ? parseISO(sched.endDate) : +sched.endDate
    }))
    : [{
        slot: 1,
        startDate: startOfDay(Date.now()).valueOf(),
        endDate: endOfDay(Date.now()).valueOf()
      }]
  const locations = useMemo(() => {
    // our primary locations because web banner only added recently
    if (variant?.locations) {
      return variant.locations.join(', ')
    }
    // this is to support older banners that still use banner.locations or banner.location
    if (remoteBanner?.locations) {
      return remoteBanner?.locations.join(', ')
    }
    return remoteBanner?.location
  }, [remoteBanner?.location, remoteBanner?.locations, variant?.locations])

  if (!remoteBanner) return null

  return (
    <InnerToolbar ref={toolbarRef}>
      <Form layout='vertical' size='small'>
        <Row>
          <ToolBarSlot>{slotId}</ToolBarSlot>
          <PublishBannerMenu
            dropdownContainer={dropdownContainer}
            slotId={slotId}
            banner={remoteBanner}
            disabled={!remoteBanner}
          />
          <EditIcon
            title="Edit Banner"
            className="icon"
            width={18}
            fill="#737373"
            onClick={openEditModal}
          />
          {deleting
            ? (<Spinner size={18} />)
            : (<DeleteIcon className="icon" width={18} fill="#e63535" title="Remove Banner" onClick={handleDelete}/>)
          }
          <ScheduleToggle
            title="Toggle Schedule Visibility"
            className="icon"
            fill={scheduleVisible ? '#333' : '#737373'}
            onClick={() => { setScheduleVisible(!scheduleVisible) }}
          />
        </Row>
        <FormDivider />
        {scheduleVisible && (<Row>
          <FormEntry label="Schedule">
            <FullWidthInput readOnly value={`${format(new TZDate(+schedule.startDate, appConfig.ianaTimezone), 'MMM d HH:mm')}   –   ${format(new TZDate(+schedule.endDate, appConfig.ianaTimezone), 'MMM d HH:mm')}`} />
          </FormEntry>
        </Row>)}
        {locations && locations.length > 0 && (<Row>
          <FormEntry label="Link to">
            <FullWidthInput readOnly value={locations ?? ''} />
          </FormEntry>
        </Row>)}
        <Row>
          <FormEntry label="Banner ID">
            <FullWidthInput readOnly value={remoteBanner.id} />
          </FormEntry>
        </Row>
        <Row>
          <FormEntry label="Banner Name">
            <FullWidthInput readOnly value={remoteBanner.name} />
          </FormEntry>
        </Row>
        <Button type="primary" className="save-button" onClick={onSelectFromBannerList}>Select from Banner List</Button>
      </Form>
    </InnerToolbar>
  )
}

export default EditBannerToolbar

const FormDivider = styled(Divider)`
  margin: 0.5rem 0 0.75rem;
`
const FormEntry = styled(Form.Item)`
  margin-bottom: 0.5rem;
  width: 100%;

  label {
    color: #737373!important;
    font-size: 12px!important;
    line-height: 1.145;
  }
`
const FullWidthInput = styled(Input)`
  border-color: #d9d9d9;
  border-width: 0 0 1px;
  color: #171717;
  font-size: 14px;
  line-height: 1.145;
  width: 100%;

  &:focus {
    border-color: inherit;
    box-shadow: none;
  }
`
const ScheduleToggle = styled(InfoCircleOutlined)<{ fill: string }>`
  border-left: 1px solid #f2f2f2;
  margin-left: 10px;
  padding-left: 10px;
  
  svg {
    fill: ${p => p.fill};
    height: 20px;
    width: 20px;
  }
`
export const ToolBarSlot = styled.div`
  border-right: 1px solid #f2f2f2;
  padding: 5px 10px 0 0;
  margin-right: 10px;
  font-size: 14px;
  letter-spacing: -0.41px;
  text-transform: capitalize;
`
const InnerToolbar = styled.div`
  align-items: center;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  display: flex;
  left: 20px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  top: 20px;
  transition: opacity 150ms ease-in;
  z-index: 300;

  .publishOverlay {
    .ant-dropdown-menu {
      padding: 5px 0;
    }
  }

  .icon {
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 14px;
  }

  .save-button {
    background-color: #fff;
    border-color: #fa488f;
    color: #ed1e79;
    font-weight: 700;
    height: 30px;
    width: 100%;

    &:hover {
      background-color: rgba(237, 30, 121, 0.15);
      color: #d41b6c;
    }
  }
`
