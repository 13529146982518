import React, { useMemo, useState } from 'react'
import { Input } from 'antd'
import { type SearchProps } from 'antd/lib/input/Search'
import { Container, DropdownBody, DropdownContainer, DropdownItem } from './styles'
import { SearchOutlined } from '@ant-design/icons'

interface SuggestItem {
  id: string
  name: string
}

interface Props<ItemT> {
  suggestedItems?: ItemT[]
  onSelectSuggestItem?: (item: ItemT) => void
}

const SearchInputWithSuggest = <ItemT extends SuggestItem = SuggestItem>({
  suggestedItems,
  onChange,
  placeholder = 'Search',
  onSelectSuggestItem,
  ...rest
}: Props<ItemT> & Omit<SearchProps, 'onSearch'>) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');

  const suggestItemsData = useMemo(() => {
    return searchText.length > 0
      ? suggestedItems?.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
      : [];
  }, [suggestedItems, searchText])

  const onChangeSearchText = (value: string) => {
    setSearchText(value);

    if (!suggestedItems) {
      return
    }
    if (!!value !== isOpenDropdown) {
      setIsOpenDropdown(!!value);
    }
  }

  const onSelecteFromSuggest = (item: ItemT) => {
    onSelectSuggestItem?.(item);
    setSearchText('');
    setIsOpenDropdown(false);
  }

  return (
    <Container>
      <Input
        {...rest}
        placeholder={placeholder}
        allowClear
        value={searchText}
        prefix={<SearchOutlined />}
        onChange={(e) => {
          onChange?.(e);
          onChangeSearchText(e.target.value);
        }}
        className='input-search'
      />
      {!!suggestedItems?.length && (
        <DropdownContainer className={isOpenDropdown ? 'open' : ''}>
          <DropdownBody>
            {suggestItemsData?.map(item => {
              return (
                <DropdownItem
                  key={item.id}
                  onClick={() => { onSelecteFromSuggest(item); }}
                >
                  {item.name}
                </DropdownItem>
              );
            })}
          </DropdownBody>
        </DropdownContainer>
      )}
    </Container>
  )
}

export default SearchInputWithSuggest
