import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
  user?: any
  token?: string
  authenticated: boolean
}

export const initialState: AuthState = {
  token: undefined,
  authenticated: false
}

const NAME = 'auth'

const { actions, reducer } = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setUser (state, action: PayloadAction<{ user?: any, token?: string }>) {
      state.authenticated = true
      state.user = action.payload.user
      state.token = action.payload.token
    },
    clearUser (state, _action: PayloadAction) {
      state.authenticated = false
      state.user = undefined
      state.token = undefined
    }
  }
})

export const {
  setUser,
  clearUser
} = actions

export default reducer
