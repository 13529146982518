const URI_RE = /^(https?):(?:\/\/([^#/?]*))?([^#?]*)(?:\?([^#]*))?(?:#(.*))?/i
interface URI {
  url: string
  protocol?: string
  host?: string
  port?: string
  path?: string
  search?: string
  hash?: string
  origin?: string
}
const parsedURICache = new Map<string, URI | undefined>()
export const URI_PIECES = (url?: string): URI | undefined => {
  const urlToMatch = url ?? location.href

  // Check cache first
  const cached = parsedURICache.get(urlToMatch)
  if (cached) return cached

  const match = urlToMatch.match(URI_RE)
  if (!match) return undefined

  const [host, port] = (match[2] ?? '').split(':')
  const result = {
    url: urlToMatch,
    protocol: match[1],
    host,
    port,
    path: match[3],
    search: match[4] ? `?${match[4]}` : undefined,
    hash: match[5],
    origin: `${match[1]}://${match[2]}`
  }

  parsedURICache.set(urlToMatch, result)
  return result
}
const QP_RE = /[&?]([^&=?]+)=([^#&]+)?/g
export const QUERY_PARAMS = (uri: string) => {
  if (!uri) return {}

  const params: Record<string, string> = {}
  let match

  while ((match = QP_RE.exec(uri)) !== null) {
    params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
  }

  return params
}

export const PARAM_PREVIEW_MODE = 'preview_mode'
export const PARAM_ACCOUNT = 'account'
export const SFCC_PARAM_PREVIEW_ID = '__previewID'
export const SFCC_PARAM_SITE_DATE = '__siteDate'
export const SESSION_SFCC_SITE_DATE = 'BILDIT.previewDate'
export const BILDIT_DATA_ATTR = 'data-bildit-id'
export const PUBLIC_VIEW_SWITCH = 'BILDITForcePublic'
export const CURRENT_URL = window.location.origin + window.location.pathname

// dwsid session cookie controls override date, but is not readable by us
// <title> is set in document when in preview mode, can pull the assigned date back
export const dateFromTitle = (): string | null => {
  const matches = document.title.match(/^\((\d{2})\/(\d{2})\/(\d{4}) (\d{1,2}):(\d{2}) ([ap]m)\)/)
  if (!matches || matches.length !== 7) return null

  const [, month, day, year, hour, minute, period] = matches
  const hourNum = Number.parseInt(hour) + (period.toLowerCase() === 'pm' && Number.parseInt(hour) !== 12 ? 12 : 0)
  const paddedHour = hourNum.toString().padStart(2, '0')

  return `${year}${month}${day}${paddedHour}${minute}`
}

const URIRegexpMap = new Map<string, RegExp>()

// Pre-compile frequently used RegExp patterns
const SPECIAL_CHARS_RE = /([.+?^${}()|[\]\\])/g
const WILDCARD_RE = /\*/g

export const URIToRegex = (uri: string): RegExp => {
  const cachedRegex = URIRegexpMap.get(uri)
  if (cachedRegex) return cachedRegex

  // Check URI patterns
  const hasRootWildcard = uri.endsWith('/*')
  const hasEndingSlash = uri.endsWith('/')

  // Remove trailing characters if needed
  const baseUri = hasRootWildcard
    ? uri.slice(0, -2)
    : (hasEndingSlash
        ? uri.slice(0, -1)
        : uri)

  // Build regex pattern
  const escapedUri = baseUri
    .replaceAll(SPECIAL_CHARS_RE, String.raw`\$1`)
    .replaceAll(WILDCARD_RE, '(?:.*)?')

  // Add appropriate suffix
  const suffix = hasRootWildcard
    ? String.raw`\/?.*?`
    : (hasEndingSlash
        ? String.raw`\/?`
        : '')

  const finalRegex = new RegExp(`^${escapedUri}${suffix}$`, 'm')
  URIRegexpMap.set(uri, finalRegex)

  return finalRegex
}
