import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { bannersListStateSelector, updateBanners } from 'redux/bannersSlice'
import { organizeBannersIntoSlots, reorderBannersInSlot, setSlotDirtyOrder, slotsListSelector } from 'redux/slotsSlice'

const EditsSaveButtons: React.FC = () => {
  const { updating, banners } = useSelector(bannersListStateSelector)
  const slots = useSelector(slotsListSelector)
  const dirty = slots.some(({ dirty }: { dirty: boolean }) => dirty)
  const isDirtyOrder = slots.some(slot => slot.dirtyOrder)

  const dispatch = useDispatch()

  const onSave = () => {
    slots.forEach((slot) => {
      const { id: slotId, dirty, dirtyOrder, banners } = slot

      if (dirtyOrder) {
        dispatch(reorderBannersInSlot({ slotId, banners }))
        dispatch(setSlotDirtyOrder({ slotId, dirty: false }))
      }

      if (dirty) {
        dispatch(updateBanners())
      }
    })
  }

  if (!dirty && !isDirtyOrder) return null

  return (
    <EditingActions>
      <Button
        type="default"
        disabled={updating}
        className="cancel-button"
        onClick={() => {
          dispatch(organizeBannersIntoSlots({ banners }))
          // Reset the dirtyOrder flags for all slots
          slots.forEach((slot) => {
            if (slot.dirtyOrder) {
              dispatch(setSlotDirtyOrder({ slotId: slot.id, dirty: false }))
            }
          })
        }}
      >
        CANCEL
      </Button>
      <Button
        disabled={!dirty && !isDirtyOrder}
        className="save-button"
        loading={updating}
        onClick={onSave}
      >
        SAVE
      </Button>
    </EditingActions>
  )
}

export default EditsSaveButtons

const EditingActions = styled.div`
  display: flex;
  margin-right: 20px;

  .cancel-button,
  .save-button {
    border-radius: 0;
    color: #ed1e79;
    font-weight: 700;
    height: auto;
    min-width: 90px;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
  }

  .cancel-button {
    background-color: #fff;
    border: 1px solid #ed1e79;
    color: #ed1e79;
    margin-right: 20px;

    &:hover {
      border-color: #ed1e79;
      color: #ed1e79;
    }
  }

  .save-button {
    background-color: #ed1e79;
    border: 1px solid transparent;
    color: #fff;

    &:hover {
      border-color: transparent;
      color: #fff;
    }
  }
`
