// Import the functions you need from the SDKs you need
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './config'

const firebaseApp = initializeApp(firebaseConfig)
// ** You need to comment the below line if project' recapcha is not activated.
// firebase.appCheck().activate(compilepocSiteKey);
const fb = {
  auth: getAuth(firebaseApp),
  storage: getStorage(firebaseApp)

  // ** You need to comment the below line if project' recapcha is not activated.
  // appCheck: firebase.appCheck,
}

if (process.env.REACT_APP_USE_FIREBASE_EMULATOR === 'true') {
  connectAuthEmulator(fb.auth, 'http://localhost:9099')
  connectStorageEmulator(fb.storage, 'localhost', 9199)
}

export default fb
