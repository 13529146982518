import { InfoCircleOutlined } from '@ant-design/icons'
import { Input as BaseInput, type InputProps, type InputRef } from 'antd'
import omit from 'lodash/omit'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Tooltip from 'components/Tooltip'

export type TextInputProps = InputProps & {
  infoText?: string
  flat?: boolean
}

const TextInput = React.forwardRef((props: TextInputProps, ref: React.Ref<InputRef>) => {
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setContainerNode(containerRef.current)
  }, [])
  return (
    <Container ref={containerRef} flat={props.flat}>
      <Input
        suffix={
          <>
            {props.infoText
              ? (
                <Tooltip
                  placement="topRight"
                  content={props.infoText}
                  {...containerNode && { getPopupContainer: () => containerNode }}
                >
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
                )
              : null}
          </>
        }
        {...omit(props, ['flat', 'textArea', 'infoText'])}
        ref={ref}
      />
    </Container>
  )
})
TextInput.displayName = 'TextInput'

export default TextInput

const Container = styled.div<{ flat?: boolean }>`
  min-width: 0;

  .ant-input-affix-wrapper {
    border-radius: ${({ flat }) => (flat ? '0' : 'inherit')};
  }
`

const Input = styled(BaseInput)`
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
  input[type='datetime-local'] {
      width: 100%;
      max-width: 100%;
      min-width: 0;
      box-sizing: border-box;
  }
`
