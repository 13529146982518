import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CancelButtonContainer = styled.div`
  margin-right: 10px;
`

export const SearchContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;

  flex-direction: row;
  display: flex;
`

export const SortContainer = styled.div`
  width: 160px;
  margin-left: 10px;
`
