import { Alert } from 'antd'
import theme from 'config/theme'
import styled from 'styled-components'

export const BannerCount = styled.span`
  font-size: 12px; /* 2 points smaller than SlotNumber */
  position: relative;
  top: -1px;
`

export const CenteredAddBannerButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ProgressContainer = styled.div`
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SlotNumber = styled.span`
  background-color: #0559fd;
  color: white;
  font: 500 14px/16px ${theme.token?.fontFamily};
  max-height: 16px;
  padding: 3px 10px 0;
  position: absolute;
  text-transform: uppercase;
  transform: translate(-2px, calc(-100% - 2px));
  white-space: nowrap;
  z-index: 2;
`

export const SlotItemContainer = styled.div`
  clear: both;
  font-family: ${theme.token?.fontFamily};
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #0559fd;
  padding: 0;
  box-sizing: border-box; 
  background-color: white;
  width: 100%;
  position: relative;
`

export const DraggableItem = styled.div`
  position: relative;
  cursor: grab;
  width: 100%;
  box-sizing: border-box;
  
  > * {
    max-width: 100%;

    &:hover .component {
      filter: brightness(70%);
    }
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  &.drag-over {
    border: 2px dashed #50C12E;
    width: calc(100% - 1px);
  }

  &.dragging {
    cursor: move;
  }

  /* Light green overlay */
  &.drag-over::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(80, 193, 46, 0.3);
    pointer-events: none;
    z-index: 2;
  }

  &.drag-blocked {
    cursor: not-allowed;
    border: 3px dashed red;
    width: calc(100% - 1px);
  }

  /* Red overlay for invalid drag-over */
  &.drag-blocked::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 0, 0, 0.3); /* Light red */
    pointer-events: none;
    z-index: 2;
  }
`

export const AlertItem = styled(Alert)`
  padding: 25px 12px;
`
