import { useMemo } from 'react';
import { PARAM_ACCOUNT } from 'utils/urlParams';

export default function useHasAccount () {
  const queryParams = new URLSearchParams(window.location.search)
  const account = queryParams.get(PARAM_ACCOUNT)
  const hasAccount = useMemo(() => !!account, [account])

  return hasAccount
}
