import { type ThemeConfig } from 'antd'

const theme: ThemeConfig = {
  token: {
    borderRadius: 0,
    colorLink: '#ed1e79',
    colorLinkActive: '#b7175f',
    colorLinkHover: '#f15097',
    colorPrimary: '#ed1e79',
    controlHeight: 36,
    fontFamily: 'Avenir, "Segoe UI", sans-serif'
  },
  components: {
    Form: {
      itemMarginBottom: 20,
      labelHeight: 16,
      lineHeight: 1,
      verticalLabelPadding: '0 0 7px'
    },
    Input: {
      activeBorderColor: 'black',
      activeShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
      colorBorder: 'gray',
      colorPrimary: '#000',
      colorPrimaryBorderHover: '#000',
      colorPrimaryHover: '#000',
      hoverBorderColor: 'black',
    },
    Dropdown: {
      controlItemBgHover: '#faeeee',
    },
    DatePicker: {
      activeShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
      colorBorder: 'gray',
      colorPrimaryHover: '#000'
    }
  }
}

export default theme
