import React, { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react'
import type { ImageDimensionsNumeric } from 'utils/metadata'
import { debounce } from 'utils/debounce'

interface WindowSizeContextParams {
  height: number
  width: number
}

const initialSize = { width: window.innerWidth, height: window.innerHeight }
const WindowSizeContext = createContext<WindowSizeContextParams>(initialSize)

interface WindowSizeProviderProps {
  children: React.ReactNode
}

export const WindowSizeProvider: React.FC<WindowSizeProviderProps> = ({ children }: WindowSizeProviderProps) => {
  const [size, setSize] = useState<ImageDimensionsNumeric>(initialSize)

  const debouncedSetInvalidation = useMemo(
    () => debounce(() => {
      setSize((prevSize) => {
        const { height, width } = prevSize
        const { innerHeight, innerWidth } = window
        return width !== innerWidth || height !== innerHeight
          ? { width: innerWidth, height: innerHeight }
          : prevSize
      })
    }, 100),
    []
  )

  const handleResize = useCallback(() => debouncedSetInvalidation(), [debouncedSetInvalidation])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return <WindowSizeContext.Provider value={size}>{children}</WindowSizeContext.Provider>
}

export const useWindowSize = (): WindowSizeContextParams => {
  const context = useContext<WindowSizeContextParams>(WindowSizeContext)
  if (!context) {
    throw new Error('useProxyLocation must be used within a LocationProvider');
  }
  return context
}
