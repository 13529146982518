import { appConfig } from 'config';
import { useCallback, useEffect, useState } from 'react';

const readWindowValue = (selector: string): any => {
  return selector
    .split('.')
    .reduce((obj, key: string) => {
      const cleanKey = key.replace(/[[\]]/g, '')
      return obj?.[cleanKey]
    }, window)
}

export const mapCategoryConfigToFunction = (config?: string): string[] => {
  if (!config) return []

  return config
    .split(',')
    .filter((checkType) => checkType.length > 0)
    .map((checkType) => `get${checkType[0].toUpperCase()}${checkType.slice(1)}Category`)
}

export const getMetaCategory = (): string => {
  // pull cgid to match against if utag_data is not present
  if (appConfig.canonicalMetaSelector && appConfig.canonicalMetaSelector.length > 0 && appConfig.canonicalMetaRegex) {
    const metaUrlTag = document.querySelector(appConfig.canonicalMetaSelector)
    const metaMatches = metaUrlTag?.getAttribute('content')?.match(new RegExp(appConfig.canonicalMetaRegex))
    return metaMatches?.length === 2
      ? metaMatches[1]
      : ''
  }

  return ''
}

export const getBreadcrumbCategory = (): string => {
  if (appConfig.breadcrumbSelector && appConfig.breadcrumbSelector.length > 0) {
    const lastCrumb = document.querySelector(appConfig.breadcrumbSelector) as HTMLElement
    return lastCrumb?.dataset?.cgid ?? ''
  }

  return ''
}

export const getDemandwareContextCategory = (): string => {
  if (appConfig.demandwareContext && appConfig.demandwareContext.length > 0) {
    let foundCategory = false
    const nodeIterator = document.createNodeIterator(
      document.getElementById(appConfig.observerId) ?? document.body,
      NodeFilter.SHOW_COMMENT
    )

    const RE = new RegExp(appConfig.demandwareContext)
    while (nodeIterator.nextNode() && !foundCategory) {
      const commentNode = nodeIterator.referenceNode;
      const contextMatches = commentNode.textContent?.match(RE)
      if (contextMatches?.length === 2) {
        foundCategory = true
        return contextMatches[1]
      }
    }
  }

  return ''
}

export const getTealiumCategory = (): string => {
  // match against utag_data from Tealium (in page source, URL blocking has no impact)
  if (appConfig.tealiumCategorySelector && appConfig.tealiumCategorySelector.length > 0) {
    // Access nested window property using array of keys
    return readWindowValue(appConfig.tealiumCategorySelector)
  }

  return ''
}
export const getAjaxCategory = (): string => {
  let ajaxCatLookup = ''
  let ajaxCatPagedata = ''

  // pageData + backup DOM selector to pull current category for AJAX-ed search
  if (appConfig.ajaxPageDataSelector && appConfig.ajaxPageDataSelector.length > 0) {
    ajaxCatPagedata = readWindowValue(appConfig.ajaxPageDataSelector)
  }

  if (appConfig.ajaxCategorySelector && appConfig.ajaxCategorySelector.length > 0 && appConfig.ajaxCategoryAttribute && appConfig.ajaxCategoryAttribute.length > 0) {
    ajaxCatLookup = document.querySelector(appConfig.ajaxCategorySelector)?.getAttribute(appConfig.ajaxCategoryAttribute) ?? ''
  }

  return ajaxCatPagedata ?? ajaxCatLookup
}

const EMPTY_SET = new Set<string>()
if (window.BILDIT) window.BILDIT.categoryValidations = {}
const getPossibleCategoryMatches = (path?: string): Set<string> => {
  const VALID_SET = window.BILDIT?.categoryValidations
  if (!VALID_SET || Object.keys(VALID_SET).length === 0) return EMPTY_SET

  const ajaxCategory = VALID_SET.getAjaxCategory ? getAjaxCategory() : ''
  // if we have an ajax category update, it is the only correct option
  // tealium data may be updated dynamically, meta is 100% correct but possibly stale due to ajax updates
  // Use a ternary expression to simplify the logic
  return new Set(ajaxCategory.length > 0
    ? [ajaxCategory]
    : Object.keys(VALID_SET).map((key) => VALID_SET[key]?.(path) ?? '').filter((x) => !!x && x.length > 0))
}

const getCurrentCategory = (path?: string): string => {
  const catMatches = getPossibleCategoryMatches(path)
  if (catMatches.size === 0) return ''
  return Array.from(catMatches)[0]
}

const categoryFunctions: any = {
  getAjaxCategory,
  getTealiumCategory,
  getMetaCategory,
  getBreadcrumbCategory,
  getDemandwareContextCategory
}
export default function useCategory () {
  // one-time parse and verify the functions requested in config are valid
  if (Object.keys(window?.BILDIT?.categoryValidations)?.length === 0) {
    appConfig.categoryChecks?.forEach((fnName) => {
      if (window.BILDIT && typeof categoryFunctions[fnName] === 'function') {
        window.BILDIT.categoryValidations[fnName] = categoryFunctions[fnName]
      }
    })
  }

  return {
    ...categoryFunctions,
    getPossibleCategoryMatches,
    getCurrentCategory
  }
}

export const useAjaxCategory = (url: string) => {
  const [category, setCategory] = useState<string | null>(null)
  const updateCategory = useCallback(() => {
    const category = getCurrentCategory(url ?? location.href)
    if (category?.length > 0) setCategory(category)
  }, [url])
  const prefetchUpdateCategory = useCallback((e: MouseEvent) => {
    if (e.target instanceof Element && e.target.nodeName === 'A') {
      const cgid = e.target.getAttribute(appConfig.categoryAttribute)
      if (cgid) setCategory(cgid)
    }
  }, [])

  useEffect(() => {
    // one-time parse and verify the functions requested in config are valid
    if (Object.keys(window?.BILDIT?.categoryValidations)?.length === 0) {
      appConfig.categoryChecks?.forEach((fnName) => {
        if (window.BILDIT && typeof categoryFunctions[fnName] === 'function') {
          window.BILDIT.categoryValidations[fnName] = categoryFunctions[fnName]
        }
      })
    }

    if (appConfig.categoryAttribute) {
      document.body.addEventListener('click', prefetchUpdateCategory)

      return () => {
        document.body.removeEventListener('click', prefetchUpdateCategory)
      }
    }
  }, [prefetchUpdateCategory])

  useEffect(() => {
    if (appConfig.ajaxPageDataReadyEvent) {
      const ajaxDataEvent = appConfig.ajaxPageDataReadyEvent
      document.addEventListener(ajaxDataEvent, updateCategory)

      return () => {
        document.removeEventListener(ajaxDataEvent, updateCategory)
      }
    }
  }, [updateCategory])

  return category ?? getCurrentCategory(url)
}
