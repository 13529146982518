import styled from 'styled-components'

export const Container = styled.div`
  .input-search {
    width: 250px;
  }
`;

export const DropdownContainer = styled.div`
  width: 250px;
  background: #FFF;
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.15);
  
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &.open {
    max-height: 200px;
    opacity: 1;
  }
`;

export const DropdownBody = styled.div`
  padding: 10px;
  max-height: 170px;
  overflow-y: scroll;
`

export const DropdownItem = styled.div`
  padding: 8px 0;
`
