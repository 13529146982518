import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { appConfig } from 'config';
import type { BannerTypeRender } from 'utils/types';
import { type BannerRemoteSchemeCommon } from 'utils/adapters';
import { mapPublicBanners } from 'utils/mapPublicBanners';

const BANNERS_PUBLIC_RESUCE_PATH = 'bannersPublic';
const BANNERS_PUBLIC_TAG = 'BANNERS_PUBLIC_TAG';
const apiEndpoints = {
  getPublicBanners: `remote-banners_v1_3?device=web&key=${appConfig.fbAppApiKey}`,
  remoteCodelib: `remote-baseCodelib?key=${appConfig.fbAppApiKey}`
};

const mapBannersData = (
  response: BannerRemoteSchemeCommon[]
): BannerTypeRender[] => {
  return mapPublicBanners(response);
};

export const bannersPublicApi = createApi({
  reducerPath: BANNERS_PUBLIC_RESUCE_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.publicBaseUrl
  }),
  tagTypes: [BANNERS_PUBLIC_TAG],
  keepUnusedDataFor: 3600,
  endpoints: (builder) => ({
    fetchPublicBanners: builder.query({
      query: () => apiEndpoints.getPublicBanners,
      transformResponse: mapBannersData
    }),
    fetchAppetizePublicBanners: builder.query({
      query: params => ({
        url: `${appConfig.baseUrl}/appetizeio-banners_v1_2`,
        params: {
          ...params,
          device: 'web',
          key: appConfig.fbAppApiKey
        }
      }),
      transformResponse: mapBannersData
    }),
    getRemoteCodelib: builder.query({
      query: () => apiEndpoints.remoteCodelib,
      transformResponse: (response: string) => response
    })
  })
});

export const {
  usePrefetch,
  useLazyFetchPublicBannersQuery,
  useFetchPublicBannersQuery,
  useFetchAppetizePublicBannersQuery,
  useLazyFetchAppetizePublicBannersQuery,
  useLazyGetRemoteCodelibQuery,
  useGetRemoteCodelibQuery
} = bannersPublicApi;
