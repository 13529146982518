import React, { useCallback, useContext } from 'react'
import handyMan from 'assets/handyman.png'
import EditsSaveButtons from 'components/EditsSaveButtons'
import { LogoutOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { organizeBannersIntoSlots, resetSlotsDraftBanners, slotsListStateSelector } from 'redux/slotsSlice'
import DatePicker from 'components/DatePicker'
import { bannersListStateSelector } from 'redux/bannersSlice'
import { AuthContext } from 'contexts/auth'
import { useAuth0 } from '@auth0/auth0-react'
import { EditIcon, BannerEditor, PickerContainer, UrlBar, UrlBarInput, UrlBarLabel, Text, Divider, Header, Home } from './styles'
import theme from 'config/theme'
import { usePreview } from 'contexts/preview'
import PreviewSelector from '../PreviewSelector';
const { confirm } = Modal

// the bar covers important parts of UI and the bar doesn't have purpose at the moment
// so it is disabled for now
const URL_BAR_ENABLED = false

interface TransitoryBanner {
  lossy: boolean
  message: string
  id?: string
  name: string
  slotId: string
}

interface LogoutConfirmationDialogContentProps {
  ephemeralBanners: TransitoryBanner[]
}

const LogoutConfirmationDialogContent: React.FC<LogoutConfirmationDialogContentProps> = ({ ephemeralBanners }) => {
  if (!ephemeralBanners.length) return null
  return (
    <>
      <p>Logging out will clear any unsaved or unpublished banners from your session:</p>
      <p><strong>Changes to bold items will be lost</strong></p>
      <ul style={{ marginTop: '1rem' }}>
        {ephemeralBanners.map((x) => (
          <li key={`${x.slotId}-${x.id}`}>
            <span style={{ fontWeight: x.lossy ? 700 : 400 }}>&quot;{x.name}&quot;<small>{x.id}</small> in slot <em>{x.slotId}</em>{x.message}</span>
          </li>
        ))}
      </ul>
    </>
  )
}

const Toolbar: React.FC = () => {
  const dispatch = useDispatch()
  const { logout } = useAuth0()
  const { authenticated } = useContext(AuthContext)
  const { previewDate, setPreviewDate } = usePreview()
  const { slots } = useSelector(slotsListStateSelector)
  const { banners } = useSelector(bannersListStateSelector)
  const url = new URL(window.location.href)
  const handleUrlBarSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    const urlPrefixInput = form.elements.namedItem('urlPrefix') as HTMLInputElement
    const path = url.pathname.slice(1)
    const newUrl = `${url.origin}${path ? `/${path}` : ''}/${cleanPath(urlPrefixInput?.value)}${url.search}`

    window.location.href = newUrl
  }
  const cleanPath = useCallback((pathname: string) => {
    const charArray = pathname.split('')
    while (charArray.length > 0 && charArray.at(-1) === '/') {
      charArray.pop()
    }
    return charArray.join('')
  }, [])

  const handleLogout = async () => {
    dispatch(resetSlotsDraftBanners())
    dispatch(organizeBannersIntoSlots({ banners: [] }))
    await logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const showDeleteConfirm = () => {
    const ephemeralBanners: TransitoryBanner[] = []
    slots.forEach((slot) => {
      slot.banners.forEach((banner) => {
        const msg = {
          id: banner.id ? `(${banner.id})` : '',
          lossy: false,
          message: '',
          name: banner.name,
          slotId: slot.id
        }
        if (banner.dirty) {
          ephemeralBanners.push({
            ...msg,
            lossy: true,
            message: ' is not saved'
          })
        } else if (!banner.id || !banners.some((b) => b.id === banner?.id)) {
          ephemeralBanners.push({
            ...msg,
            message: ' is no longer scheduled or removed'
          })
        }
      })
    })

    confirm({
      title: 'Confirm logout',
      icon: <ExclamationCircleFilled />,
      content: <LogoutConfirmationDialogContent ephemeralBanners={ephemeralBanners} />,
      okButtonProps: {
        type: 'primary',
        danger: true,
        style: {
          fontFamily: theme.token?.fontFamily
        }
      },
      okText: 'Logout',
      cancelButtonProps: {
        type: 'default',
        style: {
          fontFamily: theme.token?.fontFamily
        }
      },
      cancelText: 'Cancel',
      onOk: handleLogout,
      onCancel: () => {},
      style: {
        fontFamily: theme.token?.fontFamily
      }
    })
  }

  return (
    <>
      <Header>
        <BannerEditor>
          <EditIcon src={handyMan} />
          <Text style={{ userSelect: 'none' }}>BANNER EDITOR</Text>
        </BannerEditor>
        <Divider />
        <Home />
        <PickerContainer>
          <DatePicker
            showTime
            value={previewDate}
            placement="bottomLeft"
            dateFormat="E, LLL d y"
            onChange={setPreviewDate}
          />
        </PickerContainer>
        <PreviewSelector />
        <EditsSaveButtons />
        {authenticated && (
          <Button
            onClick={showDeleteConfirm}
            type="default"
            shape="circle"
            icon={<LogoutOutlined />}
          />
        )}
      </Header>
      {URL_BAR_ENABLED && (
        <UrlBar onSubmit={handleUrlBarSubmit}>
          <UrlBarLabel>URL</UrlBarLabel>
          <UrlBarInput>
            <div>{url.origin}{cleanPath(url.pathname)}/</div>
            <input type="text" name="urlPrefix" />
          </UrlBarInput>
        </UrlBar>
      )}
    </>
  )
}

export default Toolbar
