import styled from 'styled-components';

interface ColorPickerWrapperProps {
  isOpen: boolean
}

interface ToggleProps {
  value: string
}

export const ColorPickerWrapper = styled.div<ColorPickerWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input[type='color'] {
    appearance: none;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    height: 40px;
    width: 100%;
    padding: 0.5rem;
    cursor: pointer;

    &:focus {
      border-color: #ef4444;
      outline: none;
      box-shadow: 0 0 0 3px rgba(248, 113, 113, 0.3);
    }
  }
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Toggle = styled.div<ToggleProps>`
    width: 30px;
    height: 30px;
    background-color: ${({ value }) => (value)};
    border-radius: 100%;
    margin-right: 10px;
    border: 2px solid #A6A6A6
`

export const Palette = styled.div`
  position: absolute;
  top: 50px;
  z-index: 10;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const Label = styled.label`
  font-size: 0.875rem;
  color: black;
  margin-bottom: 0.5rem;
`;

export const ErrorMessage = styled.p`
  font-size: 0.75rem;
  color: red;
  margin-top: 0.25rem;
`;
