import React, { useState, useRef, type PropsWithChildren } from 'react'
import { TooltipBox, TooltipContainer } from './styles'

interface TooltipProps extends PropsWithChildren {
  content: React.ReactNode
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  placement = 'bottomLeft'
}) => {
  const [visible, setVisible] = useState(false)
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const showTooltip = () => { setVisible(true) }
  const hideTooltip = () => { setVisible(false) }

  return (
    <TooltipContainer
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      <TooltipBox
        ref={tooltipRef}
        placement={placement}
        className={visible ? 'visible' : ''}
      >
        {content}
      </TooltipBox>
    </TooltipContainer>
  )
}

export default Tooltip
