import React, { createContext, useContext, useEffect, useState, type ReactNode } from 'react';
import { format } from 'date-fns';
import { PARAM_PREVIEW_MODE, PUBLIC_VIEW_SWITCH, SESSION_SFCC_SITE_DATE, SFCC_PARAM_PREVIEW_ID } from '../utils/urlParams';
import { DEFAULT_TIME_ZONE, parseDateString } from 'utils/date';
import { setCookie, clearCookie, getCookieSync } from 'utils/cookies';
import { TZDate } from '@date-fns/tz';

export type PreviewSize = 'mobile' | 'tablet' | 'desktop' | null;
interface PreviewContextType {
  previewSize: PreviewSize
  isPreviewEnabled: boolean
  isPublicMode: boolean
  setPreviewSize: (size: PreviewSize) => void
  togglePreview: () => void
  togglePublicMode: () => void
  previewDate: Date | null
  setPreviewDate: (date: string | null) => void
}

// Create the context
const PreviewContext = createContext<PreviewContextType | undefined>(undefined);

interface PreviewProviderProps {
  children: ReactNode
}

export const PreviewProvider: React.FC<PreviewProviderProps> = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const publicMode = queryParams.get(PUBLIC_VIEW_SWITCH);
  const previewID = queryParams.get(SFCC_PARAM_PREVIEW_ID);
  const previewMode = queryParams.get(PARAM_PREVIEW_MODE);
  const [newURL, setNewURL] = useState<string>()

  const [previewSize, setPreviewSizeState] = useState<PreviewSize>('desktop');
  const [isPreviewEnabled, setIsPreviewEnabled] = useState<boolean>(() => {
    if (previewID?.length) return true;
    return !!previewMode || !!previewID;
  });
  const [isPublicMode, setIsPublicMode] = useState<boolean>(() => publicMode === 'true');
  const [previewDate, setPreviewDateState] = useState<Date | null>(() => {
    const cookieDate = getCookieSync(SESSION_SFCC_SITE_DATE);
    if (cookieDate) {
      return parseDateString(cookieDate);
    }

    const url = new URL(window.location.href);
    const dateParam = url.searchParams.get(SESSION_SFCC_SITE_DATE);

    if (dateParam) {
      return parseDateString(dateParam);
    }

    return null;
  });

  const setPreviewDate = (date: string | null) => {
    const url = new URL(window.location.href);
    if (!date) {
      void clearCookie(SESSION_SFCC_SITE_DATE)
      url.searchParams.delete(SESSION_SFCC_SITE_DATE)
    } else {
      const parsedDate = new TZDate(date, DEFAULT_TIME_ZONE ?? 'America/New_York')
      setPreviewDateState(parsedDate);

      const formattedDate = format(parsedDate, 'yyyyMMddHHmm');
      void setCookie(SESSION_SFCC_SITE_DATE, formattedDate, 1 / 24, { secure: true, sameSite: 'None' })
      url.searchParams.set(SESSION_SFCC_SITE_DATE, formattedDate)
    }
    setNewURL(url.toString())
  };

  const togglePreview = () => {
    setIsPreviewEnabled(!isPreviewEnabled);
  };

  const togglePublicMode = () => {
    setIsPublicMode(!publicMode);
    if (publicMode) {
      setPreviewSize('desktop');
      setNewURL(location.href.replace(/[&?]BILDITForcePublic=true/g, ''));
    } else {
      setNewURL(location.href + `${location.search.startsWith('?') ? '&' : '?'}${PUBLIC_VIEW_SWITCH}=true`);
    }
  };

  useEffect(() => {
    if (newURL) location.href = newURL
  }, [newURL])

  const resizerWidth = {
    mobile: 375,
    tablet: 768,
    desktop: screen.availWidth
  };

  const setPreviewSize = (size: PreviewSize) => {
    if (size === 'desktop') {
      setPreviewSizeState('desktop');
      return;
    }

    const nextUrl = new URL(window.location.href);

    if (previewDate) {
      const formattedDate = format(previewDate, 'yyyyMMddHHmm');
      nextUrl.searchParams.set(SESSION_SFCC_SITE_DATE, formattedDate);
    }

    const width = size === 'mobile' ? resizerWidth.mobile : resizerWidth.tablet;
    nextUrl.searchParams.set(PARAM_PREVIEW_MODE, 'true');
    window.open(
      nextUrl.toString(),
      '_blank',
      `location=yes,height=${screen.availHeight},width=${width},scrollbars=yes,status=yes`
    );
    setPreviewSizeState(size);
  };

  return (
    <PreviewContext.Provider
      value={{
        previewSize,
        isPublicMode,
        isPreviewEnabled,
        setPreviewSize,
        togglePreview,
        togglePublicMode,
        previewDate,
        setPreviewDate
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreview = (): PreviewContextType => {
  const context = useContext(PreviewContext);
  if (!context) {
    throw new Error('usePreview must be used within a PreviewProvider');
  }
  return context;
}
