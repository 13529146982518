import React, { useEffect, useMemo, useRef, useState } from 'react'

import { TEMP_ID, type BannerType } from 'utils/types'
import EditBanner from 'components/EditBanner'
import CreateBanner, { copyBanners } from 'components/CreateBanner'
import AddBannerButton from 'components/EditingMenu/AddBannerButton'
import BannerFormModal from 'components/BannerFormModal'
import BannerListModal from 'components/BannerListModal'

import {
  BannerCount,
  CenteredAddBannerButton,
  ProgressContainer,
  SlotNumber,
  SlotItemContainer,
  DraggableItem
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  addBannerToSlot,
  setSlotDirtyOrder,
  setSlotLoading,
  updateSlotBanners
} from 'redux/slotsSlice'
import CreateMenu from 'components/CreateMenu'
import { CreateMenuType } from 'components/CreateMenu/CreateMenu'
import CodeLibraryModal from 'components/CodeLibraryModal'
import { Spinner } from 'components/Spinner'
import { getDefaultSchedules, isCurrentDateWithinRange } from 'utils/date'
import { DEFAULT_SCREENS, updateBannerLocation } from 'utils/adapters'
import { v4 } from 'uuid'
import { parseMetadata, replaceTemplateData } from 'utils/metadata'
import { bannersListStateSelector, bannersListSelector, transpileCode } from 'redux/bannersSlice'
import { validateMetadata } from 'components/BannerFormModal/BannerFormModal'
import useSiteDate from 'hooks/useSiteDate'

interface SlotItemProps {
  banners: BannerType[]
  isListLoading: boolean
  slotId: string
}

const SlotItem: React.FC<SlotItemProps> = ({ banners, slotId, isListLoading }) => {
  const dispatch = useDispatch()
  const [showMenu, setShowMenu] = useState(false)
  const timerRef = useRef<NodeJS.Timeout>()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showLibraryModal, setShowLibraryModal] = useState(false)
  const [showListModal, setShowListModal] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const remoteBanners = useSelector(bannersListSelector)
  const { defaultTemplate: template } = useSelector(bannersListStateSelector)
  const providedDate = useSiteDate()

  const [localBanners, setLocalBanners] = useState<BannerType[]>(banners)
  useEffect(() => {
    setLocalBanners(providedDate
      ? banners.filter(
        (banner) => banner.schedules.some((schedule) => providedDate ? isCurrentDateWithinRange(schedule, providedDate) : true)
      )
      : banners
    )
  }, [banners, providedDate])

  const bannerCount = useMemo(() => {
    return localBanners.length;
  }, [localBanners]);

  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null)
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null)
  const [draggingData, setDraggingData] = useState<{ index: number, slotId: string } | null>(
    null
  )

  const handleShowListModal = (show: boolean) => {
    setShowListModal(show)
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    if (localBanners.length <= 1) return

    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/plain', JSON.stringify({ index, slotId }))
    setDraggingIndex(index)
    setDraggingData({ index, slotId })
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.preventDefault()

    if (!draggingData) return

    const { slotId: sourceSlotId } = draggingData
    setDragOverIndex(index)

    if (sourceSlotId !== slotId) {
      e.dataTransfer.dropEffect = 'none'
    } else {
      e.dataTransfer.dropEffect = 'move'
    }
  }

  const handleDragLeave = () => {
    setDragOverIndex(null)
  }

  const handleDragEnd = () => {
    setDragOverIndex(null)
    setDraggingIndex(null)
    setDraggingData(null)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
    e.preventDefault()

    if (!draggingData) return

    const { index: dragIndex, slotId: sourceSlotId } = draggingData

    setDragOverIndex(null)
    setDraggingIndex(null)
    setDraggingData(null)

    if (sourceSlotId !== slotId) return

    if (dragIndex === dropIndex) return

    const reorderedBanners = Array.from(localBanners)
    const [removed] = reorderedBanners.splice(dragIndex, 1)
    reorderedBanners.splice(dropIndex, 0, removed)

    // Update bannerSortOrder in local banners
    const updatedBanners = reorderedBanners.map((banner, index) => {
      return {
        ...banner,
        bannerSortOrder: {
          ...banner.bannerSortOrder,
          [slotId]: index
        }
      }
    })

    setLocalBanners(updatedBanners)

    // Dispatch action to update Redux store for saving reorder
    dispatch(updateSlotBanners({ slotId, banners: updatedBanners }))

    // mark reordered slot as dirty
    dispatch(setSlotDirtyOrder({ slotId, dirty: true }))
  }

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    setShowMenu(true)
  }

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setShowMenu(false)
    }, 80)
  }

  const handleShowLibraryModal = (show: boolean) => {
    setShowLibraryModal(show)
  }

  if (localBanners.length && bannerCount) {
    return (
      <SlotItemContainer>
        <SlotNumber>
          {slotId} <BannerCount>({bannerCount})</BannerCount>
        </SlotNumber>
        <div>
          {localBanners.map((banner, index) => (
            <DraggableItem
              key={banner.id}
              draggable={localBanners.length > 1}
              onDragStart={(e) => {
                handleDragStart(e, index)
              }}
              onDragOver={(e) => {
                handleDragOver(e, index)
              }}
              onDragLeave={handleDragLeave}
              onDrop={(e) => {
                handleDrop(e, index)
              }}
              onDragEnd={handleDragEnd}
              className={`
                ${dragOverIndex === index ? 'drag-over' : ''}
                ${draggingIndex === index ? 'dragging' : ''}
              `}
            >
              <EditBanner
                key={banner.id}
                banner={banner}
                slotId={slotId}
                stale={!remoteBanners?.find((b) => b.id === banner.id)}
              />
            </DraggableItem>
          ))}
        </div>

        <CenteredAddBannerButton>
          <AddBannerButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </CenteredAddBannerButton>

        <BannerFormModal
          open={showCreateModal}
          slotId={slotId}
          onCancel={() => { setShowCreateModal(false) }}
          onSubmit={(values) => {
            const { id: bannerId, variants } = values
            const variant = variants[0] ?? {}
            const { code, codeType, metadata } = variant

            const isValid = validateMetadata(metadata)
            if (!isValid) return

            setShowCreateModal(false)
            if (code && codeType) {
              dispatch(setSlotLoading({ slotId, bannerId }))
              const { code: tmpCode } = values.variants
              dispatch(transpileCode({
                bannerId,
                code: replaceTemplateData(code, metadata),
                codeType: codeType ?? 'jsx',
                onSuccess: (code) => {
                  // values from form would only have raw code
                  // don't wipe out object and break banner display until user saves
                  values.variants[0].code = {
                    raw: tmpCode ?? template?.raw,
                    compiled: code
                  }
                  dispatch(addBannerToSlot({ slotId, banner: values }))
                },
                onFailure: (err) => {
                  console.error(err)
                  dispatch(setSlotLoading({ slotId, bannerId }))
                }
              }))
            } else {
              dispatch(addBannerToSlot({ slotId, banner: values }))
            }
          }}
          onClickSelectFromList={() => { handleShowLibraryModal(true) }}
        />

        {showMenu && (
          <CreateMenu
            ref={menuRef}
            type={CreateMenuType.RIGHT}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onCreateNew={() => {
              setShowCreateModal(true)
            }}
            onSelectFromBannerList={() => {
              handleShowListModal(true)
            }}
          />
        )}

        {showLibraryModal && (
          <CodeLibraryModal
            open
            onConfirm={(snippet) => {
              const banner: BannerType = {
                name: `${snippet.name} Copy`,
                id: TEMP_ID(),
                key: undefined,
                webSlots: [slotId],
                variants: [
                  {
                    id: v4(),
                    categories: [],
                    code: snippet.code,
                    codeType: snippet.codeType,
                    metadata: snippet.metadata?.length ? snippet.metadata : parseMetadata(snippet.code?.raw ?? ''),
                    locations: [window.location.pathname],
                    templateName: snippet.name
                  }
                ],
                schedules: [
                  {
                    ...getDefaultSchedules(),
                    slot: 1
                  }
                ],
                screenTypes: DEFAULT_SCREENS,
                locations: [window.location.pathname],
                stale: false,
                dirty: true,
                published: false
              }
              dispatch(addBannerToSlot({ slotId, banner }))
              handleShowLibraryModal(false)
              setShowCreateModal(false)
            }}
            onClose={() => {
              handleShowLibraryModal(false)
            }}
          />
        )}

        {showListModal && (
          <BannerListModal
            open
            onConfirm={(banners: BannerType[]) => {
              copyBanners(banners, slotId).map((banner) =>
                dispatch(addBannerToSlot({
                  slotId,
                  banner: updateBannerLocation(banner, slotId)
                })))
              handleShowListModal(false)
            }}
            onClose={() => {
              handleShowListModal(false)
            }}
          />
        )}
      </SlotItemContainer>
    )
  }

  if (isListLoading) {
    return (
      <ProgressContainer>
        <Spinner size={18} />
      </ProgressContainer>
    )
  }

  return (
    <SlotItemContainer>
      <SlotNumber>{slotId}</SlotNumber>
      <CreateBanner slotId={slotId} />
    </SlotItemContainer>
  )
}

export default SlotItem
