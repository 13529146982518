import theme from 'config/theme'
import type { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface DatePickerPanelContainerProps extends PropsWithChildren {
  placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  open: boolean
  showTime: boolean
}
export const DatePickerPanelContainer = styled.div<DatePickerPanelContainerProps>`
  position: absolute;
  top: ${({ placement }) => {
    switch (placement) {
      case 'bottomLeft':
      case 'bottomRight':
        return '100%'
      case 'topLeft':
      case 'topRight':
        return 'auto'
      default:
        return 'auto'
    }
  }};
  bottom: ${({ placement }) => {
    switch (placement) {
      case 'bottomLeft':
      case 'bottomRight':
        return 'auto'
      case 'topLeft':
      case 'topRight':
        return '100%'
      default:
        return 'auto'
    }
  }};
  left: ${({ placement }) => {
    switch (placement) {
      case 'bottomLeft':
      case 'topLeft':
        return '0'
      case 'bottomRight':
      case 'topRight':
        return 'auto'
      default:
        return '0'
    }
  }};
  right: ${({ placement }) => {
    switch (placement) {
      case 'bottomLeft':
      case 'topLeft':
        return 'auto'
      case 'bottomRight':
      case 'topRight':
        return '0'
      default:
        return 'auto'
    }
  }};
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: ${({ placement }) =>
    placement.startsWith('top') ? '-10px' : '0'};
  font-size: 14px;
  font-family: ${theme.token?.fontFamily};
`

export const DatePickerPanel = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const DatePickerDatePanel = styled.div``
export const DatePickerTimePanel = styled.div``

export const DatePickerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .ant-btn-primary,
  .ant-btn-primary:active,
  .ant-btn-primary:hover {
    background: #ed1e79!important;
  }
`

export const WeekdaysContainer = styled.div`
  margin-top: 20px;
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  color: #737373;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  align-items: center;
`

export const CalendarBody = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 0 16px;
`

interface TimePickerProps {
  showTime: boolean
}
export const TimePicker = styled.div<TimePickerProps>`
  display: ${({ showTime }) => (showTime ? 'block' : 'none')};
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 22px 17px 0px 10px;
`

export const Header = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`

export const DatePickerAction = styled.div`
  width: 24;
  height: 24;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HeaderMonth = styled.span`
  flex: 1;
  color: #171717;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
`

interface DayProps {
  isSelected: boolean
  isToday: boolean
  currentMonth: boolean
  disabled: boolean
}
export const Day = styled.div<DayProps>`
  display: inline-block;
  width: 25px;
  text-align: center;
  line-height: 17px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background: ${({ isSelected }) => (isSelected ? '#ed1e79' : '#FAEEEE')};
  }
  background: ${({ isSelected, isToday }) => (isSelected ? '#ed1e79' : isToday ? 'rgba(237, 30, 121, 0.1)' : 'transparent')};
  color: ${({ currentMonth, isSelected, disabled }) => (isSelected ? '#fff' : disabled ? '#D9D9D9' : currentMonth ? '#000' : '#ccc')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
`

export const TimePickerHeader = styled.div`
  color: rgba(0, 0, 0, .85);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TimePickerParamsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const TimeParametrValue = styled.span`
  margin: 0 17px;
  color: #737373;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
`

export const TimePickerHeaderValue = styled.div`
  flex: 1;
  font-size: smaller;
  font-weight: 400;
  letter-spacing: -0.25.px;
  line-height: 20px;
  white-space: nowrap;
`

export const TimePickerContent = styled.div`
  display: flex;
  max-height: 165px;
  margin: 0;
`

export const TimePanelColumn = styled.div`
  flex-direction: column;
  max-height: fit-parent;
  overflow-y: hidden;
  text-align: left;
  scrollbar-width: thin;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &:hover {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

interface TimeProps {
  isSelected: boolean
}
export const TimePanelCell = styled.div<TimeProps>`
  margin: 0 10px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  padding: 5px;
  cursor: pointer;
  background: ${({ isSelected }) => (isSelected ? '#ed1e79' : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? '#fff' : 'inherit')};
  &:hover {
    background: ${({ isSelected }) => (isSelected ? '#ed1e79' : '#FAEEEE')};
  }
`

export const VerticalDevider = styled.div`
  width: 1px;
  height: 12px;
  background-color: #737373;
  background: #737373;
`
