import { type CodeSnippetMinimized, type CodeSnippet } from 'services/codeLibrary.d';

export enum CodeLibrarySortOption {
  AZ = 'a-z',
  ZA = 'z-a',
  NEWEST_FISRT = 'newest-first',
  OLDEST_FIRST = 'oldest-first',
  RESENTLY_UPDATED = 'recently-updated',
}

export const CODE_LIBRARY_SORT_OPTIONS: Array<{
  label: string
  value: CodeLibrarySortOption
}> = [
  {
    label: 'A - Z',
    value: CodeLibrarySortOption.AZ
  },
  {
    label: 'Z - A',
    value: CodeLibrarySortOption.ZA
  },
  {
    label: 'Newest first',
    value: CodeLibrarySortOption.NEWEST_FISRT
  },
  {
    label: 'Oldest first',
    value: CodeLibrarySortOption.OLDEST_FIRST
  },
  {
    label: 'Recently updated',
    value: CodeLibrarySortOption.RESENTLY_UPDATED
  }
];

export const sortingCodeLibraryData = (data?: CodeSnippet[], sortingBy?: CodeLibrarySortOption): CodeSnippet[] => {
  if (!data?.length) {
    return [];
  }

  const copyData = [...data];

  switch (sortingBy) {
    case CodeLibrarySortOption.AZ: {
      return copyData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    case CodeLibrarySortOption.ZA: {
      return copyData.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
    }
    case CodeLibrarySortOption.NEWEST_FISRT: {
      return copyData.sort((a, b) => b.createdAt - a.createdAt);
    }
    case CodeLibrarySortOption.OLDEST_FIRST: {
      return copyData.sort((a, b) => a.updatedAt - b.updatedAt);
    }
    case CodeLibrarySortOption.RESENTLY_UPDATED: {
      return copyData.sort((a, b) => b.updatedAt - a.updatedAt);
    }
    default: {
      return data;
    }
  }
}

export const minimizeDataForSearch = (data: CodeSnippet[], tabKey: string): CodeSnippetMinimized[] => {
  return data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      tabKey
    };
  });
};
