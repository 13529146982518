import styled from 'styled-components'
import crossImage from 'assets/backcross.webp'
import theme from 'config/theme'

export const PickerContainer = styled.div`
  align-self: center;
  display: flex;
  margin-right: 12px;
  width: 200px;
`
export const UrlBar = styled.form`
  display: flex;
  align-items: center;
  background: #FAFAFA;
  min-height: 63px;
  padding: 5px 30px;
  box-sizing: border-box;
  font-family: ${theme.token?.fontFamily};
`

export const UrlBarLabel = styled.div`
  color: #171717;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
`

export const UrlBarInput = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-left: 20px;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 10px 45px;
  width: 100%;
  box-sizing: border-box;

  > div,
  > input {
    color: #404040;
    border: 0;
    font: 400 20px/normal ${theme.token?.fontFamily};
    margin: 0;
    padding: 0;
  }

  > input {
    width: 100%;
  }

  > input:focus {
    outline: none;
  }
`

export const EditIcon = styled.img`
  height: 24px;
  width: 24px;
`

export const Home = styled.div`
  flex: 1;
  margin-left: 20px;
`

export const Divider = styled.div`
  background-color: #A6A6A6;
  height: 50%;
  margin-left: 20px;
  width: 1px;
`
export const Text = styled.div`
  color: white;
  font-size: 24px;
  margin-left: 10px;
`

export const BannerEditor = styled.div`
  align-items: center;
  display: flex;
  padding: 10px 30px;
`

export const Header = styled.div`
  align-items: center;
  background: grey url(${crossImage}) 0 0/cover;
  font-family: ${theme.token?.fontFamily};
  display: flex;
  height: 80px;
  padding-right: 20px;
`
