import React, { useRef, type FC } from 'react';

import AdaptiveModal from 'components/AdaptiveModal';
import CodePanel from 'components/CodePanel';
import { Button } from 'antd';
import theme from 'config/theme'

interface BannerFormProps {
  open: boolean
  rawCode: string
  codeType?: string
  onCancel: () => void
  onSave: (code: string) => void
}

const ViewCodeModal: FC<BannerFormProps> = ({ open, rawCode, codeType, onCancel, onSave }) => {
  const currentCode = useRef<string>('')
  const controlHeight = theme.token?.controlHeight ? theme.token?.controlHeight : 0
  const handleSave = () => {
    onSave(currentCode.current)
  }

  return (
    <AdaptiveModal centered width={815} height={715} openInNewWindow onClose={onCancel} open={open}>
      <CodePanel style={{ height: `calc(-${codeType === 'html' ? 50 + controlHeight : 40}px + 100%)`, margin: 0, maxHeight: 'none' }} value={rawCode} setValue={(e) => { currentCode.current = e }} readonly={codeType !== 'html'} />
      {codeType === 'html' ? <Button type="primary" style={{ marginTop: 10 }} onClick={handleSave}>Save</Button> : null}
    </AdaptiveModal>
  );
};

export default ViewCodeModal;
