import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { DefaultTemplate, BannerType } from '../utils/types'
import { type RootState } from './store'

export interface BannersState {
  banners: BannerType[]
  loading: boolean
  updating: boolean
  displayCode: {
    code: string
    id: string
  }
  transpileError?: {
    code: string
    message: string
    statusCode: number
  }
  defaultTemplate: DefaultTemplate
}

export const initialState: BannersState = {
  banners: [],
  loading: false,
  updating: false,
  displayCode: {
    code: '',
    id: ''
  },
  defaultTemplate: {
    code: '',
    codeType: 'js',
    raw: '',
    metadata: [],
    name: ''
  }
}

const NAME = 'banners'

const { actions, reducer } = createSlice({
  name: NAME,
  initialState,
  reducers: {
    fetchBanners () {},
    setLoadingBanners (state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading
    },
    setBanners (state, action: PayloadAction<{ banners: BannerType[] }>) {
      state.banners = action.payload.banners
    },
    updateBanners (state) {
      state.updating = true
    },
    setBannersUpdating (state, action: PayloadAction<{ updating: boolean }>) {
      state.updating = action.payload.updating
      state.banners.forEach((banner) => { banner.dirty = false })
    },
    setLoadingPublishedBanners (state, action: PayloadAction<{ loading: boolean }>) {
      state.loading = action.payload.loading
    },
    setPublishedBanners (state, action: PayloadAction<{ banners: BannerType[] }>) {
      state.banners = action.payload.banners
    },
    updatePublishedBanner (state, action: PayloadAction<{ banner: BannerType, deleted?: boolean }>) {
      const { banner, deleted } = action.payload
      const addition = !deleted ? [banner] : []
      state.banners = [...state.banners.filter(b => b.id !== banner.id), ...addition]
    },
    transpileCode (state, action: PayloadAction<{ bannerId: string, code: string, codeType: string, onSuccess?: (code: string) => void, onFailure?: (error: any) => void }>) {
      state.displayCode = {
        code: '',
        id: action.payload.bannerId
      }
      state.updating = true
      state.loading = true
    },
    setTranspileError (state, action: PayloadAction<{ status: number, code: string, message: string }>) {
      const { status: statusCode, code, message } = action.payload
      state.updating = false
      state.loading = false
      state.transpileError = {
        code,
        message,
        statusCode
      }
    },
    setTranspiledCode (state, action: PayloadAction<{ code: string }>) {
      const { code } = action.payload
      state.displayCode.code = code ?? ''
      delete state.transpileError
      state.updating = false
      state.loading = false
    },
    fetchDefaultTemplate () {},
    setDefaultTemplate (state, action: PayloadAction<{ template: DefaultTemplate }>) {
      state.defaultTemplate = action.payload.template
    },
    fetchBaseComponent () {}
  }
})

export const {
  fetchBanners,
  setLoadingBanners,
  setBanners,
  updateBanners,
  setBannersUpdating,
  setLoadingPublishedBanners,
  setPublishedBanners,
  updatePublishedBanner,
  transpileCode,
  setTranspiledCode,
  setTranspileError,
  fetchDefaultTemplate,
  setDefaultTemplate,
  fetchBaseComponent
} = actions

export default reducer

export const bannersListStateSelector = (state: RootState): BannersState => state.banners
export const bannersListSelector = (state: RootState) => state.banners.banners
