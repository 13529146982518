import bannersReducer from './bannersSlice'
import bannersCatalogReducer from './bannersCatalogSlice'
import slotsReducer, { type SlotState } from './slotsSlice'
import authReducer from './authSlice'
import { combineReducers } from '@reduxjs/toolkit'
import { createTransform, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { bannersPublicApi } from './bannersPublic';
import { codeLibraryApi } from 'services/codeLibrary'
import { authApi } from 'services/auth'
import { bannersApi } from 'services/banners';
import type { RootState } from './store'
import strictEvaluate from 'utils/eval'

// always set inProgress to false on load + save
// otherwise you can load into a login modal you can't use
const LoginTransform = createTransform<RootState, RootState>(
  (inboundState, _key) => {
    return {
      ...inboundState,
      inProgress: false
    }
  },
  (outboundState, _key) => {
    return {
      ...outboundState,
      inProgress: false
    }
  },
  { whitelist: ['auth'] }
)

// always set banners.updating to false on load + save
// fixes possible stuck state if error occurs during update
const BannerTransform = createTransform<RootState, RootState>(
  (inboundState, _key) => {
    return {
      ...inboundState,
      updating: false
    }
  },
  (outboundState, _key) => {
    return {
      ...outboundState,
      updating: false
    }
  },
  { whitelist: ['banners'] }
)

const DefaultComponentTransform = createTransform<RootState, RootState>(
  (inboundState, _key) => {
    const inState = (inboundState as any as SlotState)
    const newState: any = {
      ...inboundState
    }
    if (typeof inState?.remoteFnStr === 'string') {
      try {
        newState.remoteFn = strictEvaluate(inState.remoteFnStr)
      } catch (e) {
        newState.remoteFnStr = ''
        newState.remoteFn = strictEvaluate('')
        console.error(e)
      }
    }
    return newState
  },
  (outboundState, _key) => {
    const outState = (outboundState as any as SlotState)
    const newState: any = {
      ...outboundState
    }

    if (typeof outState?.remoteFn === 'function') {
      newState.remoteFnStr = outState.remoteFn.toString()
    }
    return newState
  },
  { whitelist: ['slots'] }
)

const reducers = combineReducers({
  auth: authReducer,
  slots: slotsReducer,
  banners: bannersReducer,
  bannersCatalog: bannersCatalogReducer,
  [codeLibraryApi.reducerPath]: codeLibraryApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [bannersPublicApi.reducerPath]: bannersPublicApi.reducer,
  [bannersApi.reducerPath]: bannersApi.reducer
})

const persistConfig: any = {
  key: 'root',
  storage,
  whitelist: ['auth', 'banners', 'slots'],
  transforms: [LoginTransform, BannerTransform, DefaultComponentTransform]
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default persistedReducer
