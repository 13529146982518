import styled from 'styled-components'

export const DropContainer = styled.div`
  padding: 20px;
`

export const ImagePreview = styled.img`
  flex: 1;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
`
export const PhotoContainer = styled.div`
  margin-bottom: 15px;
`
export const DropText = styled.div`
  font-size: 14px;
  margin: 5px 0;
  color: #a6a6a6;
  font-weight: 500;
`
export const ChooseText = styled.div`
  color: #ed1e79;
  font-size: 14px;
  margin-top: 15px;
`
