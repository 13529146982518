import axios from 'axios'
import type { TemplateResponse, BannersPrivateResponse, BannerSubmitData } from './adapters'
import type { WebApp, BannerSortOrderUpdate } from './types'
import { appConfig } from '../config'
import { objectToUrlParams } from './objectToUrlParams'

export interface GetBannersParams {
  archived?: boolean
  locations?: string
  categories?: string
  customerGroup?: string
}

interface ApiResponse<T> {
  result: T
}

const shouldCache = (url: string) => /(remote|appetizeio)-/.test(url)

const api = {
  async baseApi <T>(subUrl: string, method: string, jsonData: object): Promise<ApiResponse<T>> {
    const startTime = Date.now()
    let token = ''
    try {
      token = JSON.parse(localStorage.getItem('firebaseToken') as string)
    } catch (ex: any) {
      console.error(`Failed to parse token: ${ex?.message}`)
    }

    const request = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      url: `${shouldCache(subUrl) ? appConfig.publicBaseUrl : appConfig.baseUrl}/${subUrl}`,
      data: jsonData
    }

    const response = await axios<T>(request)
    console.log(
        `API[${method}] ${subUrl} - Success [${Date.now() - startTime}]`
    )

    return {
      result: response.data
    }
  },

  async getBanners (params?: GetBannersParams) {
    const additionalParams = params ? `?${objectToUrlParams(params)}` : '';

    return await this.baseApi<BannersPrivateResponse>(
      `api/apps/${appConfig.webAppId}/banners${additionalParams}`,
      'GET',
      {}
    )
  },
  async getWebApp (appId: string) {
    return await this.baseApi(`api/admin/webs/${appId}`, 'GET', {})
  },
  async updateWebApp (appId: string, data: WebApp) {
    return await this.baseApi(`api/admin/webs/${appId}`, 'PUT', data)
  },
  async getRemoteCodelib () {
    return await this.baseApi<string>(`remote-baseCodelib?key=${appConfig.fbAppApiKey}`, 'GET', {})
  },
  async getBanner (bannerId: string) {
    return await this.baseApi(`api/apps/${appConfig.webAppId}/banners/${bannerId}`, 'GET', {})
  },
  async createBanner (data: BannerSubmitData) {
    return await this.baseApi(`api/apps/${appConfig.webAppId}/banners`, 'POST', data)
  },
  async updateBanner (data: BannerSubmitData) {
    return await this.baseApi(`api/apps/${appConfig.webAppId}/banners/${data.id}`, 'PUT', data)
  },
  async getDefaultTemplate () {
    return await this.baseApi<TemplateResponse>(`api/apps/${appConfig.webAppId}/codelibs/default`, 'GET', {})
  },
  async deleteSlotFromBanner (bannerId: string, slotId: string) {
    return await this.baseApi(
      `api/apps/${appConfig.webAppId}/banners/${bannerId}/slots`,
      'PATCH',
      {
        slotsToRemove: [slotId]
      }
    )
  },
  async deleteBanner (bannerId: string) {
    return await this.baseApi(
      `api/apps/${appConfig.webAppId}/banners/${bannerId}`,
      'DELETE',
      {}
    )
  },
  async updateBulkBanners (data: { banners: BannerSortOrderUpdate[] }) {
    return await this.baseApi(`api/apps/${appConfig.webAppId}/banners`, 'PUT', data)
  },
  async remoteTranspileCode (code: string, codeType: string) {
    return await this.baseApi<{ data: string }>(`utility-code_transpiler`, 'POST', { code, codeType })
  }
}

export default api
