export const objectToUrlParams = (data: Record<string, any>, withoutEncodeURI: boolean = false): string => {
  const params: string[] = [];

  for (const key in data) {
    if (data[key] === null || data[key] === undefined) {
      continue;
    }

    if (Array.isArray(data[key])) {
      for (const item of data[key]) {
        const keyParam = withoutEncodeURI ? key : encodeURIComponent(key);
        const itemParam = withoutEncodeURI ? item : encodeURIComponent(item);

        params.push(`${keyParam}=${itemParam}`);
      }
    } else {
      const keyParam = withoutEncodeURI ? key : encodeURIComponent(key);
      const itemParam = withoutEncodeURI ? data[key] : encodeURIComponent(data[key]);

      params.push(`${keyParam}=${itemParam}`);
    }
  }

  return params.join('&');
}
