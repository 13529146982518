import React, { type PropsWithChildren } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import persistedReducer from './rootReducer';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { listenerMiddleware } from './listenerMiddleware';
import {} from './bannersAPI';
import {} from './bannersCatalogAPI';
import { bannersPublicApi } from './bannersPublic';
import { codeLibraryApi } from 'services/codeLibrary';
import { authApi } from 'services/auth';
import { bannersApi } from 'services/banners';

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['banners/transpileCode', FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .prepend(listenerMiddleware.middleware)
      .concat(bannersPublicApi.middleware)
      .concat(codeLibraryApi.middleware)
      .concat(authApi.middleware)
      .concat(bannersApi.middleware)
});
export type RootState = ReturnType<typeof store.getState>;

// sagaMiddleware.run(rootSaga)
export const persistor = persistStore(store, undefined, () => {});
export default store;

export type AppDispatch = typeof store.dispatch;

export function ReduxWrappers ({ children }: PropsWithChildren): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
}
