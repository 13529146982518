import { create } from 'zustand'
import type { BannerType, BannerTypeRender, Variant } from 'utils/types'
import { URI_PIECES, URIToRegex, QUERY_PARAMS } from 'utils/urlParams'
import type { ElementType } from 'react'
import strictEvaluate from 'utils/eval'
import type { VariantSubmitData } from 'utils/adapters'
import type { ParsedCookie } from 'contexts/cookies'

interface Slot {
  id: string
  banners: BannerTypeRender[]
  loading: boolean
}

interface SlotState {
  slots: Slot[]
  loading: boolean
  clientDefaultFn?: SetDefaultFn
}

export type SetDefaultFn = (Component: ElementType) => ElementType
interface Actions {
  addSlot: (id: string | null) => void
  organizeBannersIntoSlots: (banners: BannerTypeRender[]) => void
  setClientDefault: (fn: string) => void
  reset: () => void
}

const initialState: SlotState = {
  slots: [],
  loading: false,
  clientDefaultFn: undefined
}

export const useBannersStore = create<SlotState & Actions>((set) => ({
  ...initialState,
  addSlot: (id: string | null) => {
    set((state) => ({
      ...state,
      slots: !id || state.slots.some((x) => x.id === id)
        ? state.slots
        : [...state.slots, {
            id,
            banners: [],
            loading: true
          }]
    }))
  },
  organizeBannersIntoSlots: (banners: BannerTypeRender[]) => {
    set(
      (state) => ({
        ...state,
        slots: state.slots.map((slot) => {
          const slotId = slot.id
          const newBanners = Array.from(
            new Map([
              ...banners.filter((banner) => banner.webSlots?.includes(slotId)),
              ...slot.banners
            ].map(item => [item.id, item])).values()
          ).sort((a, b) => {
            const aOrder = a?.bannerSortOrder?.[slotId] ?? 0
            const bOrder = b?.bannerSortOrder?.[slotId] ?? 0
            return aOrder - bOrder
          })

          return {
            ...slot,
            banners: newBanners,
            loading: false
          }
        })
      })
    )
  },
  reset: () => {
    set(
      (state) => ({
        ...state,
        slots: state.slots.map((slot) => ({
          ...slot,
          banners: []
        }))
      })
    )
  },
  setClientDefault: (fn: string) => {
    set(
      (state) => {
        try {
          return {
            ...state,
            clientDefaultFn: strictEvaluate(fn)
          }
        } catch (ex) {
          console.error(ex)
          return state
        }
      }
    )
  }
}))

const fallbackURIPieces = { path: '', search: '', port: '', hash: '', origin: '', protocol: '', host: '' }
const stripUrlExtras = (url: string) => {
  if (url[0] === '?') return url

  let endIndex = Math.min(
    url.includes('#') ? url.lastIndexOf('#') : Infinity,
    url.includes('?') ? url.lastIndexOf('?') : Infinity
  )

  // Don't strip if we're just hitting the protocol
  const slicedUrl = url.slice(0, endIndex)
  if (endIndex === 7 && /^https?:\//.test(slicedUrl)) endIndex = Infinity

  // Handle trailing slashes consistently
  const stripped = endIndex !== Infinity ? slicedUrl : url
  return stripped.endsWith('/') ? stripped : `${stripped}/`
}

const verifyBase = (currentLocation: string, locToTest: string, base: string) => {
  if (!locToTest) return false
  // Early return for query param only case
  if (locToTest[0] === '?') return true

  const isRelative = !relCheck.test(locToTest)
  if (isRelative && locToTest[0] !== '/') locToTest = `/${locToTest}`

  const cleanCurrentLoc = stripUrlExtras(currentLocation)
  const { path = '', search = '', origin = '' } = URI_PIECES(!relCheck.test(locToTest) ? `${base}${locToTest}` : locToTest) ?? fallbackURIPieces
  if (origin !== base) return false

  // Handle wildcard paths
  if (path.endsWith('*')) {
    const basePath = path.slice(0, -1)
    return cleanCurrentLoc.startsWith(`${base}${basePath}`)
  }

  const fullLocToTest = isRelative ? `${origin}${path}${search}` : locToTest
  const cleanFullLoc = stripUrlExtras(fullLocToTest)
  return URIToRegex(stripUrlExtras(cleanFullLoc)).test(cleanCurrentLoc)
}

const verifyQueryParams = (currentLocation: string, locToTest: Record<string, string>, qpKeys: string[]) => {
  const currentQP = QUERY_PARAMS(currentLocation)

  return qpKeys.every((key) => {
    const matchValue = currentQP[key]
    return matchValue ? URIToRegex(locToTest[key]).test(matchValue) : false
  })
}

const includeURL = (locToTest: string, currentBase: string, urlToTest: string) => {
  const baseVerified = verifyBase(urlToTest, locToTest, currentBase)
  if (!baseVerified) return false

  const isRelative = !relCheck.test(locToTest)
  if (isRelative && locToTest[0] !== '/') locToTest = `/${locToTest}`
  const locQP = isRelative
    ? QUERY_PARAMS(`${currentBase}${locToTest}`)
    : QUERY_PARAMS(locToTest)
  const locQPKeys = Object.keys(locQP)

  // Only check query params if we have them and passed base verification
  return verifyQueryParams(urlToTest, locQP, locQPKeys)
}

type LocationsInBannerType = Pick<BannerType, 'id' | 'locations' | 'location' | 'variants'>;

export const relCheck = /^https?:\/\//i
const getCurrentURIConfig = (url: string) => {
  const URI = URI_PIECES(url)

  return {
    URI,
    CURRENT_BASE: URI
      ? URI.origin ?? URI.protocol + '://' + URI.host + (URI?.port ? `:${URI.port}` : '')
      : ''
  }
}

export const bannersForLocation = <T extends LocationsInBannerType>(banners: T[], url: string, category?: string, cookies?: ParsedCookie[]): T[] => {
  const { CURRENT_BASE } = getCurrentURIConfig(url)
  const visibleBanners = new Map<string, T>()

  for (const banner of banners) {
    const { id, location, locations, variants } = banner

    // Skip if we've already added this banner
    if (visibleBanners.has(id)) continue

    // Check single location
    if (location && includeURL(location, CURRENT_BASE, url)) {
      visibleBanners.set(id, banner)
      continue
    }

    // Check multiple locations
    if (locations?.some(loc => includeURL(loc, CURRENT_BASE, url))) {
      visibleBanners.set(id, banner)
      continue
    }

    if (!variants?.length) continue

    // Check variants
    const hasMatchingVariant = variants.some(variant => {
      const categoryMatch = typeof category === 'string' && variant.categories?.includes(category)
      if (categoryMatch) return true

      const customerGroupMatch = Array.isArray(cookies) && cookies.length > 0 && variant.customerGroups?.some(group =>
        cookies.some(cookie => cookie.value && cookie.map?.[group]?.includes(cookie.value))
      )
      if (customerGroupMatch) return true

      return variant.locations?.some(loc => includeURL(loc, CURRENT_BASE, url))
    })

    if (hasMatchingVariant) {
      visibleBanners.set(id, banner)
    }
  }

  return Array.from(visibleBanners.values())
}

export const variantsForLocation = (variants: Variant[], url: string, category?: string, cookies?: ParsedCookie[]): Variant[] => {
  const { CURRENT_BASE } = getCurrentURIConfig(url)

  return variants.filter(variant => {
    const hasCategoryMatch = variant.categories?.some(cat => category === cat)
    if (hasCategoryMatch) return true

    if (Array.isArray(cookies) && cookies.length > 0) {
      return variant.customerGroups?.some(group =>
        cookies.some(cookie => cookie.value && cookie.map?.[group]?.includes(cookie.value))
      )
    }

    return variant.locations?.some(loc => includeURL(loc, CURRENT_BASE, url))
  })
}
export const variantsIndex = (variants: Variant[] | VariantSubmitData[], url: string, category?: string, cookies?: ParsedCookie[]): number => {
  const { CURRENT_BASE } = getCurrentURIConfig(url)

  // Use early return pattern and avoid creating unnecessary closures
  for (let i = 0; i < variants.length; i++) {
    const variant = variants[i]

    // Check categories first as it's likely cheaper than location checks
    if (variant.categories?.some(cat => category === cat)) return i

    if (Array.isArray(cookies) && cookies.length > 0 && variant.customerGroups?.some(group =>
      cookies.some(cookie => cookie.value && cookie.map?.[group]?.includes(cookie.value))
    )) return i

    // Only check locations if no category match was found
    if (variant.locations?.some(loc => includeURL(loc, CURRENT_BASE, url))) return i
  }

  return -1
}
