import { imageDimensionsFromData } from 'image-dimensions'
import type { VariantMetadata } from './types'

export interface ImageDimensions {
  height: string
  width: string
}
export interface ImageDimensionsNumeric {
  height: number
  width: number
}

const MD_REGEX = /\$\(([\w$]+):(\w+)(\[([\s\w"$',`|]*)])?(=?)(?:["'`]([^\n"'`]*)["'`])?\)/g
const QUOTES_RE = /['"`]/
const escapeRegExp = (stringToGoIntoTheRegex: string): string => stringToGoIntoTheRegex.replace(/[$()*+./?[\\\]^{|}-]/g, String.raw`\$&`)

export interface ddOpt { label: string, value: string | number }
export const parseMetadata = (code: string) => {
  const metadataFromCode = []

  let match
  // eslint-disable-next-line no-cond-assign
  while ((match = MD_REGEX.exec(code)) !== null) {
    const [, name, type, , dropdownOptions, hasDefault, defaultValue] = match
    const metadata: VariantMetadata = {
      name,
      type,
      defaultValue: hasDefault ? (type === 'Number' ? Number(defaultValue) : defaultValue) : null
    }
    if (type === 'Dropdown' && dropdownOptions) {
      let ddOpts: ddOpt | string = dropdownOptions
      // strip surrounding quotes if present
      if (
        QUOTES_RE.test(dropdownOptions[0]) &&
        dropdownOptions.at(-1) === dropdownOptions[0]
      ) {
        ddOpts = dropdownOptions.slice(1, -1)
      }
      metadata.dropdownOptions = ddOpts.split(',').map((item) => {
        const [label, value] = item.split('|')
        return { label, value } satisfies ddOpt
      })
    }

    metadataFromCode.push(metadata)
  }

  return metadataFromCode
}
export const replaceTemplateData = (code: string, metadata: VariantMetadata[]) => {
  let modifiedCode = code
  const fields = parseMetadata(code)
  fields.forEach((field) => {
    const { name, type, defaultValue } = field
    const md = metadata.find((md) => md.name === name)
    const value = md?.value
    const dynamicTemplateRegex = `\\$\\(${escapeRegExp(name)}:.*?=?(.*?)?\\)`
    const regex = new RegExp(dynamicTemplateRegex, 'g')
    const tmpValue = value ?? defaultValue
    const isValueQuoted = tmpValue && QUOTES_RE.test(tmpValue[0]) && tmpValue[0] === tmpValue.at(-1)
    let finalVal: number | string | boolean
    // process our value into numeric, boolean or string
    if (type === 'Number') {
      finalVal = Number(tmpValue)
    } else if (type === 'Boolean' && typeof tmpValue === 'string') {
      finalVal = isValueQuoted
        ? tmpValue.toLowerCase().replaceAll(/'`/g, String.raw`\"`) === '"true"'
        : tmpValue.toLowerCase() === 'true'
    } else if (tmpValue) {
      finalVal = tmpValue && isValueQuoted ? tmpValue : `"${tmpValue.replaceAll('"', String.raw`\"`)}"`
    } else {
      finalVal = 'null'
    }

    // if finalVal has $, escape it so we don't invent new "variables" from pricing data
    modifiedCode = modifiedCode.replace(regex, String(finalVal).replace(/\$/g, '$$$'))
  })

  return modifiedCode
}

const concatenate = (uint8arrays: Uint8Array[]): Uint8Array => {
  const totalLength = uint8arrays.reduce(
    (total, uint8array) => total + uint8array.byteLength,
    0
  )
  const result = new Uint8Array(totalLength)
  let offset = 0
  uint8arrays.forEach((uint8array) => {
    result.set(uint8array, offset)
    offset += uint8array.byteLength
  })
  return result
}

async function * streamingFetch (fetchcall: () => Promise<Response>) {
  const response = await fetchcall()
  const reader = response.body?.getReader()
  while (true) {
    const { done, value } = await reader?.read() ?? { done: true, value: undefined }
    if (done) break
    yield value
  }
}

export const getSizeFromURL = async (imgUrl: string): Promise<ImageDimensions> => {
  return await new Promise((resolve, reject) => {
    void (async () => {
      let dimensions: ImageDimensionsNumeric | undefined
      let imageTypeDetectionError: unknown
      const resolveOrReject = (ex?: unknown) => {
        if (ex) {
          reject(ex)
        } else if (!dimensions) {
          reject(imageTypeDetectionError)
        } else {
          resolve({
            height: String(dimensions.height),
            width: String(dimensions.width)
          })
        }
      }
      try {
        const controller = new AbortController()
        const signal = controller.signal
        const buffers: Uint8Array[] = []
        for await (const chunk of streamingFetch(async () => await fetch(imgUrl, { signal }))) {
          buffers.push(chunk)
          try {
            const buffer = concatenate(buffers)
            dimensions = imageDimensionsFromData(buffer)
            if (dimensions?.width && dimensions?.height) {
              // console.info('bytes read', buffers.reduce((total, arr) => total + arr.byteLength, 0))
              controller.abort()
            }
          } catch (e: unknown) {
            imageTypeDetectionError = e
          }
        }
        resolveOrReject()
      } catch (ex: any) {
        resolveOrReject(ex.name !== 'AbortError' ? ex : null)
      }
    })()
  })
}
