import React from 'react'
import { ReduxWrappers } from 'redux/store'
import { getRootElement } from './index.common'
import SlotListing from 'components/SlotListing'
import lib from '../package.json'
import { appConfig } from './config'
import { Auth0Provider } from '@auth0/auth0-react'
import { AuthProvider } from './contexts/auth';
import { Toaster } from 'react-hot-toast'
import { ConfigProvider } from 'antd'
import theme from 'config/theme'
import { PreviewProvider } from './contexts/preview';
import { CookieProvider } from 'contexts/cookies'
import { WindowSizeProvider } from 'contexts/window'
import { LocationProvider } from 'contexts/location'
import { clearCookie, setCookie } from 'utils/cookies'
import { SESSION_SFCC_SITE_DATE, SFCC_PARAM_SITE_DATE } from 'utils/urlParams'
import { dateFromSFCC } from 'hooks/useSiteDate'

(() => {
  window.BILDIT = {
    ...window.BILDIT,
    version: lib.version,
    init: () => {
      const queryParams = new URLSearchParams(window.location.search)
      const urlDateParam = queryParams.get(SFCC_PARAM_SITE_DATE)
      const bilditPreview = queryParams.get(SESSION_SFCC_SITE_DATE)
      if (bilditPreview && bilditPreview.length > 0) {
        console.warn('Previewing content for', dateFromSFCC(bilditPreview))
        // 1 hr expiry
        void setCookie(SESSION_SFCC_SITE_DATE, bilditPreview, 1 / 24, { secure: true, sameSite: 'None' })
      }
      // store sfcc date in our date param when available
      if (urlDateParam) {
        if (urlDateParam.length === 0) {
          void clearCookie(SESSION_SFCC_SITE_DATE)
        } else {
          console.warn('Previewing content for', dateFromSFCC(urlDateParam))
          // 1 hr expiry
          void setCookie(SESSION_SFCC_SITE_DATE, urlDateParam, 1 / 24, { secure: true, sameSite: 'None' })
        }
      } else console.warn('Previewing current content for', new Date())
      console.log('BILDIT initialized')
    },
    info: {
      version: lib.version,
      scriptInstalled: true,
      appId: appConfig.webAppId
    },
    persistent: false,
    categoryValidations: {}
  }

  window.BILDIT.init?.()
})()

const toolBar = getRootElement()
if (toolBar) {
  toolBar.render(
    <ReduxWrappers>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
        clientId={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <AuthProvider>
          <ConfigProvider theme={theme}>
            <Toaster
              toastOptions={{
                style: {
                  fontFamily: theme.token?.fontFamily
                }
              }}
            />
            <PreviewProvider>
              <CookieProvider cookies={appConfig.cookies}>
                <LocationProvider>
                  <WindowSizeProvider>
                    <SlotListing />
                  </WindowSizeProvider>
                </LocationProvider>
              </CookieProvider>
            </PreviewProvider>
          </ConfigProvider>
        </AuthProvider>
      </Auth0Provider>
    </ReduxWrappers>
  )
}
