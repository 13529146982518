import styled from 'styled-components'

export const CodeLibraryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
`

export const SnippetListPanel = styled.div`
  min-width: 180px;
`

export const PreviewPanel = styled.div`
  display: grid;
  padding: 0 10px;
`

export const Previewer = styled.div`
`

export const PreviewImage = styled.img`
  height: auto;
  min-width: 350px;
  max-width: 500px;
`
