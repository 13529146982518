import type { BannerTypeRender, CodeType, Variant } from './types';
import { remoteSchemeCommonToBanner, type BannerRemoteSchemeCommon } from './adapters';

const STYLE_RE = /<style\s?(?:[a-z]+="?[\d/a-z]+"?)?>([^<]*)<\/style>/gi
const QUOTES_RE = /&quot;|&#x27;/g

export const unescapeVariantHTML = (html?: string): string | undefined => {
  if (!html) return
  return html.replace(
    STYLE_RE,
    (_, group1: string) =>
    `<style>${group1.replace(
      QUOTES_RE,
      (match: string) => match === '&quot;' ? '"' : '\''
    )}</style>`
  )
}

export const mapPublicBanners = (data: BannerRemoteSchemeCommon[]): BannerTypeRender[] =>
  (data ?? [])
    .map((banner: BannerRemoteSchemeCommon) => ({
      adobeAnalytics: banner.adobeAnalytics,
      alternateText: banner.alternateText,
      bannerSortOrder: banner.bannerSortOrder,
      code: banner.code,
      id: banner.id,
      image: banner.image,
      locations: Array.isArray(banner.locations) ? banner.locations : (banner.location ? [banner.location] : []),
      name: '',
      published: true,
      preview: banner.preview,
      schedules: banner.schedules,
      screenTypes: remoteSchemeCommonToBanner(banner)?.screenTypes,
      slot: banner.slot,
      url: banner.url,
      webSlots: banner.webSlots ?? [],
      variants: banner.variants?.map((variant: Variant) => ({
        categories: variant?.categories,
        customerGroups: variant?.customerGroups,
        code: variant.code?.compiled
          ? {
              compiled: variant.code.compiled,
              // pre-decode quotes inside style tags
              html: unescapeVariantHTML(variant.code?.html)
            }
          : undefined,
        codeType: variant?.codeType as CodeType,
        image: variant?.image,
        locations: variant?.locations,
        preview: variant?.preview,
        url: variant?.url
      }))
    }))
